import React from 'react';
import Grid from 'canvas-core-react/lib/Grid';
import Row from 'canvas-core-react/lib/Row';
import Col from 'canvas-core-react/lib/Column';
import TextField from 'canvas-core-react/lib/TextField';
import { noc_motivos_agenda } from '../data/get_dominios';
import { noc_productos_agenda } from '../data/get_dominios';
import { noc_deuda } from '../data/get_noclientes';
    
import '../Sic.scss';

const now = new Date();
now.setHours(0, 0, 0, 0);

const endDate = new Date(now.getTime()); // clone first date
endDate.setMonth(endDate.getMonth() + 2);

const PanelProspectos = () => (
  <> 
  <div className="PanelFiltros__card" >
    <Grid className="PanelFiltros__block" fluid={true} >

    <Row className="PanelFiltros__rows" >

        <Col className="PanelFiltros__cols" xs={1} sm={2} md={2} lg={2}>
          <TextField id="f_clte_rut" label="" secondaryLabel="RUT" placeholder="" type="text" className="PanelFiltros__input"/>
        </Col>

        <Col xs={2} sm={4} md={10} lg={10} className="sic-align-left">
          <button href="/" className="sic-btn sic-border sic-border-color-gray-600 sic-hover-border-color-light-red sic-hover-text-color-dark-red sic-margin" type="submit"> Buscar </button>
        </Col>

      </Row>
    </Grid>
  </div>

  <div className="PanelFiltros__card" >
    <Grid className="PanelFiltros__block" fluid={true} >
      <Row className="PanelFiltros__rows" >
        <Col className="PanelFiltros__cols" xs={4} sm={8} md={12} lg={12}>

<table className="sic-table">
	<thead>
		<tr>
			<th className="sic-xlarge sic-color-blue sic-align-center sic-padding" colSpan="3">Agenda</th>
		</tr>
	</thead>
	<tbody>
		<tr>
			<td>
				<label className="sic-label">Motivo</label>
					<select className="sic-select" name="s_moti_noc">
          {
            noc_motivos_agenda.map((motivo, index) => (
            <option id={"moti_"+motivo.moti_id} key={"moti_"+motivo.moti_id+"_"+index} value={motivo.moti_id} className="PanelFiltros__select-option">
              {motivo.moti_nombre}
            </option>
            ))
          }  
					</select>
			</td>
			<td>
				<label className="sic-label">Producto</label>
					<select className="sic-select" name="s_prod_noc">
          {
            noc_productos_agenda.map((producto, index) => (
            <option id={"prod_"+producto.prod_id} key={"prod_"+producto.prod_id+"_"+index} value={producto.prod_id} className="PanelFiltros__select-option">
              {producto.prod_nombre}
            </option>
            ))
          }  
  				</select>
			</td>
			<td>
				<label className="sic-label">Fecha agenda</label>
				<input className="sic-input" type="date" name="t_fecha_agen_noc" id="t_fecha_agen_noc" size="12" />
			</td>
		</tr>
		<tr>
			<td className="sic-padding" colSpan="3">
				<a href="/" className="sic-btn sic-border sic-border-color-dark-blue sic-hover-border-color-light-red sic-hover-text-color-dark-red" name="b_ag_agregar_noc" >Agendar no cliente</a>
			</td>
		</tr>
	</tbody>
</table>

        </Col>
      </Row>
    </Grid>

  </div>

  <div className="PanelFiltros__card" >
    <table className="sic-table sic-table-all sic-hoverable sic-border sic-border-color-blue">
      <thead>
          <tr>
              <th className="sic-border sic-large sic-align-center sic-color-dark-blue sic-background-color-thead" colSpan="11">Información SBIF</th>
          </tr>
          <tr>
              <th className="sic-border"></th>
              <th className="sic-border">Período</th>
              <th className="sic-border">Deuda Consumo<br/>(SBIF M$)</th>
              <th className="sic-border">Deuda Comercial<br/>(SBIF M$)</th>
              <th className="sic-border">Deuda Hipotecaria<br/>(SBIF M$)</th>
              <th className="sic-border">Deuda Leasing<br/>(SBIF M$)</th>
              <th className="sic-border">Deuda Moneda<br/>Extranjera (SBIF M$)</th>
              <th className="sic-border">Disponible<br/>Línea de Crédito<br/>(SBIF M$)</th>
              <th className="sic-border">Deuda Indirecta<br/>Vigente<br/>(SBIF M$)</th>
              <th className="sic-border">Deuda Directa<br/>Vigente<br/>(SBIF M$)</th>
              <th className="sic-border">Número de<br/>Inst. Consumo</th>
          </tr>
      </thead>

      <tbody>
      {
        noc_deuda.map((data, idx) => (
          <tr className="sic-medium" key={"venc_"+idx}>
            <td className="sic-align-center sic-border">{idx + 1}</td>
            <td className="sic-align-center sic-border">{data.ns_periodo}</td>
            <td className="sic-align-right sic-border">{data.ns_dcons}</td>
            <td className="sic-align-right sic-border">{data.ns_dcom}</td>
            <td className="sic-align-right sic-border">{data.ns_dhipot}</td>
            <td className="sic-align-right sic-border">{data.ns_leasing}</td>
            <td className="sic-align-right sic-border">{data.ns_dcommonextr}</td>
            <td className="sic-align-right sic-border">{data.ns_lindisp}</td>
            <td className="sic-align-right sic-border">{data.ns_dindirecta}</td>
            <td className="sic-align-right sic-border">{data.ns_ddirvgte}</td>
            <td className="sic-align-right sic-border">{data.ns_numinst}</td>
          </tr>
        ))
      }
      </tbody>
    </table>
  </div>
  </>
);

export default PanelProspectos;