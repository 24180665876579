import React from "react";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
//require('highcharts/modules/exporting')(Highcharts)

const GraficoTorta = ({params, datap}) => {

    const  opciones = {
        chart: {
            plotBackgroundColor: null,
            plotBorderWidth: null,
            plotShadow: false,
            type: 'pie'
        },
        title: {
            text: params.title,
            style: {
				fontSize: '14px',
				fontFamily: 'Arial, Verdana, sans-serif'
				}
        },
        subtitle: {
            text: params.subtitle,
            style: {
				fontSize: '13px',
				fontFamily: 'Arial, Verdana, sans-serif'
				}
        },
        credits: { 
            enabled: false 
        },
        exporting: {
            enabled: false
        },
        tooltip: {
            pointFormat: '{series.name}: <b>{point.percentage:.1f}%</b>'
        },
        accessibility: {
            point: {
                valueSuffix: '%'
            }
        },
        plotOptions: {
            pie: {
                allowPointSelect: true,
                cursor: 'pointer',
                innerSize: "50%",
                showInLegend: true,
                dataLabels: {
                    enabled: true,
					format: '<b>{point.name}</b>: {point.y}',
					style: {
						fontSize: '12px',
						fontFamily: 'Arial, Verdana, sans-serif'
					}
                }
            }
        },

        series: [{
            name: 'Brands',
            colorByPoint: true,
            data: datap.map (cont => { return({name: cont.atributo_desc, y: cont.atributo_count}) })
        }]
    }

return (
    <>
    <HighchartsReact
        highcharts={Highcharts}
        options={opciones}
    />
    </>
);
}

export default GraficoTorta;