export const isUndefined = (text) => {
    return ( (text !== null) && (text !== undefined) && (text.length !== 0) ) ? text : '';
};

export const formatRut = (rut) => {

    if ( (rut === null) || (rut === undefined) ) return '';

	let nRut = `${rut}`;
	nRut = nRut.replace(/[^0-9kK]+/g, '').toUpperCase();
	if (rut.length <= 1) {
		return rut;
	}

	let result = `${nRut.slice(-4, -1)}-${nRut.substr(nRut.length - 1)}`;
	for (let i = 4; i < nRut.length; i += 3) {
		result = `${nRut.slice(-3 - i, -i)}.${result}`;
	}

	return result;
};

export const formatCantidad = (cantidad, precision = 0) => {
    if ( (cantidad !== null) && (cantidad !== undefined) && (cantidad.length !== 0) )
        if (isNaN(cantidad))
            return(cantidad)
        else
            if (cantidad === 0)
                return('')
            else
                return new Intl.NumberFormat('es-CL',  {style: 'decimal', minimumFractionDigits: precision, maximumFractionDigits: precision}).format(cantidad);
    else
        return('')
};

export const formatMonto = (monto, precision = 0) => {
    if ( (monto !== null) && (monto !== undefined) && (monto.length !== 0) )
        if (isNaN(monto))
            return(monto)
        else
            return new Intl.NumberFormat('es-CL',  {style: 'decimal', minimumFractionDigits: precision, maximumFractionDigits: precision}).format(monto);
    else
        return('')
};

export const formatAntiguedad = (cantidad, precision = 0) => {
    if ( (cantidad !== null) && (cantidad !== undefined) && (cantidad.length !== 0) )
        if (isNaN(cantidad))
            return(cantidad)
        else
            return new Intl.NumberFormat('es-CL',  {style: 'decimal', minimumFractionDigits: precision, maximumFractionDigits: precision}).format(cantidad/365);
    else
        return('')
};

export const formatPctje = (valor, precision = 0, simbolo = '%') => {
    return ( (new Intl.NumberFormat({style: 'decimal', minimumFractionDigits: precision, maximumFractionDigits: precision}).format(valor)) + simbolo );
};

export const formatSiNo = (valor) => {

    const valor1 = isUndefined(valor).toUpperCase();
    return ( (valor1 === 'S') ? 'Sí' : ((valor1 === 'N') ? 'No' : '') );
};

/*
export const formatAmount = ({currency_code, amount}) => {
    if (currency_code === 'UTM') {
        let currency = new Intl.NumberFormat(getCurrency('USD'), {style: 'currency', currency: 'USD', currencyDisplay: 'symbol'}).format(amount);
        return `UTM ${currency.substring(1, currency.length)}`;
    } else if (currency_code === 'UF') {
        let currency = new Intl.NumberFormat(getCurrency('CLF'), {style: 'currency', currency: 'CLF', currencyDisplay: 'symbol'}).format(amount);
        return `${currency.replace('CLF', 'UF')}`;
    } else if (currency_code === 'USD') {
        let currency = new Intl.NumberFormat(getCurrency('EUR'), {style: 'currency', currency: 'EUR', currencyDisplay: 'symbol'}).format(amount);
        return `USD ${currency.substr(0,currency.length -2)}`;
    } else if (currency_code === 'EUR') {
        let currency = new Intl.NumberFormat(getCurrency('EUR'), {style: 'currency', currency: 'EUR', currencyDisplay: 'symbol'}).format(amount);
        return `EUR ${currency.substr(0,currency.length -2)}`;
    } else {
        return new Intl.NumberFormat(getCurrency(currency_code), {style: 'currency', currency: currency_code.toUpperCase(), currencyDisplay: 'symbol'}).format(amount);
    }
};

*/