export const serviciosSIC = 
    {
      "serv_select_data_bgpe_x_padre": "sprc_select_data_bgpe_x_padre",
      "serv_select_data_prod_x_padre": "sprc_select_data_prod_x_padre",
      "serv_select_data_ctrl_x_padre": "sprc_select_data_ctrl_x_padre",

      "serv_select_cacc_personas_x_params": "sprc_select_cacc_personas_x_params",

      "serv_select_tablas_x_params" : "sprc_select_tablas_x_params",
      "serv_select_etapas_x_params" : "sprc_select_etapas_x_params",

      "serv_panel_control_tubo": "1",
      "serv_panel_control_acciones_data": "sprc_select_pcontrol_acciones_x_params",
      "serv_panel_control_acciones_graf": "3",
      "serv_panel_control_alertas_data": "sprc_select_pcontrol_alertas_x_params",
      "serv_panel_control_alertas_graf": "4",
      "serv_select_pcontrol_x_tubo_comercial": "sprc_select_pcontrol_x_tubo_comercial",
      "serv_select_pcontrol_x_tubo_producto": "sprc_select_pcontrol_x_tubo_producto",
      "serv_select_pcontrol_x_visitas_comercial": "sprc_select_pcontrol_x_visitas_comercial",
      "serv_select_pcontrol_x_visitas_producto": "sprc_select_pcontrol_x_visitas_producto",

      "serv_panel_acciones": "sprc_select_acciones_x_params",
      "serv_panel_visitas": "sprc_select_visitas_x_params",
      "serv_panel_clientes": "sprc_select_clientes_x_params",
      "serv_panel_alertas": "sprc_select_alertas_x_params",
      "serv_panel_vencimientos": "sprc_select_vencimientos_x_params",

      "serv_panel_diagnostico_grafico_conteo": "sprc_select_clientes_atributos_x_conteo",
      "serv_panel_diagnostico_grafico_evolucion": "sprc_select_clientes_atributos_x_evolucion",
      "serv_panel_diagnostico_grafico_penetracion": "sprc_select_clientes_atributos_x_penetracion",

      "serv_informes_tubo_resumido": "90",
      "serv_informes_visita_mensual": "91",
      "serv_informes_prospeccion": "92",

      "serv_ficha_cliente": "111",
      "serv_ficha_cliente_contactos": "222",
      "serv_ficha_cliente_rentabilidad": "333",
      "serv_ficha_cliente_visitas": "444",
    }
   