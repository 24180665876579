export const cliente_contactos_x_rut = [
    {
      "ctto_nkey": 1,
      "clte_rut": 966921609,
      "ctto_nombre_contacto": "PERS_966921609_1",
      "ctto_cargo": "Gerente Finanzas",
      "ctto_nivel_decision": "Transaccional y deuda",
      "ctto_ctto_grupopatr": "S",
      "ctto_telefono_fijo": 228704304,
      "ctto_telefono_movil": "",
      "ctto_email": "francisca.lizama@scotiabank.cl",
      "ctto_rut": "",
      "ctto_fecha_nac": "",
      "ctto_observaciones": "",
      "ctto_vigente": "S",
      "ctto_skey_cargo": "gerente_finanzas",
      "ctto_skey_nivel_decision": "transaccional_deuda",
      "pers_id_creacion": null,
      "pers_id_modif": null,
      "ctto_fecha_creacion_dma": null,
      "ctto_fecha_modif_dma": null,
      "ctto_fecha_nac_dma": "",
      "carg_tabp_alias": "Gerente Finanzas",
      "ndec_tabp_alias": "Transaccional y deuda"
    }
   ]

export const cliente_vbalance_x_rut = [
    {
      "clte_rut": 966921609,
      "vbal_ano_datos": 2017,
      "vbal_activo_circulante": null,
      "vbal_activo_fijo": null,
      "vbal_otros_activos": null,
      "vbal_pasivo_circulante": null,
      "vbal_pasivo_largo_plazo": null,
      "vbal_patrimonio": null,
      "vbal_ventas": null,
      "vbal_ratio_capital_de_trabajo": null,
      "vbal_ratio_capital_neto_tangible": null,
      "vbal_ratio_cobertura": null,
      "vbal_ratio_cobertura_ampliada": null,
      "vbal_ratio_deuda_financiera_neta_v_gener_int_fondos": null,
      "vbal_ratio_ebitda_v_gastos_financieros": null,
      "vbal_ratio_pas_exigible_cap_neto_tangible": null,
      "vbal_ratio_pasivo_lp_deficit_kdt_v_gener_int_fondos": null,
      "vbal_ratio_patrimonio_activo_permanente": null,
      "vbal_ratio_promedio_dias_cobranza": null,
      "vbal_ratio_promedio_dias_inventario": null,
      "vbal_ratio_promedio_dias_pago_proveedores": null,
      "vbal_ratio_razon_corriente": null,
      "vbal_ratio_resultado_neto": null,
      "vbal_ratio_resultado_neto_patrimonio": null,
      "vbal_ratio_resultado_neto_ventas": null,
      "vbal_ratio_test_acido": null,
      "vbal_ratio_variacion_real_ventas": null,
      "vbal_ratio_ventas_del_periodo": null,
      "bec_vbal_disponible": 408315,
      "bec_vbal_cuentas_por_cobrar": 0,
      "bec_vbal_existencia": 3396662,
      "bec_vbal_otros_activos_circulantes": 15648,
      "bec_vbal_activo_fijo": 20905,
      "bec_vbal_otros_activos_fijo": 0,
      "bec_vbal_deuda_financiera_corto_plazo": 2356311,
      "bec_vbal_deuda_financiera_lp_porcion_corriente": 492246,
      "bec_vbal_cuentas_por_pagar": 641208,
      "bec_vbal_otros_pasivos_circulante": 0,
      "bec_vbal_deuda_financiera_lp": 553091,
      "bec_vbal_patrimonio_neto_final": 1713798,
      "bec_vbal_ventas_resultados": null,
      "bec_vbal_ventas_del_periodo": 9720722,
      "bec_vbal_costo_ventas": -8500678,
      "bec_vbal_gav": -1829766,
      "bec_vbal_depreciacion": -3338,
      "bec_vbal_gastos_financieros": -304416,
      "bec_vbal_extraordinarios": 0,
      "bec_vbal_resultado_final": 581973,
      "bec_vbal_ebitda": 0,
      "bec_vbal_ratios_relevantes": null,
      "bec_vbal_politica_cuentas_por_cobrar_dias": 0,
      "bec_vbal_politica_existencias_dias": 114,
      "bec_vbal_politica_cuentas_por_pagar_dias": 26,
      "bec_vbal_politica_dividendos_dias": 0,
      "bec_vbal_pasivo_lp_deficit_kdt_v_gen_int_fondos": 0,
      "bec_vbal_variacion_activo_fijo": 0
    },
    {
      "clte_rut": 966921609,
      "vbal_ano_datos": 2016,
      "vbal_activo_circulante": "4353674",
      "vbal_activo_fijo": "21340",
      "vbal_otros_activos": "640431",
      "vbal_pasivo_circulante": "3126805",
      "vbal_pasivo_largo_plazo": "553091",
      "vbal_patrimonio": "1335549",
      "vbal_ventas": "11484233",
      "vbal_ratio_capital_de_trabajo": "1226869.00",
      "vbal_ratio_capital_neto_tangible": "1335549.00",
      "vbal_ratio_cobertura": "2.52",
      "vbal_ratio_cobertura_ampliada": "2.53",
      "vbal_ratio_deuda_financiera_neta_v_gener_int_fondos": "10.02",
      "vbal_ratio_ebitda_v_gastos_financieros": "2.12",
      "vbal_ratio_pas_exigible_cap_neto_tangible": "2.76",
      "vbal_ratio_pasivo_lp_deficit_kdt_v_gener_int_fondos": "3.35",
      "vbal_ratio_patrimonio_activo_permanente": "2.02",
      "vbal_ratio_promedio_dias_cobranza": "60.51",
      "vbal_ratio_promedio_dias_inventario": "93.24",
      "vbal_ratio_promedio_dias_pago_proveedores": "26.93",
      "vbal_ratio_razon_corriente": "1.39",
      "vbal_ratio_resultado_neto": "256098.00",
      "vbal_ratio_resultado_neto_patrimonio": "19.25",
      "vbal_ratio_resultado_neto_ventas": "2.23",
      "vbal_ratio_test_acido": "0.68",
      "vbal_ratio_variacion_real_ventas": "13.71",
      "vbal_ratio_ventas_del_periodo": "11484233.00",
      "bec_vbal_disponible": 202299,
      "bec_vbal_cuentas_por_cobrar": 19080,
      "bec_vbal_existencia": 3396662,
      "bec_vbal_otros_activos_circulantes": 0,
      "bec_vbal_activo_fijo": 24298,
      "bec_vbal_otros_activos_fijo": 0,
      "bec_vbal_deuda_financiera_corto_plazo": 1938255,
      "bec_vbal_deuda_financiera_lp_porcion_corriente": 0,
      "bec_vbal_cuentas_por_pagar": 987925,
      "bec_vbal_otros_pasivos_circulante": 0,
      "bec_vbal_deuda_financiera_lp": 258309,
      "bec_vbal_patrimonio_neto_final": 1763860,
      "bec_vbal_ventas_resultados": null,
      "bec_vbal_ventas_del_periodo": 11484233,
      "bec_vbal_costo_ventas": -6933267,
      "bec_vbal_gav": -2143884,
      "bec_vbal_depreciacion": -3229,
      "bec_vbal_gastos_financieros": -339526,
      "bec_vbal_extraordinarios": 0,
      "bec_vbal_resultado_final": 257124,
      "bec_vbal_ebitda": 0,
      "bec_vbal_ratios_relevantes": null,
      "bec_vbal_politica_cuentas_por_cobrar_dias": 0,
      "bec_vbal_politica_existencias_dias": -999,
      "bec_vbal_politica_cuentas_por_pagar_dias": 41,
      "bec_vbal_politica_dividendos_dias": 0,
      "bec_vbal_pasivo_lp_deficit_kdt_v_gen_int_fondos": 3,
      "bec_vbal_variacion_activo_fijo": 0
    },
    {
      "clte_rut": 966921609,
      "vbal_ano_datos": 2015,
      "vbal_activo_circulante": "4770616",
      "vbal_activo_fijo": "24298",
      "vbal_otros_activos": "640431",
      "vbal_pasivo_circulante": "3413176",
      "vbal_pasivo_largo_plazo": "258309",
      "vbal_patrimonio": "1763860",
      "vbal_ventas": "9720722",
      "vbal_ratio_capital_de_trabajo": "1357440.00",
      "vbal_ratio_capital_neto_tangible": "1763860.00",
      "vbal_ratio_cobertura": "3.45",
      "vbal_ratio_cobertura_ampliada": "3.46",
      "vbal_ratio_deuda_financiera_neta_v_gener_int_fondos": "4.31",
      "vbal_ratio_ebitda_v_gastos_financieros": "3.72",
      "vbal_ratio_pas_exigible_cap_neto_tangible": "2.08",
      "vbal_ratio_pasivo_lp_deficit_kdt_v_gener_int_fondos": "0.51",
      "vbal_ratio_patrimonio_activo_permanente": "2.65",
      "vbal_ratio_promedio_dias_cobranza": "83.97",
      "vbal_ratio_promedio_dias_inventario": "114.48",
      "vbal_ratio_promedio_dias_pago_proveedores": "41.62",
      "vbal_ratio_razon_corriente": "1.40",
      "vbal_ratio_resultado_neto": "581299.00",
      "vbal_ratio_resultado_neto_patrimonio": "32.99",
      "vbal_ratio_resultado_neto_ventas": "5.98",
      "vbal_ratio_test_acido": "0.75",
      "vbal_ratio_variacion_real_ventas": "14.97",
      "vbal_ratio_ventas_del_periodo": "9720722.00",
      "bec_vbal_disponible": 26280,
      "bec_vbal_cuentas_por_cobrar": 10688,
      "bec_vbal_existencia": 2219429,
      "bec_vbal_otros_activos_circulantes": 35503,
      "bec_vbal_activo_fijo": 21340,
      "bec_vbal_otros_activos_fijo": 0,
      "bec_vbal_deuda_financiera_corto_plazo": 1723171,
      "bec_vbal_deuda_financiera_lp_porcion_corriente": 320334,
      "bec_vbal_cuentas_por_pagar": 801583,
      "bec_vbal_otros_pasivos_circulante": 0,
      "bec_vbal_deuda_financiera_lp": 258309,
      "bec_vbal_patrimonio_neto_final": 1335549,
      "bec_vbal_ventas_resultados": null,
      "bec_vbal_ventas_del_periodo": 11484233,
      "bec_vbal_costo_ventas": -8500678,
      "bec_vbal_gav": -2143884,
      "bec_vbal_depreciacion": -2545,
      "bec_vbal_gastos_financieros": -277212,
      "bec_vbal_extraordinarios": 0,
      "bec_vbal_resultado_final": 257124,
      "bec_vbal_ebitda": 0,
      "bec_vbal_ratios_relevantes": null,
      "bec_vbal_politica_cuentas_por_cobrar_dias": 0,
      "bec_vbal_politica_existencias_dias": 114,
      "bec_vbal_politica_cuentas_por_pagar_dias": 41,
      "bec_vbal_politica_dividendos_dias": 0,
      "bec_vbal_pasivo_lp_deficit_kdt_v_gen_int_fondos": -999,
      "bec_vbal_variacion_activo_fijo": 0
    },
    {
      "clte_rut": 966921609,
      "vbal_ano_datos": 2014,
      "vbal_activo_circulante": "4153185",
      "vbal_activo_fijo": "20621",
      "vbal_otros_activos": "497754",
      "vbal_pasivo_circulante": "3101982",
      "vbal_pasivo_largo_plazo": "433127",
      "vbal_patrimonio": "1136451",
      "vbal_ventas": "8137592",
      "vbal_ratio_capital_de_trabajo": "1051203.00",
      "vbal_ratio_capital_neto_tangible": "1136451.00",
      "vbal_ratio_cobertura": "2.39",
      "vbal_ratio_cobertura_ampliada": "2.39",
      "vbal_ratio_deuda_financiera_neta_v_gener_int_fondos": "8.47",
      "vbal_ratio_ebitda_v_gastos_financieros": "2.49",
      "vbal_ratio_pas_exigible_cap_neto_tangible": "3.11",
      "vbal_ratio_pasivo_lp_deficit_kdt_v_gener_int_fondos": "1.46",
      "vbal_ratio_patrimonio_activo_permanente": "2.19",
      "vbal_ratio_promedio_dias_cobranza": "83.12",
      "vbal_ratio_promedio_dias_inventario": "136.80",
      "vbal_ratio_promedio_dias_pago_proveedores": "42.90",
      "vbal_ratio_razon_corriente": "1.34",
      "vbal_ratio_resultado_neto": "324690.00",
      "vbal_ratio_resultado_neto_patrimonio": "28.63",
      "vbal_ratio_resultado_neto_ventas": "3.99",
      "vbal_ratio_test_acido": "0.60",
      "vbal_ratio_variacion_real_ventas": "6.67",
      "vbal_ratio_ventas_del_periodo": "8137592.00",
      "bec_vbal_disponible": 86844,
      "bec_vbal_cuentas_por_cobrar": 0,
      "bec_vbal_existencia": 2279201,
      "bec_vbal_otros_activos_circulantes": 13827,
      "bec_vbal_activo_fijo": 20621,
      "bec_vbal_otros_activos_fijo": 0,
      "bec_vbal_deuda_financiera_corto_plazo": 2173356,
      "bec_vbal_deuda_financiera_lp_porcion_corriente": 0,
      "bec_vbal_cuentas_por_pagar": 715574,
      "bec_vbal_otros_pasivos_circulante": 0,
      "bec_vbal_deuda_financiera_lp": 433127,
      "bec_vbal_patrimonio_neto_final": 1136451,
      "bec_vbal_ventas_resultados": null,
      "bec_vbal_ventas_del_periodo": 8137592,
      "bec_vbal_costo_ventas": -6005211,
      "bec_vbal_gav": -1471922,
      "bec_vbal_depreciacion": 0,
      "bec_vbal_gastos_financieros": -276387,
      "bec_vbal_extraordinarios": 0,
      "bec_vbal_resultado_final": 325369,
      "bec_vbal_ebitda": 0,
      "bec_vbal_ratios_relevantes": null,
      "bec_vbal_politica_cuentas_por_cobrar_dias": 0,
      "bec_vbal_politica_existencias_dias": 136,
      "bec_vbal_politica_cuentas_por_pagar_dias": 42,
      "bec_vbal_politica_dividendos_dias": 0,
      "bec_vbal_pasivo_lp_deficit_kdt_v_gen_int_fondos": 1,
      "bec_vbal_variacion_activo_fijo": 0
    }
   ]

export const cliente_sitfinanciera_x_rut = 
    {
      "clte_rut": 762094746,
      "sfin_directa_deuda_total_sbif": 2095309,
      "sfin_directa_deuda_total_bbva": 372076,
      "sfin_directa_deuda_total_porcentaje": 17.76,
      "sfin_directa_deuda_hipotecario_sbif": 0,
      "sfin_directa_deuda_hipotecario_bbva": 0,
      "sfin_directa_deuda_hipotecario_porcentaje": 0,
      "sfin_directa_deuda_comercial_sbif": 2095309,
      "sfin_directa_deuda_comercial_bbva": 372076,
      "sfin_directa_deuda_comercial_porcentaje": 17.76,
      "sfin_directa_deuda_leasing_sbif": 0,
      "sfin_directa_deuda_leasing_bbva": 0,
      "sfin_directa_deuda_leasing_porcentaje": 0,
      "sfin_directa_deuda_linea_credito_sbif": 42200,
      "sfin_directa_deuda_linea_credito_bbva": 20000,
      "sfin_directa_deuda_linea_credito_porcentaje": 47.39,
      "sfin_indirecta_deuda_total_sbif": 0,
      "sfin_indirecta_deuda_total_bbva": 0,
      "sfin_indirecta_deuda_total_porcentaje": 0,
      "sfin_indirecta_deuda_hipotecario_sbif": null,
      "sfin_indirecta_deuda_hipotecario_bbva": null,
      "sfin_indirecta_deuda_hipotecario_porcentaje": null,
      "sfin_indirecta_deuda_comercial_sbif": null,
      "sfin_indirecta_deuda_comercial_bbva": null,
      "sfin_indirecta_deuda_comercial_porcentaje": null,
      "sfin_indirecta_deuda_leasing_sbif": null,
      "sfin_indirecta_deuda_leasing_bbva": null,
      "sfin_indirecta_deuda_leasing_porcentaje": null,
      "sfin_indirecta_deuda_linea_credito_sbif": null,
      "sfin_indirecta_deuda_linea_credito_bbva": null,
      "sfin_indirecta_deuda_linea_credito_porcentaje": null,
      "sfin_directa_deuda_contingente_sbif": 0,
      "sfin_directa_deuda_contingente_bbva": 0,
      "sfin_directa_deuda_contingente_porcentaje": 0,
      "sfin_directa_periodo_datos": 201810
    }
   
export const cliente_acciones_x_rut = [
    {
      "agen_rutcli": 762094746,
      "agen_id": 1739,
      "agen_codgestor": 304,
      "agen_codhgestor": "H701237",
      "agen_pers_id_gestor": "clmezacdj",
      "agen_tsic_fecha_dma": 0,
      "agen_tsic_ac_oportunidad": null,
      "agen_fecha_agdmto": "2020-07-26 18:38:05.8300000",
      "agen_perfil_agendador": "prog_carga_masiva",
      "agen_pers_id_agendador": "20190614UAF1",
      "agen_uadm_id_agendador": "n/a",
      "agen_regi_gestor": "n/a",
      "agen_uadm_gestor": "n/a",
      "agen_sucs_gestor": 163,
      "agen_tpk2_id_motivo": "carga_masiva",
      "agen_asunto": null,
      "agen_fecha_prox_ctto_dma": "18/06/2019",
      "agen_fecha_prox_ctto_hm": "00:00",
      "agen_fecha_ulti_ctto_dma": null,
      "agen_pers_id_ulti_ctto": null,
      "agen_tpk2_id_etapa": "agendamiento",
      "agen_tpk2_id_subetapa": "contacto",
      "agen_pers_id_modif": null,
      "agen_fecha_modif_dma": null,
      "agen_vigente": "S",
      "moti_tabp_alias": "Oportunidades Dirigidas",
      "resu_p_tabp_alias": "1. Contacto",
      "resu_p_tabp_nombre": "1. Contacto",
      "resu_h_tabp_alias": "1. Contacto",
      "resu_h_tabp_nombre": "1. Contacto",
      "agen_fecha_prox_ctto_plazo": -456,
      "agen_fecha_agdmto_plazo": 52,
      "agen_tipo_cliente": "cliente",
      "agen_tpk2_id_producto": "_na",
      "agen_monto_oper": null,
      "agen_tpk2_id_agdmto_origen": "prog_carga_masiva",
      "agen_tipo_agdmto": "agdmto_producto",
      "agen_camp_prod_asoc": "_na",
      "agen_fecha_ini_subetapa": null,
      "agen_fecha_ini_subetapa_plazo": null,
      "agen_tpk2_id_resul_etapa": null,
      "agen_fecha_control_etapa_dma": null,
      "agen_tpk2_id_resp_gestion": "ejec_plat",
      "agen_aget_id_actual": 1,
      "agen_campo1_control_etapa": null,
      "agen_campo2_control_etapa": null,
      "agen_campo3_control_etapa": null,
      "agen_pers_id_resp_gestion": "clmezacdj",
      "agen_tpk2_id_producto_rela": "_na",
      "agen_comentario": "Declaracion Jurada UAF vence el dia: 2019-06-18",
      "agen_pcom_ano": null,
      "agen_page_nkey": null,
      "agen_adj_visita_nlog": null,
      "agen_adj_visita_nfis": null,
      "agen_tpk2_id_tipo_alerta": "alerta_gestion",
      "agen_nombre_alerta": "Vencimiento UAF",
      "agen_tpk2_id_tipo_gestion": null,
      "prod_tabp_alias": "(no aplica)",
      "agen_fecha_agdmto_dma": "26/07/2020",
      "agen_tpk2_id_orig_nocliente": null,
      "agen_visi_part_banco": null,
      "agen_visi_part_empresa": null,
      "agen_visi_objetivo": null,
      "agen_visi_oportunidades": null,
      "agen_visi_compromisos": null,
      "agen_visi_observaciones": null,
      "agen_visi_tipo_visita": null,
      "tvis_tabp_alias": null
    },
    {
      "agen_rutcli": 762094746,
      "agen_id": 1561,
      "agen_codgestor": 304,
      "agen_codhgestor": "H701237",
      "agen_pers_id_gestor": "clmezacdj",
      "agen_tsic_fecha_dma": 0,
      "agen_tsic_ac_oportunidad": null,
      "agen_fecha_agdmto": "2020-07-26 18:37:59.2300000",
      "agen_perfil_agendador": "prog_carga_masiva",
      "agen_pers_id_agendador": "20190614A841",
      "agen_uadm_id_agendador": "n/a",
      "agen_regi_gestor": "n/a",
      "agen_uadm_gestor": "n/a",
      "agen_sucs_gestor": 163,
      "agen_tpk2_id_motivo": "carga_masiva",
      "agen_asunto": null,
      "agen_fecha_prox_ctto_dma": "18/06/2019",
      "agen_fecha_prox_ctto_hm": "00:00",
      "agen_fecha_ulti_ctto_dma": null,
      "agen_pers_id_ulti_ctto": null,
      "agen_tpk2_id_etapa": "agendamiento",
      "agen_tpk2_id_subetapa": "contacto",
      "agen_pers_id_modif": null,
      "agen_fecha_modif_dma": null,
      "agen_vigente": "S",
      "moti_tabp_alias": "Oportunidades Dirigidas",
      "resu_p_tabp_alias": "1. Contacto",
      "resu_p_tabp_nombre": "1. Contacto",
      "resu_h_tabp_alias": "1. Contacto",
      "resu_h_tabp_nombre": "1. Contacto",
      "agen_fecha_prox_ctto_plazo": -456,
      "agen_fecha_agdmto_plazo": 52,
      "agen_tipo_cliente": "cliente",
      "agen_tpk2_id_producto": "_na",
      "agen_monto_oper": null,
      "agen_tpk2_id_agdmto_origen": "prog_carga_masiva",
      "agen_tipo_agdmto": "agdmto_producto",
      "agen_camp_prod_asoc": "_na",
      "agen_fecha_ini_subetapa": null,
      "agen_fecha_ini_subetapa_plazo": null,
      "agen_tpk2_id_resul_etapa": null,
      "agen_fecha_control_etapa_dma": null,
      "agen_tpk2_id_resp_gestion": "ejec_plat",
      "agen_aget_id_actual": 1,
      "agen_campo1_control_etapa": null,
      "agen_campo2_control_etapa": null,
      "agen_campo3_control_etapa": null,
      "agen_pers_id_resp_gestion": "clmezacdj",
      "agen_tpk2_id_producto_rela": "_na",
      "agen_comentario": "Declaracion Jurada Art. 85 vence el dia: 2019-06-18",
      "agen_pcom_ano": null,
      "agen_page_nkey": null,
      "agen_adj_visita_nlog": null,
      "agen_adj_visita_nfis": null,
      "agen_tpk2_id_tipo_alerta": "alerta_gestion",
      "agen_nombre_alerta": "Vencimiento Art-85",
      "agen_tpk2_id_tipo_gestion": null,
      "prod_tabp_alias": "(no aplica)",
      "agen_fecha_agdmto_dma": "26/07/2020",
      "agen_tpk2_id_orig_nocliente": null,
      "agen_visi_part_banco": null,
      "agen_visi_part_empresa": null,
      "agen_visi_objetivo": null,
      "agen_visi_oportunidades": null,
      "agen_visi_compromisos": null,
      "agen_visi_observaciones": null,
      "agen_visi_tipo_visita": null,
      "tvis_tabp_alias": null
    }
   ]


   export const cliente_atributos_x_rut = 
    {
      "clte_rut": 966921609,
      "clte_razon_social": "RETAIL S A                    ",
      "clte_actualizacion_financiera": null,
      "clte_aduana_exportaciones": 0,
      "clte_aduana_importaciones": 3783,
      "clte_ciudad": "",
      "clte_clasificacion_sbif": "",
      "clte_cliente_activo": "S",
      "clte_cod_gestor": 7572,
      "clte_comuna": "",
      "clte_correo": "",
      "clte_deterioro": "Sin Deterioro",
      "clte_dicom": "N",
      "clte_digital": "N",
      "clte_direccion": "",
      "clte_direccion_pu": "",
      "clte_fecha_fuga": null,
      "clte_fecha_ingreso": null,
      "clte_movil": null,
      "clte_nro_acreedores": null,
      "clte_nro_productos": null,
      "clte_numero_cliente": "",
      "clte_rubro": "H - COMERCIO AL POR MAYOR Y ME",
      "clte_salem": 22,
      "clte_segmento": "",
      "clte_telefonos": "",
      "clte_tipo_cliente": null,
      "clte_tipo_persona": "",
      "clte_ifin_deterioro": null,
      "clte_ifin_deuda_bbva": null,
      "clte_ifin_deuda_leasing": null,
      "clte_ifin_deuda_sbif": null,
      "clte_ifin_salem_roe": null,
      "clte_prod_boletas_garantia_tenencia": "N",
      "clte_prod_boletas_garantia_monto": null,
      "clte_prod_boletas_garantia_spread": null,
      "clte_prod_comerciales_con_garantia_tenencia": "N",
      "clte_prod_comerciales_con_garantia_monto": null,
      "clte_prod_comerciales_con_garantia_spread": null,
      "clte_prod_comerciales_sin_garantia_tenencia": "N",
      "clte_prod_comerciales_sin_garantia_monto": null,
      "clte_prod_comerciales_sin_garantia_spread": null,
      "clte_prod_comex_colocaciones_tenencia": "N",
      "clte_prod_comex_colocaciones_monto": null,
      "clte_prod_comex_colocaciones_spread": null,
      "clte_prod_credito_comercial_tenencia": "N",
      "clte_prod_credito_comercial_monto": null,
      "clte_prod_credito_comercial_spread": null,
      "clte_prod_cuenta_corriente_tenencia": "N",
      "clte_prod_cuenta_corriente_saldo_promedio": null,
      "clte_prod_factoring_tenencia": "N",
      "clte_prod_factoring_monto": null,
      "clte_prod_factoring_spread": null,
      "clte_prod_fogain_tenencia": "N",
      "clte_prod_fogain_monto": null,
      "clte_prod_fogain_spread": null,
      "clte_prod_fogape_tenencia": "N",
      "clte_prod_fogape_monto": null,
      "clte_prod_fogape_spread": null,
      "clte_prod_hipotecario_ffgg_tenencia": "N",
      "clte_prod_hipotecario_ffgg_monto": null,
      "clte_prod_hipotecario_ffgg_spread": null,
      "clte_prod_hipotecario_vivienda_tenencia": "N",
      "clte_prod_hipotecario_vivienda_monto": null,
      "clte_prod_hipotecario_vivienda_spread": null,
      "clte_prod_leasing_tenencia": "N",
      "clte_prod_leasing_monto": null,
      "clte_prod_leasing_spread": null,
      "clte_prod_linea_credito_tenencia": "N",
      "clte_prod_linea_credito_utilizado": null,
      "clte_prod_linea_credito_cupo": null,
      "clte_prod_margen_kt_tenencia": "N",
      "clte_prod_margen_kt_utilizado": null,
      "clte_prod_margen_kt_cupo": null,
      "clte_prod_plan_cuenta_corriente_tenencia": "N",
      "clte_prod_sgr_tenencia": "N",
      "clte_prod_sgr_monto": null,
      "clte_prod_sgr_spread": null,
      "clte_prod_tarjeta_credito_tenencia": "N",
      "clte_prod_tarjeta_credito_utilizado": null,
      "clte_prod_tarjeta_credito_cupo": null,
      "clte_serv_clave_acceso_cl_tenencia": "N",
      "clte_serv_clave_acceso_netcash_tenencia": "N",
      "clte_serv_fx_tenencia": "N",
      "clte_serv_fx_ultimo_mes": null,
      "clte_serv_fx_ultimos_tres_meses": null,
      "clte_serv_impuestos_tenencia": "N",
      "clte_serv_impuestos_ultimo_mes": null,
      "clte_serv_impuestos_ultimos_tres_meses": null,
      "clte_serv_pagos_proveedores_tenencia": "N",
      "clte_serv_pagos_proveedores_ultimo_mes": null,
      "clte_serv_pagos_proveedores_ultimos_tres_meses": null,
      "clte_serv_pagos_remuneraciones_tenencia": "N",
      "clte_serv_pagos_remuneraciones_ultimo_mes": null,
      "clte_serv_pagos_remuneraciones_ultimos_tres_meses": null,
      "clte_serv_recaudacion_tenencia": "N",
      "clte_serv_recaudacion_ultimo_mes": null,
      "clte_serv_recaudacion_ultimos_tres_meses": null,
      "clte_serv_transbank_tenencia": "N",
      "clte_serv_transbank_ultimo_mes": null,
      "clte_serv_transbank_ultimos_tres_meses": null,
      "clte_serv_transferencias_masivas_tenencia": "N",
      "clte_serv_transferencias_masivas_ultimo_mes": null,
      "clte_serv_transferencias_masivas_ultimos_tres_meses": null,
      "clte_inve_acciones_tenencia": "N",
      "clte_inve_acciones_monto": null,
      "clte_inve_dap_tenencia": "N",
      "clte_inve_dap_monto": null,
      "clte_inve_fondos_mutuos_tenencia": "N",
      "clte_inve_fondos_mutuos_monto": null,
      "clte_inve_forward_tenencia": "N",
      "clte_inve_forward_monto": null,
      "clte_dias_ingreso_antiguedad": 586,
      "clte_fecha_fuga_dma": null,
      "clte_fecha_ingreso_dma": null,
      "clte_adic_telefonos": null,
      "clte_adic_correo": null,
      "clte_adic_direccion": null,
      "clte_prod_credito_comercial_cp_tenencia": "N",
      "clte_prod_credito_comercial_cp_monto": null,
      "clte_prod_credito_comercial_cp_spread": null,
      "clte_prod_credito_comercial_lp_tenencia": "N",
      "clte_prod_credito_comercial_lp_monto": null,
      "clte_prod_credito_comercial_lp_spread": null,
      "clte_prod_garantias_tenencia": "N",
      "clte_prod_garantias_monto": null,
      "clte_serv_comex_transferencias_mx_tenencia": "N",
      "clte_serv_comex_transferencias_mx_ultimo_mes": null,
      "clte_serv_comex_transferencias_mx_ultimos_tres_meses": null,
      "clte_prod_cuenta_corriente_mono_tenencia": "N",
      "clte_prod_linea_credito_mono_tenencia": "N",
      "bec_clte_margen_renovado_monto_ano": "N",
      "bec_clte_nivel_digital": "M",
      "bec_clte_nivel_venta": 11962315,
      "bec_clte_nro_productos": null,
      "bec_clte_rating": "A5",
      "bec_clte_roa_empresa": "roa_3",
      "bec_clte_segmento_venta": "200M - 600M UF",
      "bec_clte_vinculacion_cliente": null,
      "bec_margen_rotativo_fecha_vencimiento": null,
      "bec_margen_rotativo_total_aprobado": null,
      "bec_margen_rotativo_credito_moneda_local_aprobado": null,
      "bec_margen_rotativo_comex_aprobado": null,
      "bec_margen_rotativo_oper_en_moneda_ext_no_comex_aprobado": null,
      "bec_margen_rotativo_boleta_garantia_tecnica_aprobado": null,
      "bec_margen_rotativo_boleta_garantia_financiera_aprobado": null,
      "bec_margen_rotativo_leasing_mobiliario_aprobado": null,
      "bec_margen_rotativo_factoring_banco_aprobado": null,
      "bec_margen_rotativo_confirming_banco_aprobado": null,
      "bec_margen_rotativo_factoring_tradicional_aprobado": null,
      "bec_margen_rotativo_factoring_financiero_aprobado": null,
      "bec_margen_rotativo_linea_sobregiro_aprobado": null,
      "bec_margen_rotativo_total_disponible": null,
      "bec_margen_rotativo_credito_moneda_local_disponible": null,
      "bec_margen_rotativo_comex_disponible": null,
      "bec_margen_rotativo_oper_en_moneda_ext_no_comex_disponible": null,
      "bec_margen_rotativo_boleta_garantia_tecnica_disponible": null,
      "bec_margen_rotativo_boleta_garantia_financiera_disponible": null,
      "bec_margen_rotativo_leasing_mobiliario_disponible": null,
      "bec_margen_rotativo_factoring_banco_disponible": null,
      "bec_margen_rotativo_confirming_banco_disponible": null,
      "bec_margen_rotativo_factoring_tradicional_disponible": null,
      "bec_margen_rotativo_factoring_financiero_disponible": null,
      "bec_margen_rotativo_linea_sobregiro_disponible": null,
      "bec_prod_boletas_garantia_tenencia": "N",
      "bec_prod_boletas_garantia_monto": null,
      "bec_prod_carta_credito_tenencia": null,
      "bec_prod_carta_credito_imp_tenencia": null,
      "bec_prod_carta_credito_imp_monto": null,
      "bec_prod_colocaciones_contingentes_tenencia": null,
      "bec_prod_colocaciones_contingentes_monto": null,
      "bec_prod_colocaciones_efectivas_tenencia": null,
      "bec_prod_colocaciones_efectivas_monto": null,
      "bec_prod_confirming_tenencia": null,
      "bec_prod_confirming_monto": null,
      "bec_prod_credito_comercial_me_tenencia": null,
      "bec_prod_credito_comercial_me_monto": null,
      "bec_prod_credito_comercial_mn_cp_tenencia": null,
      "bec_prod_credito_comercial_mn_cp_monto": null,
      "bec_prod_credito_comercial_mn_lp_tenencia": null,
      "bec_prod_credito_comercial_mn_lp_monto": null,
      "bec_prod_cuenta_corriente_dolar_tenencia": null,
      "bec_prod_cuenta_corriente_dolar_saldo_prom_ult_mes": null,
      "bec_prod_cuenta_corriente_peso_tenencia": null,
      "bec_prod_cuenta_corriente_peso_saldo_prom_ult_mes": null,
      "bec_prod_derivados_tenencia": null,
      "bec_prod_derivados_monto": null,
      "bec_prod_factoring_financiero_tenencia": null,
      "bec_prod_factoring_financiero_monto": null,
      "bec_prod_factoring_tradicional_tenencia": null,
      "bec_prod_factoring_tradicional_monto": null,
      "bec_prod_fin_import_ccred_fcon_tenencia": null,
      "bec_prod_financiamiento_import_tenencia": null,
      "bec_prod_financiamiento_import_monto": null,
      "bec_prod_forward_tenencia": null,
      "bec_prod_forward_monto": null,
      "bec_prod_leasing_inmobiliario_tenencia": null,
      "bec_prod_leasing_inmobiliario_monto": null,
      "bec_prod_leasing_mobiliario_tenencia": null,
      "bec_prod_leasing_mobiliario_monto": null,
      "bec_prod_linea_credito_tenencia": null,
      "bec_prod_linea_credito_aprobado": null,
      "bec_prod_linea_credito_disponible": null,
      "bec_prod_margen_rotativo_tenencia": null,
      "bec_prod_pae_tenencia": null,
      "bec_prod_pae_monto": null,
      "bec_prod_stand_by_tenencia": null,
      "bec_prod_stand_by_monto": null,
      "bec_prod_tarjeta_credito_tenencia": null,
      "bec_prod_tarjeta_credito_aprobado": null,
      "bec_prod_tarjeta_credito_disponible": null,
      "bec_serv_bbva_net_cash_tenencia": null,
      "bec_serv_bbva_net_cash_habilitacion": null,
      "bec_serv_comex_carta_credito_tenencia": null,
      "bec_serv_comex_carta_credito_canal": null,
      "bec_serv_comex_carta_credito_ultimo_mes": null,
      "bec_serv_comex_carta_credito_ultimos_tres_meses": null,
      "bec_serv_comex_ope_tenencia": null,
      "bec_serv_comex_ope_canal": null,
      "bec_serv_comex_ope_ultimo_mes": null,
      "bec_serv_comex_ope_ultimos_tres_meses": null,
      "bec_serv_comex_opr_tenencia": null,
      "bec_serv_comex_opr_canal": null,
      "bec_serv_comex_opr_ultimo_mes": null,
      "bec_serv_comex_opr_ultimos_tres_meses": null,
      "bec_serv_compra_venta_divisa_tenencia": null,
      "bec_serv_compra_venta_divisa_canal": null,
      "bec_serv_compra_venta_divisa_ultimo_mes": null,
      "bec_serv_compra_venta_divisa_ultimos_tres_meses": null,
      "bec_serv_deposito_plazo_tenencia": null,
      "bec_serv_dispositivo_movil_tenencia": null,
      "bec_serv_dispositivo_movil_habilitacion": null,
      "bec_serv_e_confirming_tenencia": null,
      "bec_serv_e_confirming_habilitacion": null,
      "bec_serv_fondos_mutuos_tenencia": null,
      "bec_serv_fx_tenencia": null,
      "bec_serv_fx_habilitacion": null,
      "bec_serv_impuestos_tenencia": null,
      "bec_serv_impuestos_canal": null,
      "bec_serv_impuestos_ultimo_mes": null,
      "bec_serv_impuestos_ultimos_tres_meses": null,
      "bec_serv_pago_proveedores_tenencia": null,
      "bec_serv_pago_proveedores_canal": null,
      "bec_serv_pago_proveedores_ultimo_mes": null,
      "bec_serv_pago_proveedores_ultimos_tres_meses": null,
      "bec_serv_pago_remuneraciones_tenencia": null,
      "bec_serv_pago_remuneraciones_canal": null,
      "bec_serv_pago_remuneraciones_ultimo_mes": null,
      "bec_serv_pago_remuneraciones_ultimos_tres_meses": null,
      "bec_serv_recaudacion_tenencia": null,
      "bec_serv_recaudacion_canal": null,
      "bec_serv_recaudacion_ultimo_mes": null,
      "bec_serv_recaudacion_ultimos_tres_meses": null,
      "bec_serv_transbank_tenencia": null,
      "bec_serv_transbank_canal": null,
      "bec_serv_transbank_ultimo_mes": null,
      "bec_serv_transbank_ultimos_tres_meses": null,
      "bec_serv_transferencias_alto_valor_tenencia": null,
      "bec_serv_transferencias_alto_valor_canal": null,
      "bec_serv_transferencias_alto_valor_ultimo_mes": null,
      "bec_serv_transferencias_alto_valor_ultimos_tres_meses": null,
      "bec_serv_transferencias_masivas_tenencia": null,
      "bec_serv_transferencias_masivas_canal": null,
      "bec_serv_transferencias_masivas_ultimo_mes": null,
      "bec_serv_transferencias_masivas_ultimos_tres_meses": null,
      "bec_serv_uso_bbva_net_cash_tenencia": null,
      "bec_serv_uso_bbva_net_cash_habilitacion": null,
      "bec_serv_web_comex_tenencia": null,
      "bec_serv_web_comex_habilitacion": null,
      "bec_serv_web_leasing_tenencia": null,
      "bec_serv_web_leasing_habilitacion": null,
      "bec_prod_cuenta_corriente_euro_tenencia": null,
      "bec_prod_cuenta_corriente_euro_saldo_prom_ult_mes": null,
      "bec_prod_opciones_tenencia": null,
      "bec_prod_opciones_saldo_prom_ult_mes": null,
      "bec_margen_rotativo_moneda": null,
      "bec_prod_opciones_monto": null
    }
   