import React from 'react';
import Grid from 'canvas-core-react/lib/Grid';
import Row from 'canvas-core-react/lib/Row';
import Col from 'canvas-core-react/lib/Column';
import { useHttpGet as useHttp, MsgeLoading } from './useHttp';
import {serviciosSIC} from '../data/const_servicios'
import SicGraficoBarraH4 from "./SicGraficoBarraH4"
import '../Sic.scss';

const PanelControlAcciones = ({bcas_id, gcia_id, plat_id, gest_id}) => {

    const params = `caso=${serviciosSIC.serv_panel_control_acciones_data}&bcas_id=${bcas_id}&gcia_id=${gcia_id}&plat_id=${plat_id}&gest_id=${gest_id}`
    const [post, isLoading] = useHttp(params)
    const panel_acciones = ((isLoading) ? [] : post)
    const mensajeCarga = () => {if (isLoading) return(<MsgeLoading/>)}

    const paramsAcciones = {
      title : "DISTRIBUCIÓN POR MOTIVO",
      subtitle : ""
    }
  
return(
    <>
    {mensajeCarga()} 
    <div className='sic-container'>
    <Grid >
        <Row className="Control__contenedor">
            <Col lg={6} md={6} sm={4} xs={2}>

              <div className="sic-container sic-margin-top">

                <table className="sic-table sic-hoverable sic-striped">

                  <colgroup></colgroup>
                  <colgroup></colgroup>
                  <colgroup></colgroup>
                  <thead>
                    <tr className="sic-xmedium sic-color-dark-blue sic-background-color-thead">
                      <th className="sic-border sic-align-center" rowSpan="2">Motivo</th>
                      <th className="sic-border sic-align-center" colSpan="3">Atrasadas</th>
                      <th className="sic-border sic-align-center" colSpan="3">Al día</th>
                      <th className="sic-border sic-align-center" rowSpan="2">Total</th>
                    </tr>
                    <tr className="sic-medium sic-color-dark-blue sic-background-color-thead">
                      <th className="sic-border" >Ejec.<br/>Com.</th>
                      <th className="sic-border" >Ejec.<br/>Prod.</th>
                      <th className="sic-border" >Total</th>
                      <th className="sic-border" >Ejec.<br/>Com.</th>
                      <th className="sic-border" >Ejec.<br/>Prod.</th>
                      <th className="sic-border" >Total</th>
                    </tr>
                  </thead>

                  <tbody>
                  {
                    panel_acciones.map((data, idx) => (
                      <tr className="sic-medium" key={"accs_"+idx}>
                        <td className="sic-border">{data.accs_motivo}</td>

                        <td className="sic-border sic-align-center">{data.accs_ec_atras}</td>
                        <td className="sic-border sic-align-center">{data.accs_ep_atras}</td>
                        <td className="sic-border sic-align-center">{data.accs_tt_atras}</td>

                        <td className="sic-border sic-align-center">{data.accs_ec_aldia}</td>
                        <td className="sic-border sic-align-center">{data.accs_ep_aldia}</td>
                        <td className="sic-border sic-align-center">{data.accs_tt_aldia}</td>

                        <td className="sic-border sic-align-center">{data.accs_tt_gral}</td>
                      </tr>
                    ))
                  }
                    </tbody>
                </table>
              </div>

            </Col>

            <Col lg={6} md={6} sm={2} xs={2}>
                <SicGraficoBarraH4 
                  params={paramsAcciones} 
                  categorias={panel_acciones.map (cont => cont.accs_motivo)}
                  serie1={panel_acciones.map (cont => parseInt(cont.accs_ec_aldia))} 
                  serie2={panel_acciones.map (cont => parseInt(cont.accs_ep_aldia))} 
                  serie3={panel_acciones.map (cont => parseInt(cont.accs_ec_atras))} 
                  serie4={panel_acciones.map (cont => parseInt(cont.accs_ep_atras))} 
                />
            </Col>
        </Row>
    </Grid>
    </div>
    </>
)
};

export default PanelControlAcciones;
