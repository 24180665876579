import React from 'react';
import Grid from 'canvas-core-react/lib/Grid';
import Row from 'canvas-core-react/lib/Row';
import Col from 'canvas-core-react/lib/Column';
import PanelControlAcciones from './PanelControlAcciones';
import PanelControlAlertas from './PanelControlAlertas';
import PanelControlTuboComercial from './PanelControlTuboComercial';
import PanelControlTuboProducto from './PanelControlTuboProducto';
import PanelControlVisitasComercial from './PanelControlVisitasComercial';
import PanelControlVisitasProducto from './PanelControlVisitasProducto';

import '../Sic.scss';

const PanelControl = ({bcas_id, gcia_id, plat_id, gest_id}) => (

<div className='sic-container'>
<Grid >

     <Row className="Control__contenedor">
        <Col lg={12} md={12} sm={8} xs={4}>
            <PanelControlTuboComercial bcas_id={bcas_id} gcia_id={gcia_id} plat_id={plat_id} gest_id={gest_id} />
        </Col>
    </Row>
 
    <Row className="Control__contenedor">
        <Col lg={12} md={12} sm={8} xs={4}>
            <PanelControlTuboProducto bcas_id={bcas_id} gcia_id={gcia_id} plat_id={plat_id} gest_id={gest_id} />
        </Col>
    </Row>

    <Row className="Control__contenedor">
        <Col lg={12} md={12} sm={8} xs={4}>
            <PanelControlVisitasComercial bcas_id={bcas_id} gcia_id={gcia_id} plat_id={plat_id} gest_id={gest_id} />
        </Col>
    </Row>
 
    <Row className="Control__contenedor">
        <Col lg={12} md={12} sm={8} xs={4}>
            <PanelControlVisitasProducto bcas_id={bcas_id} gcia_id={gcia_id} plat_id={plat_id} gest_id={gest_id} />
        </Col>
    </Row>

    <Row className="Control__contenedor">
        <Col lg={12} md={12} sm={8} xs={4}>
            <PanelControlAlertas bcas_id={bcas_id} gcia_id={gcia_id} plat_id={plat_id} gest_id={gest_id} />
        </Col>
    </Row>

    <Row className="Control__contenedor">
        <Col lg={12} md={12} sm={8} xs={4}>
            <PanelControlAcciones bcas_id={bcas_id} gcia_id={gcia_id} plat_id={plat_id} gest_id={gest_id} />
        </Col>
    </Row>


</Grid>
</div>

);

export default PanelControl;
