import React from 'react';
import Grid from 'canvas-core-react/lib/Grid';
import Row from 'canvas-core-react/lib/Row';
import Col from 'canvas-core-react/lib/Column';
import { cliente_acciones_x_rut } from '../data/get_clientes';

import '../Sic.scss';

const FichaClienteAcciones = () => (
<>

<Grid className="sic-color-black sic-xmedium">
    <Row className="Control__contenedor">
        <Col lg={12} md={12} sm={8} xs={4}>

		<table className="sic-table sic-table-all">
			<thead>
				<tr>
					<th className="sic-large sic-color-blue sic-align-center" colSpan="12">ACCIONES ABIERTAS</th>
				</tr>
				<tr>
					<th className="sic-color-blue sic-align-center"></th>
					<th className="sic-color-blue sic-align-center">Fecha</th>
					<th className="sic-color-blue sic-align-center">Producto</th>
					<th className="sic-color-blue sic-align-center">Responsable</th>
					<th className="sic-color-blue sic-align-center">Etapa</th>
					<th className="sic-color-blue sic-align-center">Motivo</th>
					<th className="sic-color-blue sic-align-center">D&iacute;as Etapa</th>
					<th className="sic-color-blue sic-align-center">D&iacute;as Tubo</th>
					<th className="sic-color-blue sic-align-center">Cierre M&uacute;ltiple</th>
					<th className="sic-color-blue sic-align-center">Bit&aacute;cora</th>
				</tr>
			</thead>
			<tbody>
			{
				cliente_acciones_x_rut.map((data, idx) => (
				<tr className="sic-medium" key={"venc_"+idx}>
					<td className="sic-align-center sic-border">{idx + 1}</td>
					<td className="sic-align-center sic-border">{data.agen_fecha_prox_ctto_dma}</td>
					<td className="sic-align-left sic-border">{data.prod_tabp_alias}</td>
					<td className="sic-align-left sic-border">{data.agen_tpk2_id_resp_gestion}</td>
					<td className="sic-align-left sic-border">{data.resu_p_tabp_alias}</td>
					<td className="sic-align-left sic-border">{data.moti_tabp_alias}</td>
					<td className="sic-align-center sic-border">{data.agen_fecha_prox_ctto_plazo}</td>
					<td className="sic-align-center sic-border">{data.agen_fecha_agdmto_plazo}</td>
					<td className="sic-align-right sic-border"></td>
					<td className="sic-align-right sic-border"></td>
				</tr>
				))
			}
			</tbody>
		</table>

        </Col>
    </Row>

    <Row className="Control__contenedor">
        <Col lg={12} md={12} sm={8} xs={4}>

		<div className="sic-container sic-linea-separacion-1"/>

		<table className="sic-table sic-padding">
			<thead>
				<tr>
					<th className="sic-large sic-color-blue sic-align-left sic-padding" colSpan="4">Acci&oacute;n comercial (modo editar)</th>
				</tr>
			</thead>

			<tbody>
				<tr className="sic-border sic-padding">
					<td className="sic-border sic-color-blue sic-align-center">Producto</td>
					<td className="sic-border">
						Leasing / Leaseback
						<input type="hidden" name="s_tpk2_id_producto" id="s_tpk2_id_producto" value="leasing" />
					</td>

					<td className="sic-border sic-color-blue sic-align-center">Motivo</td>
					<td className="sic-border">
						Oportunidades Dirigidas
						<input type="hidden" name="s_tpk2_id_motivo" id="s_tpk2_id_motivo" value="carga_masiva" />
					</td>
				</tr>
				<tr>
					<td colSpan="4">
						<table className="sic-table">
							<tbody>
								<tr>
									<td>
										<div><label className="sic-label">Fecha Agenda</label></div>
										<input className="sic-input-2" type="date" name="t_ahis_fecha_prox_ctto" id="t_ahis_fecha_prox_ctto" size="20"/>
									</td>

									<td>
										<div><label className="sic-label">Etapa</label></div>
										<select className="sic-select" name="s_tpk2_id_e_subetapa_sgte">
											<option value="_SELE" >(seleccionar)</option>
										</select>
										<input type="hidden" name="s_tpk2_id_e_subetapa_actual" id="s_tpk2_id_e_subetapa_actual" value="visita|visita" />
									</td>

									<td>
										<div><label className="sic-label">Responsable Gesti&oacute;n</label></div>
										<select className="sic-select" name="s_tpk2_id_resp_gestion">
											<option value="ejec_plat||">Ejecutivo de Cuentas</option>
										</select>
									</td>
								</tr>
							</tbody>
						</table>
					</td>
				</tr>

				<tr>
					<td colSpan="4">

						<table className="sic-table sic-border">
							<tbody>
							<tr>
								<td className="sic-border" width="40%">
									<label className="sic-label">Anotaci&oacute;n</label>
									<textarea className="sic-input-4" name="t_ahis_comentario"></textarea>
									<br/>
									<span className="sic-small">&Uacute;ltima actualizaci&oacute;n: 15/05/2019<br/></span>
									<span>(no registra anotaci&oacute;n)</span>
								</td>
							</tr>
							</tbody>
						</table>
					</td>
				</tr>
			</tbody>
		</table>

        </Col>
    </Row>
    <Row className="Control__contenedor">
        <Col lg={12} md={12} sm={8} xs={4}>

		<div className="sic-container sic-linea-separacion-1"/>

		<table className="sic-table sic-border">
			<tbody>
			<tr>
				<td className="sic-border" width="25%">
					<label className="sic-label">Leasing Mobiliario/ Inmobiliario</label>
					<select className="sic-select" name="tp_tpk2_id_tipo_trans__leasing">
						<option value="_SELE">(seleccionar)</option>
						<option value="bilateral">Bilateral</option>
						<option value="sindicado">Sindicado</option>
						<option value="vehiculos_leasing">Vehículos - Leasing</option>
						<option value="maq_industrial_leasing">Maq. Industrial - Leasing</option>
						<option value="maq_pesada_leasing">Maq. Pesada - Leasing</option>
						<option value="maq_electr_leasing">Equipos Elec. - Leasing</option>
					</select>
				</td>
				<td className="sic-border" width="25%">
					<label className="sic-label">Estructura (Bullet/Cuota)</label>
					<select className="sic-select" name="tp_tpk2_id_estructura__leasing">
						<option value="_SELE">(selec)</option>
						<option value="aleman">Amortización</option>
						<option value="bullet">Bullet</option>
					</select>
				</td>
				<td className="sic-border" width="25%">
					<label className="sic-label">Moneda</label>
					<select className="sic-select" name="tp_tpk2_id_moneda__leasing">
						<option value="_SELE">(seleccionar)</option>
						<option value="mm_$">MM $</option>
						<option value="m_usd">M USD</option>
						<option value="m_euro">M EURO</option>
						<option value="uf">UF</option>
					</select>
				</td>
				<td className="sic-border" width="25%">
					<label className="sic-label">Monto/ Colocación</label>
					<input className="sic-input" type="text"/>
				</td>
			</tr>
			<tr>
				<td className="sic-border">
					<label className="sic-label">Plazo</label>
					<div className="sic-bar">
					<div className="sic-bar-item">
					<input className="sic-input" type="text" name="tp_agpr_plazo__leasing" id="tp_agpr_plazo__leasing" size="10"/>
					<select className="sic-select" name="tp_tpk2_id_plazo__leasing">
						<option value="_SELE">(selec)</option>
						<option value="semanas">Semanas</option>
						<option value="meses">Meses</option>
						<option value="anos">Años</option>
					</select>
					</div>
					</div>
				</td>
				<td className="sic-border">
					<label className="sic-label">Spread</label>
					<input className="sic-input" type="text" name="tp_agpr_spread__leasing" id="tp_agpr_spread__leasing"/>
				</td>
				<td className="sic-border">
					<label className="sic-label">Monto Comisión</label>
					<input className="sic-input" type="text" name="tp_agpr_comision__leasing" id="tp_agpr_comision__leasing"/>
				</td>
				<td className="sic-border">
					<label className="sic-label">Spread Anterior</label>
					<input className="sic-input" type="text" name="tp_agpr_spread_ant__leasing" id="tp_agpr_spread_ant__leasing"/>
				</td>
			</tr>
			<tr>
				<td className="sic-border">
					<label className="sic-label">Monto LCR</label>
					<input className="sic-input" type="text" name="tp_agpr_monto_lcr__leasing" id="tp_agpr_monto_lcr__leasing"/>
				</td>
				<td className="sic-border">
					<label className="sic-label">Tasa</label>
					<input className="sic-input" type="text" name="tp_agpr_tasa__leasing" id="tp_agpr_tasa__leasing"/>
				</td>
				<td className="sic-border">
					<label className="sic-label">Toma Banco (%)</label>
					<input className="sic-input" type="text" name="tp_agpr_toma_banco__leasing" id="tp_agpr_toma_banco__leasing"/>
				</td>
				<td className="sic-border">
					<label className="sic-label">Tarjeta de crédito</label>
					<select className="sic-select" name="tp_agpr_tarjeta_credito__leasing">
						<option value="_SELE">(selec)</option>
						<option value="plan_con_tarjeta">Plan Con Tarjeta</option>
						<option value="plan_sin_tarjeta">Plan Sin Tarjeta</option>
					</select>
				</td>
			</tr>
			<tr>
				<td className="sic-border">
					<label className="sic-label">Prop. Electrónica</label>
					<input className="sic-input" type="text" name="tp_agpr_prop_electronica__leasing" id="tp_agpr_prop_electronica__leasing"/>
				</td>
				<td className="sic-border">
					<label className="sic-label">Utilidad Derivado</label>
					<input className="sic-input" type="text" name="tp_agpr_monto_tcr__leasing" id="tp_agpr_monto_tcr__leasing"/>
				</td>
				<td className="sic-border">
					<label className="sic-label">Saldo Prom. Previsto</label>
					<input className="sic-input" type="text" name="tp_agpr_saldo_promedio_previsto__leasing" id="tp_agpr_saldo_promedio_previsto__leasing"/>
				</td>
				<td className="sic-border">
					<label className="sic-label">Fecha Probable Curse o Pago</label>
					<input className="sic-input-2" type="date" name="tp_agpr_fecha_prob_cierre__leasing" id="tp_agpr_fecha_prob_cierre__leasing" size="20"/>
				</td>
			</tr>
			<tr>
				<td className="sic-border">
					<label className="sic-label">Prima Anual</label>
					<input className="sic-input" type="text" name="tp_agpr_prima_anual__leasing" id="tp_agpr_prima_anual__leasing"/>
				</td>
				<td className="sic-border">
					<label className="sic-label">Periodicidad</label>
					<select className="sic-select" name="tp_agpr_periodicidad__leasing">
						<option value="_SELE">(selec)</option>
						<option value="prueba">Periodicidad #1</option>
					</select>
				</td>
				<td className="sic-border">
					<label className="sic-label">Tipo Seguro</label>
					<select className="sic-select" name="tp_tpk2_id_tipo_seguro__leasing">
						<option value="_SELE">(selec)</option>
						<option value="banco">Banco</option>
						<option value="cliente">Cliente</option>
					</select>
				</td>
				<td className="sic-border">
					<label className="sic-label">Probabilidad de Éxito</label>
					<select className="sic-select" name="tp_agpr_prob_exito__leasing">
						<option value="_SELE">(selec)</option>
						<option value="alta">Alta</option>
						<option value="media">Media</option>
						<option value="baja">Baja</option>
					</select>
				</td>
			</tr>
			<tr>
				<td className="sic-border">
					<label className="sic-color-blue sic-medium">Monto Legado</label>
					<input className="sic-input" type="text" name="tp_agpr_monto__leasing" id="tp_agpr_monto__leasing"/>
				</td>
				<td className="sic-border">
					<label className="sic-color-blue sic-medium">Derivado</label>
					<input className="sic-check" type="checkbox" name="agpr_derivado__leasing"/>
				</td>
				<td className="sic-border">
				</td>
				<td className="sic-border">
				</td>
			</tr>
			</tbody>
		</table>


        </Col>
    </Row>

    <Row className="Control__contenedor">
        <Col lg={12} md={12} sm={8} xs={4}>

			<div className="sic-container sic-linea-separacion-1"/>

			<div className="sic-margin">
				<a href="/" className="sic-btn sic-border sic-border-color-dark-blue sic-hover-border-color-light-red sic-hover-text-color-dark-red" name="b_update_cambios_cliente" >Grabar Cambios</a>
			</div>
			<br/>
			<div>
				<a href="/" className="sic-btn sic-border sic-border-color-gray-600 sic-hover-border-color-light-red sic-hover-text-color-dark-red" name="b_grab_dclix">Crear nueva acci&oacute;n comercial</a>
			</div>

        </Col>
    </Row>

</Grid>
</>

);

export default FichaClienteAcciones;