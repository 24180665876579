import React from 'react';
import {BrowserRouter as Router, Route} from 'react-router-dom'
import 'canvas-core-react/lib/CanvasStyles.css';
import './Sic.scss';

//import SicBienvenida from './components/SicBienvenida';
//import LoginLocal from './components/SicFormLoginLocal';
import SicHeader from './components/SicHeader';
import SicControlBGPE from './components/SicControlBGPE';
//import SicControlTabs from './components/SicControlTabs';

function App() {
  return (
    <div className="App">
{/*      <SicBienvenida/> */}
      <Router>
        <Route path="/" component={SicHeader} />
        <Route path="/panel/:pers_id?" component={SicControlBGPE} />
      </Router>
    </div>
  );
}
 
export default App;
