import React from 'react';
import Grid from 'canvas-core-react/lib/Grid';
import Row from 'canvas-core-react/lib/Row';
import Col from 'canvas-core-react/lib/Column';
//import { cliente_contactos_x_rut as contactos} from '../data/get_clientes';
import '../Sic.scss';

const FichaClienteContacto = ({clienteContacto}) => (
<>
<Grid  className="sic-color-black sic-xmedium">
    <Row className="Control__contenedor">
        <Col lg={12} md={12} sm={8} xs={4}>

			<table className="sic-table sic-table-all">
				<thead>
					<tr>
						<th className="sic-large sic-color-blue sic-align-center" colSpan="7">Datos de Contactos</th>
					</tr>
					<tr>
						<th></th>
						<th className="sic-color-blue sic-align-center">Cargo</th>
						<th className="sic-color-blue sic-align-center">Nombre</th>
						<th className="sic-color-blue sic-align-center">Tel&eacute;fonos</th>
						<th className="sic-color-blue sic-align-center">Correo</th>
						<th className="sic-color-blue sic-align-center">Observaciones</th>
						<th className="sic-color-blue sic-align-center">Editar</th>
					</tr>
				</thead>
				<tbody>
				{
					clienteContacto.map((contacto, idx) => (
					<tr className="sic-medium" key={"ctto_"+idx}>
						<td className="sic-align-center sic-border">{idx + 1}</td>
						<td className="sic-align-left sic-border">{contacto.ctto_nombre_contacto}</td>
						<td className="sic-align-left sic-border">{contacto.carg_tabp_alias}</td>
						<td className="sic-align-center sic-border">{contacto.ctto_telefono_fijo}</td>
						<td className="sic-align-center sic-border">{contacto.ctto_email}</td>
						<td className="sic-align-left sic-border">{contacto.ctto_observaciones}</td>
						<td className="ic-align-center sic-border"><a href="/" key={"ctto_nkey_"+idx+contacto.ctto_nkey} className="sic-anchor">...</a></td>
					</tr>
					))
				}
				</tbody>
			</table>

        </Col>
    </Row>

    <Row className="Control__contenedor">
        <Col className="sic-container-centered sic-margin-top sic-background-color-white sic-padding" lg={6} md={6} sm={4} xs={2}>
            <input className="sic-btn" type="button" name="b_grab_dclix"  value="Crear Nuevo Contacto" />
        </Col>
    </Row>

</Grid>
</>

);

export default FichaClienteContacto;