import React from 'react';
import Grid from 'canvas-core-react/lib/Grid';
import Row from 'canvas-core-react/lib/Row';
import Col from 'canvas-core-react/lib/Column';

import '../Sic.scss';

const FichaClienteRentabilidad = () => (
<>
<Grid className="sic-color-black sic-xmedium">
    <Row className="Control__contenedor">
        <Col lg={12} md={12} sm={8} xs={4}>
<table className="sic-table sic-striped ">
	<thead>
		<tr>
			<th className="sic-large sic-color-blue sic-align-center" colSpan="4">Rentabilidad del Cliente (M$)</th>
		</tr>
		<tr className="sic-large sic-color-blue">
			<th></th>
			<th className="sic-align-center">Mes</th>
			<th className="sic-align-center">A&ntilde;o</th>
			<th className="sic-align-center">12M</th>
		</tr>
	</thead>
	<tbody>
		<tr className="sic-border-bottom">
			<td>Colocaciones</td>
			<td className="sic-align-center">19</td>
			<td className="sic-align-center">420</td>
			<td className="sic-align-center">420</td>
		</tr>
		<tr className="sic-border-bottom">
			<td>Dep&oacute;sitos Vista</td>
			<td className="sic-align-center">205</td>
			<td className="sic-align-center">12.817</td>
			<td className="sic-align-center">12.817</td>
		</tr>
		<tr className="sic-border-bottom">
			<td>Dep&oacute;sitos Plazo</td>
			<td className="sic-align-center">0</td>
			<td className="sic-align-center">0</td>
			<td className="sic-align-center">0</td>
		</tr>
		<tr className="sic-border-bottom">
			<td className="sic-bold sic-color-blue">Resultados por Spread</td>
			<td className="sic-align-center">224</td>
			<td className="sic-align-center">13.238</td>
			<td className="sic-align-center">13.238</td>
		</tr>
		<tr className="sic-border-bottom">
			<td>Comisiones Ganadas</td>
			<td className="sic-align-center">0</td>
			<td className="sic-align-center">140</td>
			<td className="sic-align-center">140</td>
		</tr>
		<tr className="sic-border-bottom">
			<td>Comisiones Pagadas</td>
			<td className="sic-align-center">-24</td>
			<td className="sic-align-center">-172</td>
			<td className="sic-align-center">-172</td>
		</tr>
		<tr className="sic-border-bottom">
			<td>Tesorer&iacute;a</td>
			<td className="sic-align-center">0</td>
			<td className="sic-align-center">2.688</td>
			<td className="sic-align-center">2.688</td>
		</tr>
		<tr className="sic-border-bottom">
			<td>Filiales</td>
			<td className="sic-align-center">0</td>
			<td className="sic-align-center">0</td>
			<td className="sic-align-center">0</td>
		</tr>
		<tr className="sic-border-bottom">
			<td className="sic-bold sic-color-blue">Total Comisiones</td>
			<td className="sic-align-center">379</td>
			<td className="sic-align-center">5.947</td>
			<td className="sic-align-center">5.947</td>
		</tr>
		<tr>
			<td></td>
		</tr>
		<tr className="sic-border-bottom">
			<td className="sic-bold sic-color-blue">Total Ingresos</td>
			<td className="sic-align-center">603</td>
			<td className="sic-align-center">19.184</td>
			<td className="sic-align-center">19.184</td>
		</tr>
		<tr>
			<td></td>
		</tr>
		<tr className="sic-border-bottom">
			<td className="sic-large sic-bold sic-color-blue">ROA</td>
			<td className="sic-align-center">13,85%</td>
			<td className="sic-align-center">20,77%</td>
			<td className="sic-align-center">20,77%</td>
		</tr>
	</tbody>
</table>


        </Col>
    </Row>

    <Row className="Control__contenedor">
        <Col className="sic-container-centered sic-margin-top sic-background-color-white sic-padding" lg={12} md={12} sm={8} xs={4}>
<span className="sic-large sic-color-blue sic-align-center sic-bold">Gráficos de Tendencia</span>
        </Col>
    </Row>

    <Row className="Control__contenedor">
        <Col className="sic-container-centered sic-margin-top sic-background-color-white sic-padding" lg={6} md={6} sm={4} xs={2}>
Gráficos 1: Evolución Saldos Cuenta Corriente + + Evolución Total Pasivos
        </Col>

        <Col className="sic-container-centered sic-margin-top sic-background-color-white sic-padding" lg={6} md={6} sm={4} xs={2}>
Gráficos 2: Evolución Margen Bruto  + Rentabilidad Neta
        </Col>

    </Row>

    <Row className="Control__contenedor">
        <Col className="sic-container-centered sic-margin-top sic-background-color-white sic-padding" lg={6} md={6} sm={4} xs={2}>
Gráficos 3: Evolución total Deuda BBVA + Deuda SBIF
        </Col>

        <Col className="sic-container-centered sic-margin-top sic-background-color-white sic-padding" lg={6} md={6} sm={4} xs={2}>
Gráficos 4: Evolución Detalle Deuda BBVA por Producto
        </Col>

    </Row>

</Grid>
</>

);

export default FichaClienteRentabilidad;