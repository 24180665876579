import React, { useState, useEffect } from 'react'
import {BrowserRouter as Router, Route, NavLink} from 'react-router-dom'
import { useHttpGet as useHttp, MsgeLoading } from './useHttp';
import PanelControl from './PanelControl';
import PanelAcciones from './PanelAcciones';
import PanelVisitas from './PanelVisitas';
import PanelClientes from './PanelClientes';
import PanelAlertas from './PanelAlertas';
import PanelVencimientos from './PanelVencimientos';
import PanelDiagnostico from './PanelDiagnostico';
//import PanelInformes from './PanelInformes';
import PanelProspectos from './PanelProspectos';
import FichaCliente from './FichaCliente';
import Grid from 'canvas-core-react/lib/Grid';
import Row from 'canvas-core-react/lib/Row';
import Col from 'canvas-core-react/lib/Column';
import {serviciosSIC} from '../data/const_servicios'
import '../Sic.scss';

const SicControlBGPE = () => {

//  const pers_id =  match.params.pers_id
  const f_caso_bcas = "BCAS"
  const f_caso_gcia = "GCIA"
  const f_caso_plat = "PLAT"
  const f_caso_ejec = "EJEC"
  const f_todos = "*"

  let largo = 0

  const params_b = `caso=${serviciosSIC.serv_select_data_ctrl_x_padre}&bgpe_caso=${f_caso_bcas}`
  const [post_b, isLoading_b] = useHttp(params_b)
  const dataBancas = ((isLoading_b) ? [] : post_b)

  const params_g = `caso=${serviciosSIC.serv_select_data_ctrl_x_padre}&bgpe_caso=${f_caso_gcia}&bgpe_id_padre=${f_todos}`
  const [post_g, isLoading_g] = useHttp(params_g)
  const dataGerencias = ((isLoading_g) ? [] : post_g)

  const params_p = `caso=${serviciosSIC.serv_select_data_ctrl_x_padre}&bgpe_caso=${f_caso_plat}&bgpe_id_padre=${f_todos}`
  const [post_p, isLoading_p] = useHttp(params_p)
  const dataPlataformas = ((isLoading_p) ? [] : post_p)

  const params_e = `caso=${serviciosSIC.serv_select_data_ctrl_x_padre}&bgpe_caso=${f_caso_ejec}&bgpe_id_padre=${f_todos}`
  const [post_e, isLoading_e] = useHttp(params_e)
  const dataEjecutivos = ((isLoading_e) ? [] : post_e)

  const [bcas_id, setBcas] = useState('_SELE')
  const [gcia_id, setGcia] = useState('_SELE')
  const [plat_id, setPlat] = useState('_SELE')
  const [gest_id, setEjec] = useState('_SELE')

  const gciaOptionTodos = (ilargo) => {
    if (ilargo >1)
      return(<option id="_gcia_todo" key="_gcia_todo" value="_TODO">(todos)</option>)
  }

  const mensajeCargaB = () => {if (isLoading_b) return(<MsgeLoading/>)}

  const platOptionTodos = (ilargo) => {
    if (ilargo >1)
      return(<option id="_plat_todo" key="_plat_todo" value="_TODO">(todos)</option>)
  }

  const ejecOptionTodos = (ilargo) => {
    if (ilargo >1)
      return(<option id="_ejec_todo" key="_ejec_todo" value="_TODO">(todos)</option>)
  }

  const handleBcas = (e) => {
    setEjec('_SELE')
    setPlat('_SELE')
    setGcia('_SELE')
    setBcas(e.target.value)
  }

  const handleGcia = (e) => {
    setEjec('_SELE')
    setPlat('_SELE')
    setGcia(e.target.value)
  }

  const handlePlat = (e) => {
    setEjec('_SELE')
    setPlat(e.target.value)
  }

  const handleEjec = (e) => {
    setEjec(e.target.value)
  }

    const mensajeCarga = () => {if ((isLoading_b) || (isLoading_g)) return(<MsgeLoading/>)}
  
  useEffect (() => {
//    console.log('CONTROL: ' + bcas_id + ' | ' + gcia_id + ' | ' + plat_id + ' | ' + gest_id)
  } , [bcas_id, gcia_id, plat_id, gest_id] ) 

  return (
  <>
  <div className='Control__ppal '>
  <Grid >

    {mensajeCargaB()} 

    {mensajeCarga()} 

    <Row justifyContent='center' className="Control__contenedor">

      <Col lg={3} md={3} sm={2} xs={1}>
        <div><label className="Control__label">Banca / Vista</label></div>
        <div className="Control__select-parent">
        <select name="n_banca" id="id_banca" className="Control__select" onChange={handleBcas}>
          <option id="_SELE" key="_bcas_sele" value="_SELE" className="Control__select-option-default"></option>
          {
            dataBancas.map((banca, idx) => (
              <option 
                id={banca.bcas_id} 
                key={banca.bcas_id} 
                value={banca.bcas_id} 
                className="Control__select-option"
              >
                {banca.bcas_nombre}
              </option>
              ))
            }  
          </select>
          <div className="Control__select-overlay-arrow">&#9660;</div>
          </div>
      </Col>

      <Col lg={3} md={3} sm={2} xs={1}>
        <div><label className="Control__label">Gerencia</label></div>
        <div className="Control__select-parent">
        <select name="n_gerencias" id="id_gerencias" className="Control__select" onChange={handleGcia}>
          <option id="_SELE" key="_gcia_sele" value="_SELE"></option>
          {
            dataGerencias.map((gerencia, idx) => { 
              largo++;
              if (gerencia.bcas_id === bcas_id)
                return (
                  <option id={gerencia.gcia_id} key={gerencia.gcia_id} value={gerencia.gcia_id} className="Control__select-option">
                    {gerencia.gcia_nombre}
                  </option>
                )
              else
                  return('')
            })
          }  

          { gciaOptionTodos(largo)}

        </select>
        <div className="Control__select-overlay-arrow">&#9660;</div>
        </div>
      </Col>

      <Col lg={3} md={3} sm={2} xs={1}>
        <div><label className="Control__label">Plataforma</label></div>
        <div className="Control__select-parent">
        <select name="n_plataforma" id="id_plataforma" className="Control__select" onChange={handlePlat}>
          <option id="_SELE" key="_plat_sele" value="_SELE"></option>
          {
            dataPlataformas.map(plataforma => {
              largo++;
              if (plataforma.gcia_id === gcia_id)
                return (
                  <option id={plataforma.plat_id} key={plataforma.plat_id} value={plataforma.plat_id} className="Control__select-option">
                    {plataforma.plat_nombre}
                  </option>
                )
              else
                return('')
            })
          }  

          { platOptionTodos(largo)}

        </select>
        <div className="Control__select-overlay-arrow">&#9660;</div>
        </div>
      </Col>

      <Col lg={3} md={3} sm={2} xs={1}>
        <div><label className="Control__label">Ejecutivo</label></div>
        <div className="Control__select-parent">
        <select name="n_ejecutivo" id="id_ejecutivo" className="Control__select" onChange={handleEjec} value={gest_id}>
          <option id="_SELE" key="_ejec_sele" value="_SELE"></option>
          {
              dataEjecutivos.map(ejecutivo => {

                if (ejecutivo.plat_id === plat_id) {
                  largo++;
                  return (
                    <option id={ejecutivo.gest_id} key={ejecutivo.gest_id} value={ejecutivo.gest_id} className="Control__select-option">
                      {ejecutivo.pers_nbre_apat_amat_gest}
                    </option>
                  )
                }
                else
                  return('')
            })
          } 

          { ejecOptionTodos(largo)}

        </select>
        <div className="Control__select-overlay-arrow">&#9660;</div>
        </div>
      </Col>

    </Row>
  </Grid>
</div>

<div className="sic-separador-color-gray-400"></div>

<div>
  <Router>
    <div className="sic-container sic-margin sic-padding sic-align-center">
      <NavLink className="sic-xmedium sic-anchor-tabs sic-padding" activeClassName="sic-border-bottom-tabs" to="/panel">PANEL CONTROL</NavLink>
      <NavLink className="sic-xmedium sic-anchor-tabs sic-padding" activeClassName="sic-border-bottom-tabs" to="/acciones">ACCIONES</NavLink>
      <NavLink className="sic-xmedium sic-anchor-tabs sic-padding" activeClassName="sic-border-bottom-tabs" to="/visitas">VISITAS</NavLink>
      <NavLink className="sic-xmedium sic-anchor-tabs sic-padding" activeClassName="sic-border-bottom-tabs" to="/clientes">CLIENTES</NavLink>
      <NavLink className="sic-xmedium sic-anchor-tabs sic-padding" activeClassName="sic-border-bottom-tabs" to="/alertas">ALERTAS</NavLink>
      <NavLink className="sic-xmedium sic-anchor-tabs sic-padding" activeClassName="sic-border-bottom-tabs" to="/vencimientos">VENCIMIENTOS</NavLink>
      <NavLink className="sic-xmedium sic-anchor-tabs sic-padding" activeClassName="sic-border-bottom-tabs" to="/diagnostico">DIAGNÓSTICO</NavLink>
{/*      <NavLink className="sic-xmedium sic-anchor-tabs sic-padding" activeClassName="sic-border-bottom-tabs" to="/informes">INFORMES</NavLink>*/}
      <NavLink className="sic-xmedium sic-anchor-tabs sic-padding" activeClassName="sic-border-bottom-tabs" to="/prospectos">PROSPECTOS</NavLink>
      <NavLink className="sic-xmedium sic-anchor-tabs sic-padding" activeClassName="sic-border-bottom-tabs" to="/ficha">FICHA</NavLink>
    </div>

    <div className="sic-separador-color-gray-400"></div>

    <div className="sic-container sic-margin sic-padding">
      <Route strict path="/panel" render={() => ( <PanelControl bcas_id={bcas_id} gcia_id={gcia_id} plat_id={plat_id} gest_id={gest_id} /> )} />
      <Route strict path="/acciones" render={() => ( <PanelAcciones bcas_id={bcas_id} gcia_id={gcia_id} plat_id={plat_id} gest_id={gest_id} /> )} />
      <Route strict path="/visitas" render={() => ( <PanelVisitas bcas_id={bcas_id} gcia_id={gcia_id} plat_id={plat_id} gest_id={gest_id} /> )} />
      <Route strict path="/clientes" render={() => ( <PanelClientes bcas_id={bcas_id} gcia_id={gcia_id} plat_id={plat_id} gest_id={gest_id} /> )} />
      <Route strict path="/alertas" render={() => ( <PanelAlertas  bcas_id={bcas_id} gcia_id={gcia_id} plat_id={plat_id} gest_id={gest_id} /> )} />
      <Route strict path="/vencimientos" render={() => ( <PanelVencimientos bcas_id={bcas_id} gcia_id={gcia_id} plat_id={plat_id} gest_id={gest_id} /> )} />
      <Route strict path="/diagnostico" render={() => ( <PanelDiagnostico bcas_id={bcas_id} gcia_id={gcia_id} plat_id={plat_id} gest_id={gest_id} /> )} />
      {/*<Route strict path="/informes" render={() => ( <PanelInformes bcas_id={bcas_id} gcia_id={gcia_id} plat_id={plat_id} gest_id={gest_id} /> )} />*/}
      <Route strict path="/prospectos" render={() => ( <PanelProspectos bcas_id={bcas_id} gcia_id={gcia_id} plat_id={plat_id} gest_id={gest_id} /> )} />
      <Route path="/ficha/:rut?" component={FichaCliente} />
    </div>
  </Router>
</div>
</>

)}

export default SicControlBGPE;