import React from 'react';
import Grid from 'canvas-core-react/lib/Grid';
import Row from 'canvas-core-react/lib/Row';
import Col from 'canvas-core-react/lib/Column';
import { useHttpGet as useHttp, MsgeLoading } from './useHttp';
import {serviciosSIC} from '../data/const_servicios'
import SicGraficoBarraH4 from "./SicGraficoBarraH4"
import '../Sic.scss';

const PanelControlAlertas = ({bcas_id, gcia_id, plat_id, gest_id}) => {

  const params = `caso=${serviciosSIC.serv_panel_control_alertas_data}&bcas_id=${bcas_id}&gcia_id=${gcia_id}&plat_id=${plat_id}&gest_id=${gest_id}`
  const [post, isLoading] = useHttp(params)
  const panel_alertas = ((isLoading) ? [] : post)
  const mensajeCarga = () => {if (isLoading) return(<MsgeLoading/>)}

  const paramsAlertas = {
    title : "DISTRIBUCIÓN POR ALERTA",
    subtitle : ""
  }

  return(
    <>
    {mensajeCarga()} 
    <div className='sic-container'>
    <Grid >
        <Row className="Control__contenedor">
            <Col lg={6} md={6} sm={4} xs={2}>

              <div className="sic-container sic-margin-top">

                <table className="sic-table sic-hoverable sic-striped">

                  <colgroup></colgroup>
                  <colgroup></colgroup>
                  <colgroup></colgroup>
                  <thead>
                    <tr className="sic-xmedium sic-color-dark-blue sic-background-color-thead">
                    <th className="sic-border sic-align-center" rowSpan="2">Alerta<br/>Tipo</th>
                    <th className="sic-border sic-align-center" rowSpan="2">Alerta<br/>SubTipo</th>
                      <th className="sic-border sic-align-center" colSpan="3">Atrasadas</th>
                      <th className="sic-border sic-align-center" colSpan="3">Al día</th>
                      <th className="sic-border sic-align-center" rowSpan="2">Total</th>
                    </tr>
                    <tr className="sic-medium sic-color-dark-blue sic-background-color-thead">
                      <th className="sic-border" >Ejec.<br/>Com.</th>
                      <th className="sic-border" >Ejec.<br/>Prod.</th>
                      <th className="sic-border" >Total</th>
                      <th className="sic-border" >Ejec.<br/>Com.</th>
                      <th className="sic-border" >Ejec.<br/>Prod.</th>
                      <th className="sic-border" >Total</th>
                    </tr>
                  </thead>

                  <tbody>
                  {
                    panel_alertas.map((data, idx) => (
                      <tr className="sic-medium" key={"aler_"+idx}>
                        <td className="sic-border">{data.aler_niv1_nombre}</td>
                        <td className="sic-border">{data.aler_niv2_nombre}</td>

                        <td className="sic-border sic-align-center">{data.aler_ec_atras}</td>
                        <td className="sic-border sic-align-center">{data.aler_ep_atras}</td>
                        <td className="sic-border sic-align-center">{data.aler_tt_atras}</td>

                        <td className="sic-border sic-align-center">{data.aler_ec_aldia}</td>
                        <td className="sic-border sic-align-center">{data.aler_ep_aldia}</td>
                        <td className="sic-border sic-align-center">{data.aler_tt_aldia}</td>

                        <td className="sic-border sic-align-center">{data.aler_tt_gral}</td>
                      </tr>
                    ))
                  }
                    </tbody>
                </table>
              </div>

            </Col>
            <Col lg={6} md={6} sm={2} xs={2}>
              <SicGraficoBarraH4 
                params={paramsAlertas} 
                categorias={panel_alertas.map (cont => cont.aler_niv2_nombre)}
                serie1={panel_alertas.map (cont => parseInt(cont.aler_ec_aldia))} 
                serie2={panel_alertas.map (cont => parseInt(cont.aler_ep_aldia))} 
                serie3={panel_alertas.map (cont => parseInt(cont.aler_ec_atras))} 
                serie4={panel_alertas.map (cont => parseInt(cont.aler_ep_atras))} 
              />
            </Col>
        </Row>
    </Grid>
    </div>
    </>
)
};

export default PanelControlAlertas;
