import React from 'react';
import Grid from 'canvas-core-react/lib/Grid';
import Row from 'canvas-core-react/lib/Row';
import Col from 'canvas-core-react/lib/Column';
import { useHttpGet as useHttp, MsgeLoading } from './useHttp';
import {serviciosSIC} from '../data/const_servicios'
import {formatCantidad} from './SicUtilidades';
import '../Sic.scss';

const posDominioEtapa = (dominio, vEtapa, vSubEtapa) => {
    for(var i=0; i<dominio.length; i++) {
      if (vEtapa !== "cierre") {
        if (dominio[i].etap_tpk2_id === vEtapa) {
          return(i)
        }
      }
      else if (dominio[i].sube_tpk2_id === vSubEtapa) {
          return(i)
      }
    }
    return(0)
  }
  
  const nombreEtapa = (dominio) => {
      if (dominio.etap_tpk2_id !== "cierre") {
          return(dominio.etap_tabp_alias)
      }
      else {
        return(dominio.sube_tabp_alias)
      }
  }
  
  const tuboMatriz = (dominio, tubo) => {
  
    var ant = '';
    var p = 0;
    var matriz = [];
    var k = -1;
    matriz[0] = []
    var tlenght = tubo.length
  
    for(var i=0; i < tlenght; i++) {
      p = posDominioEtapa(dominio, tubo[i].tubo_etapa, tubo[i].tubo_subetapa);
  
      if (ant === tubo[i].pers_id_ejpr) {
        matriz[k][p+10] = tubo[i].tubo_cantidad
        matriz[k][tlenght+10-1] += tubo[i].tubo_cantidad
      }
      else {
        ant = tubo[i].pers_id_ejpr;
        k = k+1;
        matriz[k] = []
        matriz[k][0] = tubo[i].pers_nbre_gepr
        matriz[k][1] = tubo[i].pers_nbre_jppr
        matriz[k][2] = tubo[i].pers_nbre_ejpr
  
        matriz[k][4] = tubo[i].pers_id_gepr
        matriz[k][5] = tubo[i].pers_id_jppr
        matriz[k][6] = tubo[i].pers_id_ejpr
  
        for(var j=0; j<20; j++) {
          matriz[k][j+10] = 0
        }
  
        matriz[k][p+10] = tubo[i].tubo_cantidad
//        matriz[k][tlenght+10-1] += tubo[i].tubo_cantidad
      }
    }

    var mFilas = matriz.length
    k = k+1;
    matriz[k] = []
    matriz[k][0] = ''
    matriz[k][1] = ''
    matriz[k][2] = 'TOTAL'
  
    matriz[k][4] = ''
    matriz[k][5] = ''
    matriz[k][6] = ''
  
    for(var jj=0; jj<20; jj++) {
      matriz[k][jj+10] = 0
    }
  
    for(var ii=0; ii < mFilas; ii++) {
      for(var ij=0; ij<20; ij++) {
        matriz[k][ij+10] += matriz[ii][ij+10]
      }
    }

    return(matriz)
  }
  
const PanelControlTuboProducto = ({bcas_id, gcia_id, plat_id, gest_id}) => {

    const params_etapa = `caso=${serviciosSIC.serv_select_etapas_x_params}&bcas_id=${bcas_id}&gcia_id=${gcia_id}`
    const [postEtapas, isLoading_etapa] = useHttp(params_etapa)
    const domEtapas = ((isLoading_etapa) ? [] : postEtapas)
  
    const params = `caso=${serviciosSIC.serv_select_pcontrol_x_tubo_producto}&bcas_id=${bcas_id}&gcia_id=${gcia_id}&plat_id=${plat_id}&gest_id=${gest_id}`
    const [post, isLoading] = useHttp(params)
    const tuboProducto = ((isLoading) ? [] : post)
    const mensajeCarga = () => {if (isLoading) return(<MsgeLoading/>)}

    const tuboM = tuboMatriz(domEtapas, tuboProducto);

    if ((bcas_id !== 'producto') || (bcas_id === '_SELE') || (bcas_id === '') ) return('')

    return(
    <>
    {mensajeCarga()} 

    <div className='sic-container'>
    <Grid >
        <Row className="Control__contenedor">
            <Col lg={12} md={12} sm={8} xs={4}>


            <div className="sic-container sic-margin-top">
<table className="sic-table sic-hoverable sic-striped">
<thead>
<tr className="sic-xmedium sic-color-dark-blue sic-background-color-thead">
<th className="sic-border sic-align-center"></th>
<th className="sic-border sic-align-center">Gerente Producto</th>
<th className="sic-border sic-align-center">JJPP Producto</th>
<th className="sic-border sic-align-center">Ejecutivo Producto</th>
{
domEtapas.map((dataE, idx) => (
  <th className="sic-border sic-align-center">{nombreEtapa(dataE)}</th>
))
}
</tr>
</thead>
<tbody>
{
  tuboM.map((tuboE, idxt) => (
    <tr>
      <td className="sic-border">{idxt+1}</td>
      {
      tuboE.map((tuboF, idxf) => {
        if (idxf < 3) {
          return(<td className="sic-border">{tuboF}</td>)
        }
        else if ( (idxf >= 10) && (idxf < domEtapas.length+10)) {
          return(<td className="sic-border sic-align-center">{formatCantidad(tuboF)}</td>)
        }
        else {
          return('')
        }
      }
      )
      }
    </tr>
  ))
}

</tbody>
</table>
</div>










              <div className="sic-container sic-margin-top">

                <table className="sic-table sic-hoverable sic-striped">

                    <thead>
                        <tr className="sic-xmedium sic-color-dark-blue sic-background-color-thead">
                            <th className="sic-border sic-align-center">`{bcas_id}<br/>{gcia_id}<br/>{plat_id}<br/>{gest_id}`</th>
                            <th className="sic-border sic-align-center">pers_id_gepr</th>
                            <th className="sic-border sic-align-center">pers_nbre_gepr</th>
                            <th className="sic-border sic-align-center">pers_id_jppr</th>
                            <th className="sic-border sic-align-center">pers_nbre_jppr</th>
                            <th className="sic-border sic-align-center">pers_id_ejpr</th>
                            <th className="sic-border sic-align-center">pers_nbre_ejpr</th>
                            <th className="sic-border sic-align-center">tubo_ejecprod</th>
                            <th className="sic-border sic-align-center">tubo_etapa</th>
                            <th className="sic-border sic-align-center">tubo_cantidad</th>
                            <th className="sic-border sic-align-center">GJE</th>
                            <th className="sic-border sic-align-center">etap_tabp_alias</th>
                            <th className="sic-border sic-align-center">etap_tabp_orden</th>
                            <th className="sic-border sic-align-center">sube_tabp_alias</th>
                            <th className="sic-border sic-align-center">sube_tabp_orden</th>
                        </tr>
                    </thead>

                    <tbody>
                    {
                        tuboProducto.map((data, idx) => (
                            <tr className="sic-medium" key={"accs_"+idx}>
                                <td className="sic-border sic-align-center">{idx}</td>
                                <td className="sic-border sic-align-center">{data.pers_id_gepr}</td>
                                <td className="sic-border sic-align-center">{data.pers_nbre_gepr}</td>
                                <td className="sic-border sic-align-center">{data.pers_id_jppr}</td>
                                <td className="sic-border sic-align-center">{data.pers_nbre_jppr}</td>
                                <td className="sic-border sic-align-center">{data.pers_id_ejpr}</td>
                                <td className="sic-border sic-align-center">{data.pers_nbre_ejpr}</td>
                                <td className="sic-border sic-align-center">{data.tubo_ejecprod}</td>
                                <td className="sic-border sic-align-center">{data.tubo_etapa}</td>
                                <td className="sic-border sic-align-center">{data.tubo_cantidad}</td>
                                <td className="sic-border sic-align-center">{data.GJE}</td>
                                <td className="sic-border sic-align-center">{data.etap_tabp_alias}</td>
                                <td className="sic-border sic-align-center">{data.etap_tabp_orden}</td>
                                <td className="sic-border sic-align-center">{data.sube_tabp_alias}</td>
                                <td className="sic-border sic-align-center">{data.sube_tabp_orden}</td>
                            </tr>
                        ))
                    }
                  </tbody>
                </table>
              </div>

            </Col>

        </Row>
    </Grid>
    </div>
    </>
    )
};

export default PanelControlTuboProducto;
