import React from "react";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
//require('highcharts/modules/exporting')(Highcharts)

const GraficoBarraH4 = ({params, categorias, serie1, serie2, serie3, serie4}) => {

//  console.log(datap.map (cont => cont.valor))
    const  opciones = {
        colors: [ '#0056D0', '#00217A', '#E32400', '#B51A00'],
	    chart: {
            type: 'bar',
            },
        title: {
            text: params.title,
            style: {
                    fontSize: '15px',
                    fontFamily: 'Arial, Verdana, sans-serif'
            }
        },
        subtitle: {
            text: params.subtitle
        },
        credits: { 
            enabled: false 
        },
        exporting: {
            enabled: false
        },        
        xAxis: {
            title: {
                text: params.xaxis_title,
                style: {
                    fontSize: '15px',
                    fontFamily: 'Arial, Verdana, sans-serif'
                }
            },
            categories: categorias,
            labels: {
				enabled: true,
				style: {
					fontSize: '14px',
					fontFamily: 'Arial, Verdana, sans-serif'
				}
            },
        },
        yAxis: {
		  min: 0,
          title: {
                text: params.yaxis_title,
                align: 'high'
            },
        },
        legend: {
            reversed: true
        },
        plotOptions: {
            series: {
                stacking: 'normal',
                dataLabels: {
                    enabled: false,
                    style: {
                        fontSize: '12px',
                        fontFamily: 'Arial, Verdana, sans-serif'
                    }
                }
            }
    },
    series: [{
        name: 'Al día EC',
        data: serie1
    }, {
        name: 'Al día EP',
        data: serie2
    }, {
        name: 'Atrasadas EC',
        data: serie3
    }, {
        name: 'Atrasadas EP',
        data: serie4
    }]
}

return (
    <>
    <HighchartsReact
        highcharts={Highcharts}
        options={opciones}
    />
    </>
);
}

export default GraficoBarraH4;