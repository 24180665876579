export const informes = [
    {
      "info_id": "tubo_extendido",
      "info_nombre": "Tubo de Negocio"
    },
    {
      "info_id": "visi_seg_mensual",
      "info_nombre": "Visitas - Seguimiento Mensual"
    },
    {
      "info_id": "pros_extendido",
      "info_nombre": "Prospección (días en tubo)"
    }
    ]
  
    
    export const info_productos = [
      {
        "prod_id": "_TODO",
        "prod_nombre": "(todos)"
      },
      {
        "prod_id": "cuenta_corriente_grupo|*",
        "prod_nombre": "Cuenta Corriente"
      },
      {
        "prod_id": "cuenta_corriente_grupo|cuenta_corriente",
        "prod_nombre": "--->  Cuenta Corriente"
      },
      {
        "prod_id": "cuenta_corriente_grupo|linea_credito",
        "prod_nombre": "--->  Línea de Crédito"
      },
      {
        "prod_id": "cuenta_corriente_grupo|seguros",
        "prod_nombre": "--->  Seguros"
      },
      {
        "prod_id": "cred_comercial|*",
        "prod_nombre": "Crédito Comercial"
      },
      {
        "prod_id": "cred_comercial|cred_comercial_cp",
        "prod_nombre": "--->  Crédito Comercial CP"
      },
      {
        "prod_id": "cred_comercial|cred_comercial_lp",
        "prod_nombre": "--->  Crédito Comercial LP"
      },
      {
        "prod_id": "cash|*",
        "prod_nombre": "CASH"
      },
      {
        "prod_id": "cash|pago_impuesto",
        "prod_nombre": "--->  Impuestos"
      },
      {
        "prod_id": "cash|institucional",
        "prod_nombre": "--->  Institucional"
      },
      {
        "prod_id": "cash|otros_prod_cta_cte",
        "prod_nombre": "--->  Otros Productos Cta. Cte."
      },
      {
        "prod_id": "cash|pago_proveedores",
        "prod_nombre": "--->  Proveedores / Remuneraciones"
      },
      {
        "prod_id": "cash|recaudacion_cajas",
        "prod_nombre": "--->  Recaudación"
      },
      {
        "prod_id": "comex|*",
        "prod_nombre": "COMEX"
      },
      {
        "prod_id": "comex|carta_cred_stand_by",
        "prod_nombre": "--->  Carta Crédito"
      },
      {
        "prod_id": "comex|cobranza",
        "prod_nombre": "--->  Cobranza Documentaria"
      },
      {
        "prod_id": "comex|gerantias",
        "prod_nombre": "--->  Garantías"
      },
      {
        "prod_id": "comex|pagos_comex",
        "prod_nombre": "--->  Ordenes de Pagos"
      },
      {
        "prod_id": "comex|alerta_comex",
        "prod_nombre": "--->  Alerta COMEX"
      },
      {
        "prod_id": "comex|financiamiento",
        "prod_nombre": "--->  Financiamiento"
      },
      {
        "prod_id": "comex|Web_Comex",
        "prod_nombre": "--->  Web Comex"
      },
      {
        "prod_id": "factoring|*",
        "prod_nombre": "Factoring"
      },
      {
        "prod_id": "factoring|confirming",
        "prod_nombre": "--->  Confirming Electrónico"
      },
      {
        "prod_id": "factoring|factoring",
        "prod_nombre": "--->  Factoring Financiero"
      },
      {
        "prod_id": "factoring|factoring_tradicional",
        "prod_nombre": "--->  Factoring Tradicional"
      },
      {
        "prod_id": "factoring|grandes_pagadores",
        "prod_nombre": "--->  Grandes Pagadores"
      },
      {
        "prod_id": "factoring|confirming_otros",
        "prod_nombre": "--->  Otros Confirming"
      },
      {
        "prod_id": "leasing|*",
        "prod_nombre": "Leasing"
      },
      {
        "prod_id": "leasing|leasing",
        "prod_nombre": "--->  Leasing / Leaseback"
      },
      {
        "prod_id": "leasing|leasing_leaseback_inmob",
        "prod_nombre": "--->  Leasing / Leaseback Inmobiliario"
      },
      {
        "prod_id": "leasing|leaseback",
        "prod_nombre": "--->  Web Leasing"
      },
      {
        "prod_id": "inversiones|*",
        "prod_nombre": "Inversiones"
      },
      {
        "prod_id": "inversiones|dap",
        "prod_nombre": "--->  DAP / FFMM"
      },
      {
        "prod_id": "inmobiliaria|*",
        "prod_nombre": "Inmobiliaria"
      },
      {
        "prod_id": "inmobiliaria|inmobiliaria",
        "prod_nombre": "--->  Inmobiliaria"
      },
      {
        "prod_id": "margenes|*",
        "prod_nombre": "Márgenes"
      },
      {
        "prod_id": "margenes|margenes",
        "prod_nombre": "--->  Márgenes"
      },
      {
        "prod_id": "mesa_dinero|*",
        "prod_nombre": "Mesa Dinero"
      },
      {
        "prod_id": "mesa_dinero|cobertura_moneda",
        "prod_nombre": "--->  Cobertura de Moneda / Tasa"
      },
      {
        "prod_id": "mesa_dinero|fx",
        "prod_nombre": "--->  FX"
      },
      {
        "prod_id": "bibec_grupo|*",
        "prod_nombre": "BIBEC"
      },
      {
        "prod_id": "bibec_grupo|bonos",
        "prod_nombre": "--->  Bonos"
      },
      {
        "prod_id": "bibec_grupo|corporate_finance",
        "prod_nombre": "--->  Corporate Finance"
      },
      {
        "prod_id": "bibec_grupo|project_financce",
        "prod_nombre": "--->  Project Finance"
      },
      {
        "prod_id": "bibec_grupo|ptmos_corporativos_lp",
        "prod_nombre": "--->  Ptmos. Corporativos LP"
      },
      {
        "prod_id": "boleta_garantia|*",
        "prod_nombre": "Boletas Garantía"
      },
      {
        "prod_id": "boleta_garantia|boleta_garantia",
        "prod_nombre": "--->  Boleta de Garantía"
      },
      {
        "prod_id": "z|*",
        "prod_nombre": "(no aplica)"
      },
      {
        "prod_id": "z|_na",
        "prod_nombre": "--->  (no aplica)"
      },
      {
        "prod_id": "z|z",
        "prod_nombre": "--->  (sin asignar)"
      }
    ]
  
    export const noc_productos_agenda = [
      {
        "prod_id": "_NA",
        "prod_nombre": "(no aplica)"
      },
      {
        "prod_id": "cuenta_corriente_grupo|*",
        "prod_nombre": "Cuenta Corriente"
      },
      {
        "prod_id": "cuenta_corriente_grupo|cuenta_corriente",
        "prod_nombre": "--->  Cuenta Corriente"
      },
      {
        "prod_id": "cuenta_corriente_grupo|linea_credito",
        "prod_nombre": "--->  Línea de Crédito"
      },
      {
        "prod_id": "cuenta_corriente_grupo|seguros",
        "prod_nombre": "--->  Seguros"
      },
      {
        "prod_id": "cred_comercial|*",
        "prod_nombre": "Crédito Comercial"
      },
      {
        "prod_id": "cred_comercial|cred_comercial_cp",
        "prod_nombre": "--->  Crédito Comercial CP"
      },
      {
        "prod_id": "cred_comercial|cred_comercial_lp",
        "prod_nombre": "--->  Crédito Comercial LP"
      },
      {
        "prod_id": "cash|*",
        "prod_nombre": "CASH"
      },
      {
        "prod_id": "cash|pago_impuesto",
        "prod_nombre": "--->  Impuestos"
      },
      {
        "prod_id": "cash|institucional",
        "prod_nombre": "--->  Institucional"
      },
      {
        "prod_id": "cash|otros_prod_cta_cte",
        "prod_nombre": "--->  Otros Productos Cta. Cte."
      },
      {
        "prod_id": "cash|pago_proveedores",
        "prod_nombre": "--->  Proveedores / Remuneraciones"
      },
      {
        "prod_id": "cash|recaudacion_cajas",
        "prod_nombre": "--->  Recaudación"
      },
      {
        "prod_id": "comex|*",
        "prod_nombre": "COMEX"
      },
      {
        "prod_id": "comex|carta_cred_stand_by",
        "prod_nombre": "--->  Carta Crédito"
      },
      {
        "prod_id": "comex|cobranza",
        "prod_nombre": "--->  Cobranza Documentaria"
      },
      {
        "prod_id": "comex|gerantias",
        "prod_nombre": "--->  Garantías"
      },
      {
        "prod_id": "comex|pagos_comex",
        "prod_nombre": "--->  Ordenes de Pagos"
      },
      {
        "prod_id": "comex|alerta_comex",
        "prod_nombre": "--->  Alerta COMEX"
      },
      {
        "prod_id": "comex|financiamiento",
        "prod_nombre": "--->  Financiamiento"
      },
      {
        "prod_id": "comex|Web_Comex",
        "prod_nombre": "--->  Web Comex"
      },
      {
        "prod_id": "factoring|*",
        "prod_nombre": "Factoring"
      },
      {
        "prod_id": "factoring|confirming",
        "prod_nombre": "--->  Confirming Electrónico"
      },
      {
        "prod_id": "factoring|factoring",
        "prod_nombre": "--->  Factoring Financiero"
      },
      {
        "prod_id": "factoring|factoring_tradicional",
        "prod_nombre": "--->  Factoring Tradicional"
      },
      {
        "prod_id": "factoring|grandes_pagadores",
        "prod_nombre": "--->  Grandes Pagadores"
      },
      {
        "prod_id": "factoring|confirming_otros",
        "prod_nombre": "--->  Otros Confirming"
      },
      {
        "prod_id": "leasing|*",
        "prod_nombre": "Leasing"
      },
      {
        "prod_id": "leasing|leasing",
        "prod_nombre": "--->  Leasing / Leaseback"
      },
      {
        "prod_id": "leasing|leasing_leaseback_inmob",
        "prod_nombre": "--->  Leasing / Leaseback Inmobiliario"
      },
      {
        "prod_id": "leasing|leaseback",
        "prod_nombre": "--->  Web Leasing"
      },
      {
        "prod_id": "inversiones|*",
        "prod_nombre": "Inversiones"
      },
      {
        "prod_id": "inversiones|dap",
        "prod_nombre": "--->  DAP / FFMM"
      },
      {
        "prod_id": "inmobiliaria|*",
        "prod_nombre": "Inmobiliaria"
      },
      {
        "prod_id": "inmobiliaria|inmobiliaria",
        "prod_nombre": "--->  Inmobiliaria"
      },
      {
        "prod_id": "margenes|*",
        "prod_nombre": "Márgenes"
      },
      {
        "prod_id": "margenes|margenes",
        "prod_nombre": "--->  Márgenes"
      },
      {
        "prod_id": "mesa_dinero|*",
        "prod_nombre": "Mesa Dinero"
      },
      {
        "prod_id": "mesa_dinero|cobertura_moneda",
        "prod_nombre": "--->  Cobertura de Moneda / Tasa"
      },
      {
        "prod_id": "mesa_dinero|fx",
        "prod_nombre": "--->  FX"
      },
      {
        "prod_id": "bibec_grupo|*",
        "prod_nombre": "BIBEC"
      },
      {
        "prod_id": "bibec_grupo|bonos",
        "prod_nombre": "--->  Bonos"
      },
      {
        "prod_id": "bibec_grupo|corporate_finance",
        "prod_nombre": "--->  Corporate Finance"
      },
      {
        "prod_id": "bibec_grupo|project_financce",
        "prod_nombre": "--->  Project Finance"
      },
      {
        "prod_id": "bibec_grupo|ptmos_corporativos_lp",
        "prod_nombre": "--->  Ptmos. Corporativos LP"
      },
      {
        "prod_id": "boleta_garantia|*",
        "prod_nombre": "Boletas Garantía"
      },
      {
        "prod_id": "boleta_garantia|boleta_garantia",
        "prod_nombre": "--->  Boleta de Garantía"
      },
      {
        "prod_id": "z|*",
        "prod_nombre": "(no aplica)"
      } 
    ]
  
    export const panel_productos = [
      {
        "prod_id": "_TODO",
        "prod_nombre": "(todos)"
      },
      {
        "prod_id": "cuenta_corriente_grupo|*",
        "prod_nombre": "Cuenta Corriente"
      },
      {
        "prod_id": "cuenta_corriente_grupo|cuenta_corriente",
        "prod_nombre": "--->  Cuenta Corriente"
      },
      {
        "prod_id": "cuenta_corriente_grupo|linea_credito",
        "prod_nombre": "--->  Línea de Crédito"
      },
      {
        "prod_id": "cuenta_corriente_grupo|seguros",
        "prod_nombre": "--->  Seguros"
      },
      {
        "prod_id": "cred_comercial|*",
        "prod_nombre": "Crédito Comercial"
      },
      {
        "prod_id": "cred_comercial|cred_comercial_cp",
        "prod_nombre": "--->  Crédito Comercial CP"
      },
      {
        "prod_id": "cred_comercial|cred_comercial_lp",
        "prod_nombre": "--->  Crédito Comercial LP"
      },
      {
        "prod_id": "cash|*",
        "prod_nombre": "CASH"
      },
      {
        "prod_id": "cash|pago_impuesto",
        "prod_nombre": "--->  Impuestos"
      },
      {
        "prod_id": "cash|institucional",
        "prod_nombre": "--->  Institucional"
      },
      {
        "prod_id": "cash|otros_prod_cta_cte",
        "prod_nombre": "--->  Otros Productos Cta. Cte."
      },
      {
        "prod_id": "cash|pago_proveedores",
        "prod_nombre": "--->  Proveedores / Remuneraciones"
      },
      {
        "prod_id": "cash|recaudacion_cajas",
        "prod_nombre": "--->  Recaudación"
      },
      {
        "prod_id": "comex|*",
        "prod_nombre": "COMEX"
      },
      {
        "prod_id": "comex|carta_cred_stand_by",
        "prod_nombre": "--->  Carta Crédito"
      },
      {
        "prod_id": "comex|cobranza",
        "prod_nombre": "--->  Cobranza Documentaria"
      },
      {
        "prod_id": "comex|gerantias",
        "prod_nombre": "--->  Garantías"
      },
      {
        "prod_id": "comex|pagos_comex",
        "prod_nombre": "--->  Ordenes de Pagos"
      },
      {
        "prod_id": "comex|alerta_comex",
        "prod_nombre": "--->  Alerta COMEX"
      },
      {
        "prod_id": "comex|financiamiento",
        "prod_nombre": "--->  Financiamiento"
      },
      {
        "prod_id": "comex|Web_Comex",
        "prod_nombre": "--->  Web Comex"
      },
      {
        "prod_id": "factoring|*",
        "prod_nombre": "Factoring"
      },
      {
        "prod_id": "factoring|confirming",
        "prod_nombre": "--->  Confirming Electrónico"
      },
      {
        "prod_id": "factoring|factoring",
        "prod_nombre": "--->  Factoring Financiero"
      },
      {
        "prod_id": "factoring|factoring_tradicional",
        "prod_nombre": "--->  Factoring Tradicional"
      },
      {
        "prod_id": "factoring|grandes_pagadores",
        "prod_nombre": "--->  Grandes Pagadores"
      },
      {
        "prod_id": "factoring|confirming_otros",
        "prod_nombre": "--->  Otros Confirming"
      },
      {
        "prod_id": "leasing|*",
        "prod_nombre": "Leasing"
      },
      {
        "prod_id": "leasing|leasing",
        "prod_nombre": "--->  Leasing / Leaseback"
      },
      {
        "prod_id": "leasing|leasing_leaseback_inmob",
        "prod_nombre": "--->  Leasing / Leaseback Inmobiliario"
      },
      {
        "prod_id": "leasing|leaseback",
        "prod_nombre": "--->  Web Leasing"
      },
      {
        "prod_id": "inversiones|*",
        "prod_nombre": "Inversiones"
      },
      {
        "prod_id": "inversiones|dap",
        "prod_nombre": "--->  DAP / FFMM"
      },
      {
        "prod_id": "inmobiliaria|*",
        "prod_nombre": "Inmobiliaria"
      },
      {
        "prod_id": "inmobiliaria|inmobiliaria",
        "prod_nombre": "--->  Inmobiliaria"
      },
      {
        "prod_id": "margenes|*",
        "prod_nombre": "Márgenes"
      },
      {
        "prod_id": "margenes|margenes",
        "prod_nombre": "--->  Márgenes"
      },
      {
        "prod_id": "mesa_dinero|*",
        "prod_nombre": "Mesa Dinero"
      },
      {
        "prod_id": "mesa_dinero|cobertura_moneda",
        "prod_nombre": "--->  Cobertura de Moneda / Tasa"
      },
      {
        "prod_id": "mesa_dinero|fx",
        "prod_nombre": "--->  FX"
      },
      {
        "prod_id": "bibec_grupo|*",
        "prod_nombre": "BIBEC"
      },
      {
        "prod_id": "bibec_grupo|bonos",
        "prod_nombre": "--->  Bonos"
      },
      {
        "prod_id": "bibec_grupo|corporate_finance",
        "prod_nombre": "--->  Corporate Finance"
      },
      {
        "prod_id": "bibec_grupo|project_financce",
        "prod_nombre": "--->  Project Finance"
      },
      {
        "prod_id": "bibec_grupo|ptmos_corporativos_lp",
        "prod_nombre": "--->  Ptmos. Corporativos LP"
      },
      {
        "prod_id": "boleta_garantia|*",
        "prod_nombre": "Boletas Garantía"
      },
      {
        "prod_id": "boleta_garantia|boleta_garantia",
        "prod_nombre": "--->  Boleta de Garantía"
      },
      {
        "prod_id": "z|*",
        "prod_nombre": "(no aplica)"
      } 
    ]
    
    export const accs_productos = [
        {
          "prod_id": "_TODO",
          "prod_nombre": "(todos)"
        },
        {
          "prod_id": "comerciales",
          "prod_nombre": "Comerciales"
        },
        {
          "prod_id": "comex",
          "prod_nombre": "Comex"
        },
        {
          "prod_id": "leasing",
          "prod_nombre": "Leasing"
        },
        {
          "prod_id": "factoring",
          "prod_nombre": "Factoring"
        },
        {
          "prod_id": "cash",
          "prod_nombre": "Cash"
        }
        ]

        export const info_etapas = [
      {
        "etap_id": "_ABIE",
        "etap_nombre": "(agendas abiertas)"
      },
      {
        "etap_id": "agendamiento",
        "etap_nombre": "1. Contacto"
      },
      {
        "etap_id": "visita",
        "etap_nombre": "2. Visita"
      },
      {
        "etap_id": "documentacion",
        "etap_nombre": "3. Elaboración Propuesta"
      },
      {
        "etap_id": "resolucion",
        "etap_nombre": "4. Resolución Crediticia"
      },
      {
        "etap_id": "curse",
        "etap_nombre": "5. Implementación – Curse"
      },
      {
        "etap_id": "cierre",
        "etap_nombre": "Cerrar el Tubo de Negocios"
      },
      {
        "etap_id": "_TODO",
        "etap_nombre": "(todos)"
        }
      ]
        
    export const info_motivos = [
      {
        "moti_id": "_TODO",
        "moti_nombre": "(todos)"
      },
      {
        "moti_id": "plan_comercial",
        "moti_nombre": "Provenientes de Planificación Comercial"
      },
      {
        "moti_id": "carga_masiva",
        "moti_nombre": "Oportunidades Dirigidas"
      },
      {
        "moti_id": "peticion_cliente",
        "moti_nombre": "Petición Cliente"
      },
      {
        "moti_id": "alerta_deterioro",
        "moti_nombre": "Gestión Deterioro Crediticio"
      },
      {
        "moti_id": "ofrecer_productos",
        "moti_nombre": "Captación No Cliente Ejecutivo"
      },
      {
        "moti_id": "carga_masiva_noc",
        "moti_nombre": "Captación No Cliente Dirigidas"
      }
  ]
            
  export const noc_motivos_agenda = [
    {
      "moti_id": "_SELE",
      "moti_nombre": "(seleccionar)"
    },
    {
      "moti_id": "ofrecer_productos",
      "moti_nombre": "Captación No Cliente Ejecutivo"
    }
]

export const panel_motivos = [
  {
    "moti_id": "_TODO",
    "moti_nombre": "(todos)"
  },
  {
    "moti_id": "plan_comercial",
    "moti_nombre": "Provenientes de Planificación Comercial"
  },
  {
    "moti_id": "carga_masiva",
    "moti_nombre": "Oportunidades Dirigidas"
  },
  {
    "moti_id": "peticion_cliente",
    "moti_nombre": "Petición Cliente"
  },
  {
    "moti_id": "alerta_deterioro",
    "moti_nombre": "Gestión Deterioro Crediticio"
  },
  {
    "moti_id": "ofrecer_productos",
    "moti_nombre": "Captación No Cliente Ejecutivo"
  },
  {
    "moti_id": "carga_masiva_noc",
    "moti_nombre": "Captación No Cliente Dirigidas"
  }
]

export const panel_situacion = [
  {
    "situ_id": "_TODO",
    "situ_nombre": "(todos)"
  },
  {
    "situ_id": "al_dia",
    "situ_nombre": "Al día"
  },
  {
    "situ_id": "atrasada",
    "situ_nombre": "Atrasada"
  }
]

  export const meses = [
    {
      "mes_id": "01",
      "mes_nombre": "Enero"
    },
    {
      "mes_id": "02",
      "mes_nombre": "Febrero"
    },
    {
      "mes_id": "03",
      "mes_nombre": "Marzo"
    },
    {
      "mes_id": "04",
      "mes_nombre": "Abril"
    },
    {
      "mes_id": "05",
      "mes_nombre": "Mayo"
    },
    {
      "mes_id": "06",
      "mes_nombre": "Junio"
    },
    {
      "mes_id": "07",
      "mes_nombre": "Julio"
    },
    {
      "mes_id": "08",
      "mes_nombre": "Agosto"
    },
    {
      "mes_id": "09",
      "mes_nombre": "Septiembre"
    },
    {
      "mes_id": "10",
      "mes_nombre": "Octubre"
    },
    {
      "mes_id": "11",
      "mes_nombre": "Noviembre"
    },
    {
      "mes_id": "12",
      "mes_nombre": "Diciembre"
    },
  ]
  
  export const anos = [
    {
      "ano_id": 2020,
      "ano_nombre": "2020"
    },
    {
      "ano_id": 2019,
      "ano_nombre": 2019
    }
  ]
  
  export const tipo_cliente = [
    {
      "tcle_id": "_TODO",
      "tcle_nombre": ""
    },
    {
      "tcle_id": "cliente",
      "tcle_nombre": "Cliente"
    },
    {
      "tcle_id": "no_cliente",
      "tcle_nombre": "No Cliente"
    }
  ]
  
  export const resp_gestion = [
    {
      "rges_id": "_TODO",
      "rges_nombre": "(todos)"
    },
    {
      "rges_id": "ejec_plat",
      "rges_nombre": "Ejecutivo Comercial"
    },
    {
      "rges_id": "ejec_prod",
      "rges_nombre": "Ejecutivo Productos"
    }
  ]
  
  export const tipo_alertas = [
    {
      "aler_id": "_TODO",
      "aler_nombre": "(todos)"
    },
    {
      "aler_id": "alerta_comercial",
      "aler_nombre": "Alerta Comercial"
    },
    {
      "aler_id": "alerta_gestion",
      "aler_nombre": "Alerta de Gestión"
    },
    {
      "aler_id": "alerta_crediticia",
      "aler_nombre": "Alerta Crediticia"
    }
  ]
  