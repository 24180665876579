import React from "react";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
//require('highcharts/modules/exporting')(Highcharts)

const GraficoLinea = ({params, datap}) => {

datap = datap.reverse();

    const  opciones = {
	    chart: {
            type: 'line',
            },
        title: {
            text: params.title,
            style: {
                    fontSize: '15px',
                    fontFamily: 'Arial, Verdana, sans-serif'
            }
        },
        subtitle: {
            text: params.subtitle
        },
        credits: { 
            enabled: false 
        },
        exporting: {
            enabled: false
        },        
        xAxis: {
            title: {
                text: params.xaxis_title,
                style: {
                    fontSize: '11px',
                    fontFamily: 'Arial, Verdana, sans-serif'
                }
            },
            categories: datap.map (cont => cont.anomes),
            
            labels: {
                rotation: -45,
                enabled: true,
            },
        },
        yAxis: {
            title: {
                text: params.yaxis_title,
                align: 'high'
            },
        },
        plotOptions: {
            bar: {
                dataLabels: {
                    enabled: true,
                    style: {
                        fontSize: '9px',
                        fontFamily: 'Arial, Verdana, sans-serif'
                    }
                }
           },
            column: {
                dataLabels: {
                    enabled: true,
                    style: {
                        fontSize: '9px',
                        fontFamily: 'Arial, Verdana, sans-serif'
                    }
                }
            }
        },
        legend: {
            layout: 'vertical',
            align: 'right',
            verticalAlign: 'top',
            x: -40,
            y: 100,
            floating: true,
            borderWidth: 1,
            backgroundColor: '#FFFFFF',
            shadow: true,
            enabled: false
        },
        tooltip: {
            pointFormat: "Valor: {point.y:,.0f}"
        },
        series: [{
            name: 'nombre',
            data: datap.map (cont => parseInt(cont.valor1))
        }]
    }

return (
    <>
    <HighchartsReact
        highcharts={Highcharts}
        options={opciones}
    />
    </>
);
}

export default GraficoLinea;