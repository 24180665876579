import React from 'react';
import Grid from 'canvas-core-react/lib/Grid';
import Row from 'canvas-core-react/lib/Row';
import Col from 'canvas-core-react/lib/Column';
import { useHttpGet as useHttp, MsgeLoading } from './useHttp';
import {serviciosSIC} from '../data/const_servicios'
import {formatCantidad} from './SicUtilidades';
import '../Sic.scss';

const posDominioEtapa = (dominio, vEtapa, vSubEtapa) => {
  for(var i=0; i<dominio.length; i++) {
    if (vEtapa !== "cierre") {
      if (dominio[i].etap_tpk2_id === vEtapa) {
        return(i)
      }
    }
    else if (dominio[i].sube_tpk2_id === vSubEtapa) {
        return(i)
    }
  }
  return(0)
}

const nombreEtapa = (dominio) => {
    if (dominio.etap_tpk2_id !== "cierre") {
        return(dominio.etap_tabp_alias)
    }
    else {
      return(dominio.sube_tabp_alias)
    }
}

const tuboMatriz = (dominio, tubo) => {

  var ant = '';
  var p = 0;
  var matriz = [];
  var k = -1;
  matriz[0] = []
  var tlenght = tubo.length

  for(var i=0; i < tlenght; i++) {
    p = posDominioEtapa(dominio, tubo[i].tubo_etapa, tubo[i].tubo_subetapa);

    if (ant === tubo[i].gest_id) {
      matriz[k][p+10] = tubo[i].tubo_cantidad
      matriz[k][tlenght+10-1] += tubo[i].tubo_cantidad
    }
    else {
      ant = tubo[i].gest_id;
      k = k+1;
      matriz[k] = []
      matriz[k][0] = tubo[i].bcas_nombre
      matriz[k][1] = tubo[i].gcia_nombre
      matriz[k][2] = tubo[i].plat_nombre
      matriz[k][3] = tubo[i].pers_nbre_apat_amat_gest

      matriz[k][4] = tubo[i].bcas_id
      matriz[k][5] = tubo[i].gcia_id
      matriz[k][6] = tubo[i].plat_id
      matriz[k][7] = tubo[i].gest_id

      for(var j=0; j<20; j++) {
        matriz[k][j+10] = 0
      }

      matriz[k][p+10] = tubo[i].tubo_cantidad
      matriz[k][tlenght+10-1] += tubo[i].tubo_cantidad
    }
  }

  var mFilas = matriz.length
  k = k+1;
  matriz[k] = []
  matriz[k][0] = ''
  matriz[k][1] = ''
  matriz[k][2] = ''
  matriz[k][3] = 'TOTAL'

  matriz[k][4] = ''
  matriz[k][5] = ''
  matriz[k][6] = ''
  matriz[k][7] = ''

  for(var jj=0; jj<20; jj++) {
    matriz[k][jj+10] = 0
  }

  for(var ii=0; ii < mFilas; ii++) {
    for(var ij=0; ij<20; ij++) {
      matriz[k][ij+10] += matriz[ii][ij+10]
    }
  }

  return(matriz)
}

const PanelControlTuboComercial = ({bcas_id, gcia_id, plat_id, gest_id}) => {

var fecha = new Date();
for (var d=0; d<30; d++) {
  fecha.setDate(fecha.getDate() + 1);
//  console.log('dia, mes', fecha, fecha.getDay(), fecha.getMonth())  
}
  const params_etapa = `caso=${serviciosSIC.serv_select_etapas_x_params}&bcas_id=${bcas_id}&gcia_id=${gcia_id}`
  const [postEtapas, isLoading_etapa] = useHttp(params_etapa)
  const domEtapas = ((isLoading_etapa) ? [] : postEtapas)

  const params = `caso=${serviciosSIC.serv_select_pcontrol_x_tubo_comercial}&bcas_id=${bcas_id}&gcia_id=${gcia_id}&plat_id=${plat_id}&gest_id=${gest_id}`
  const [post, isLoading] = useHttp(params)
  const tuboComercial = ((isLoading) ? [] : post)

  const mensajeCarga = () => {if (isLoading || isLoading_etapa) return(<MsgeLoading/>)}

  const tuboM = tuboMatriz(domEtapas, tuboComercial);
  
    if ((bcas_id === 'producto') || (bcas_id === '_SELE') || (bcas_id === '') ) return('')

    return(
    <>
    {mensajeCarga()} 
    <div className='sic-container'>
    <Grid >
        <Row className="Control__contenedor">
            <Col lg={12} md={12} sm={8} xs={4}>

{/*
<div className="sic-container sic-margin-top">
<table className="sic-table sic-hoverable sic-striped">
<thead>
<tr className="sic-xmedium sic-color-dark-blue sic-background-color-thead">
<th className="sic-border sic-align-center">etap_id</th>
<th className="sic-border sic-align-center">etap_nombre</th>
</tr>
</thead>
<tbody>
{
domEtapas.map((etapa, idx_etapa) => (
<tr className="sic-medium" key={"etapa_"+idx_etapa}>
<td className="sic-border sic-align-center">{etapa.tpk2_id}</td>
<td className="sic-border sic-align-center">{etapa.tabp_alias}</td>
</tr>
))
}
</tbody>
</table>
</div>
*/}

<div className="sic-container sic-margin-top">
<table className="sic-table sic-hoverable sic-striped">
<thead>
<tr className="sic-xmedium sic-color-dark-blue sic-background-color-thead">
<th className="sic-border sic-align-center"></th>
<th className="sic-border sic-align-center">Banca</th>
<th className="sic-border sic-align-center">Gerencia</th>
<th className="sic-border sic-align-center">Plataforma</th>
<th className="sic-border sic-align-center">Ejecutivo</th>
{
domEtapas.map((dataE, idx) => (
  <th className="sic-border sic-align-center">{nombreEtapa(dataE)}</th>
))
}
</tr>
</thead>
<tbody>
{
  tuboM.map((tuboE, idxt) => (
    <tr>
      <td className="sic-border">{idxt+1}</td>
      {
      tuboE.map((tuboF, idxf) => {
        if (idxf < 4) {
          return(<td className="sic-border">{tuboF}</td>)
        }
        else if ( (idxf >= 10) && (idxf < domEtapas.length+10)) {
          return(<td className="sic-border sic-align-center">{formatCantidad(tuboF)}</td>)
        }
        else {
          return('')
        }
      }
      )
      }
    </tr>
  ))
}

</tbody>
</table>
</div>

{/*
              <div className="sic-container sic-margin-top">

                <table className="sic-table sic-hoverable sic-striped">

                  <thead>
                    <tr className="sic-xmedium sic-color-dark-blue sic-background-color-thead">
                      <th className="sic-border sic-align-center">`{bcas_id}<br/>{gcia_id}<br/>{plat_id}<br/>{gest_id}`</th>
                      <th className="sic-border sic-align-center">bcas_id</th>
                      <th className="sic-border sic-align-center">bcas_nombre</th>
                      <th className="sic-border sic-align-center">bcas_orden</th>
                      <th className="sic-border sic-align-center">gcia_id</th>
                      <th className="sic-border sic-align-center">gcia_nombre</th>
                      <th className="sic-border sic-align-center">gcia_orden</th>
                      <th className="sic-border sic-align-center">plat_id</th>
                      <th className="sic-border sic-align-center">plat_nombre</th>
                      <th className="sic-border sic-align-center">plat_orden</th>
                      <th className="sic-border sic-align-center">gest_id</th>
                      <th className="sic-border sic-align-center">gest_nombre</th>
                      <th className="sic-border sic-align-center">gest_orden</th>
                      <th className="sic-border sic-align-center">pers_id</th>
                      <th className="sic-border sic-align-center">pers_num_empleado</th>
                      <th className="sic-border sic-align-center">pers_nbre_apat_amat_gest</th>
                      <th className="sic-border sic-align-center">tubo_gestor</th>
                      <th className="sic-border sic-align-center">tubo_etapa</th>
                      <th className="sic-border sic-align-center">tubo_subetapa</th>
                      <th className="sic-border sic-align-center">tubo_cantidad</th>
                      <th className="sic-border sic-align-center">BGPE</th>
                      <th className="sic-border sic-align-center">etap_tabp_alias</th>
                      <th className="sic-border sic-align-center">etap_tabp_orden</th>
                    </tr>
                  </thead>

                  <tbody>
                  {
                    tuboComercial.map((data, idx) => (
                      <tr className="sic-medium" key={"accs_"+idx}>
                        <td className="sic-border sic-align-center">{idx}</td>
                        <td className="sic-border sic-align-center">{data.bcas_id}</td>
                        <td className="sic-border sic-align-center">{data.bcas_nombre}</td>
                        <td className="sic-border sic-align-center">{data.bcas_orden}</td>
                        <td className="sic-border sic-align-center">{data.gcia_id}</td>
                        <td className="sic-border sic-align-center">{data.gcia_nombre}</td>
                        <td className="sic-border sic-align-center">{data.gcia_orden}</td>
                        <td className="sic-border sic-align-center">{data.plat_id}</td>
                        <td className="sic-border sic-align-center">{data.plat_nombre}</td>
                        <td className="sic-border sic-align-center">{data.plat_orden}</td>
                        <td className="sic-border sic-align-center">{data.gest_id}</td>
                        <td className="sic-border sic-align-center">{data.gest_nombre}</td>
                        <td className="sic-border sic-align-center">{data.gest_orden}</td>
                        <td className="sic-border sic-align-center">{data.pers_id}</td>
                        <td className="sic-border sic-align-center">{data.pers_num_empleado}</td>
                        <td className="sic-border sic-align-center">{data.pers_nbre_apat_amat_gest}</td>
                        <td className="sic-border sic-align-center">{data.tubo_gestor}</td>
                        <td className="sic-border sic-align-center">{data.tubo_etapa}</td>
                        <td className="sic-border sic-align-center">{data.tubo_subetapa}</td>
                        <td className="sic-border sic-align-center">{data.tubo_cantidad}</td>
                        <td className="sic-border sic-align-center">{data.BGPE}</td>
                        <td className="sic-border sic-align-center">{data.etap_tabp_alias}</td>
			                  <td className="sic-border sic-align-center">{data.etap_tabp_orden}</td>
                      </tr>
                    ))
                  }
                    </tbody>
                </table>
              </div>
                */}
            </Col>

        </Row>
    </Grid>
    </div>

    </>
    )
};

export default PanelControlTuboComercial;
