import React from 'react';
import {formatMonto} from './SicUtilidades';
import '../Sic.scss';

const PanelVencimientosCompl = ({texto, valor, formato=''}) => {

	const valor_fmt = (
		(formato!=='') ? formatMonto(valor, formato) : valor
	)

	if ( (valor !== null) && (valor !== undefined) && (valor !== 0) && (valor !== '') )
		return(
			<tr>
				<td className="sic-color-dark-blue">{texto}</td>
				<td className="sic-align-right">{valor_fmt}</td>
			</tr>
		)
	else
		return('')

}

export default PanelVencimientosCompl;