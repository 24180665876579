import React from 'react';
import Grid from 'canvas-core-react/lib/Grid';
import Row from 'canvas-core-react/lib/Row';
import Col from 'canvas-core-react/lib/Column';
//import { cliente_atributos_x_rut as cliente} from '../data/get_clientes';
//import { cliente_clienteSitFinanciera_x_rut as clienteSitFinanciera} from '../data/get_clientes';
import {formatMonto, formatPctje, formatSiNo} from './SicUtilidades';
import '../Sic.scss';

const FichaClienteFicha = ({cliente, clienteSitFinanciera}) => {

return(
<>
<Grid className=" sic-xmedium">
    <Row className="Control__contenedor">
        <Col lg={12} md={12} sm={8} xs={4}>

<div className="sic-card-2 sic-xmedium">

<table className="sic-table sic-medium sic-text-color-default sic-border sic-bordered">
    <thead>
    <tr>
        <th className="sic-large sic-align-center sic-text-color-dark-blue" colSpan="8">Datos del Cliente</th>
    </tr>
    </thead>
    <tbody>
    <tr>
        <td className="sic-border sic-background-color-td-ficha">Nivel de Venta (MM$)</td>
        <td className="sic-border sic-text-color-blue sic-align-right">{cliente.bec_clte_nivel_venta}</td>
        <td className="sic-border sic-background-color-td-ficha">ROA</td>
        <td className="sic-border sic-text-color-blue">{cliente.troa_tabp_nombre}</td>
        <td className="sic-border sic-background-color-td-ficha"></td>
        <td className="sic-border sic-text-color-blue"></td>
        <td className="sic-border sic-background-color-td-ficha">Nro. Productos</td>
        <td className="sic-border sic-text-color-blue sic-align-right">{cliente.clte_nro_productos}</td>
    </tr>
    <tr>
        <td className="sic-border sic-background-color-td-ficha">IG</td>
        <td className="sic-border sic-text-color-blue sic-align-center">{cliente.bec_clte_rating}</td>
        <td className="sic-border sic-background-color-td-ficha">Dep &uacute;lt 3 meses MM$</td>
        <td className="sic-border sic-text-color-blue sic-align-right">{cliente.clte_salem}</td>
        <td className="sic-border sic-background-color-td-ficha">Protestos</td>
        <td className="sic-border sic-text-color-blue">{formatSiNo(cliente.clte_dicom)}</td>
        <td className="sic-border sic-background-color-td-ficha">Deterioro SBIF</td>
        <td className="sic-border sic-text-color-blue">{cliente.clte_deterioro}</td>
    </tr>
    <tr>
        <td className="sic-border sic-background-color-td-ficha">Aduana Importaci&oacute;n<br/>a&ntilde;o ant. (M USD)</td>
        <td className="sic-border sic-text-color-blue sic-align-right">{formatMonto(cliente.clte_aduana_importaciones)}</td>
        <td className="sic-border sic-background-color-td-ficha">Aduana Exportaci&oacute;n<br/>a&ntilde;o ant. (M USD)</td>
        <td className="sic-border sic-text-color-blue sic-align-right">{formatMonto(cliente.clte_aduana_exportaciones)}</td>
{/*}
        <td className="sic-border sic-background-color-td-ficha">Visitas &uacute;lt. 12 meses</td>
        <td className="sic-border sic-text-color-blue sic-align-right"><a href="/">?</a></td>
        <td className="sic-border sic-background-color-td-ficha">Fecha &uacute;lt. Visita</td>
        <td className="sic-border sic-text-color-blue">?</td>
*/}
		</tr>
    </tbody>
</table>
</div>
        </Col>
    </Row>

    <Row className="Control__contenedor">
        <Col className="sic-container-centered sic-margin-top sic-background-color-white sic-padding" lg={6} md={6} sm={4} xs={2}>



<div className="sic-card-2">

<table className="sic-table sic-medium sic-text-color-default sic-border sic-bordered">
	<thead>
		<tr>
			<th className="sic-large sic-align-center sic-text-color-dark-blue" colSpan="4">Situaci&oacute;n Financiera (M$)</th>
		</tr>
		<tr>
			<th className="sic-xmedium sic-align-center sic-text-color-dark-blue sic-background-color-thead" colSpan="4">Informaci&oacute;n a {clienteSitFinanciera.sfin_directa_periodo_datos}</th>
		</tr>
		<tr>
			<th className="sic-small sic-align-center sic-text-color-dark-blue sic-background-color-thead sic-border"></th>
			<th className="sic-small sic-align-center sic-text-color-dark-blue sic-background-color-thead sic-border">Banco</th>
			<th className="sic-small sic-align-center sic-text-color-dark-blue sic-background-color-thead sic-border">SBIF</th>
			<th className="sic-small sic-align-center sic-text-color-dark-blue sic-background-color-thead sic-border">%</th>
		</tr>
	</thead>

	<tbody>
		<tr>
			<td className="sic-border">Deuda Directa Total</td>
			<td className="sic-border sic-text-color-blue sic-align-right">{formatMonto(clienteSitFinanciera.sfin_directa_deuda_total_bbva)}</td>
			<td className="sic-border sic-text-color-blue sic-align-right">{formatMonto(clienteSitFinanciera.sfin_directa_deuda_total_sbif)}</td>
			<td className="sic-border sic-text-color-blue sic-align-right">{formatPctje(clienteSitFinanciera.sfin_directa_deuda_total_porcentaje)}</td>
		</tr>
		<tr>
			<td className="sic-border">Deuda Comercial</td>
			<td className="sic-border sic-text-color-blue sic-align-right">{formatMonto(clienteSitFinanciera.sfin_directa_deuda_comercial_bbva)}</td>
			<td className="sic-border sic-text-color-blue sic-align-right">{formatMonto(clienteSitFinanciera.sfin_directa_deuda_comercial_sbif)}</td>
			<td className="sic-border sic-text-color-blue sic-align-right">{formatPctje(clienteSitFinanciera.sfin_directa_deuda_comercial_porcentaje)}</td>
		</tr>
		<tr>
			<td className="sic-border">Deuda Leasing</td>
			<td className="sic-border sic-text-color-blue sic-align-right">{formatMonto(clienteSitFinanciera.sfin_directa_deuda_leasing_bbva)}</td>
			<td className="sic-border sic-text-color-blue sic-align-right">{formatMonto(clienteSitFinanciera.sfin_directa_deuda_leasing_sbif)}</td>
			<td className="sic-border sic-text-color-blue sic-align-right">{formatPctje(clienteSitFinanciera.sfin_directa_deuda_leasing_porcentaje)}</td>
		</tr>
		<tr>
			<td className="sic-border">Disponible L&iacute;nea Cr&eacute;dito</td>
			<td className="sic-border sic-text-color-blue sic-align-right">{formatMonto(clienteSitFinanciera.sfin_directa_deuda_linea_credito_bbva)}</td>
			<td className="sic-border sic-text-color-blue sic-align-right">{formatMonto(clienteSitFinanciera.sfin_directa_deuda_linea_credito_sbif)}</td>
			<td className="sic-border sic-text-color-blue sic-align-right">{formatPctje(clienteSitFinanciera.sfin_directa_deuda_linea_credito_porcentaje)}</td>
		</tr>
		<tr>
			<td className="sic-border">Deuda Contingente</td>
			<td className="sic-border sic-text-color-blue sic-align-right">{formatMonto(clienteSitFinanciera.sfin_directa_deuda_contingente_bbva)}</td>
			<td className="sic-border sic-text-color-blue sic-align-right">{formatMonto(clienteSitFinanciera.sfin_directa_deuda_contingente_sbif)}</td>
			<td className="sic-border sic-text-color-blue sic-align-right">{formatPctje(clienteSitFinanciera.sfin_directa_deuda_contingente_porcentaje)}</td>
		</tr>
		<tr>
			<td className="sic-border">Deuda Indirecta Total</td>
			<td className="sic-border sic-text-color-blue sic-align-right">{formatMonto(clienteSitFinanciera.sfin_indirecta_deuda_total_bbva)}</td>
			<td className="sic-border sic-text-color-blue sic-align-right">{formatMonto(clienteSitFinanciera.sfin_indirecta_deuda_total_sbif)}</td>
			<td className="sic-border sic-text-color-blue sic-align-right">{formatPctje(clienteSitFinanciera.sfin_indirecta_deuda_total_porcentaje)}</td>
		</tr>
	</tbody>
</table>
</div>

        </Col>

        <Col className="sic-container-centered sic-margin-top sic-background-color-white sic-padding" lg={6} md={6} sm={4} xs={2}>

<div className="sic-card-2">

<table className="sic-table sic-medium sic-text-color-default sic-border sic-bordered">
	<thead>
		<tr>
			<th className="sic-large sic-align-center sic-text-color-dark-blue"  colSpan="3">Facility Rotativa </th>
		</tr>
		<tr>
			<th className="sic-medium sic-align-center sic-text-color-dark-blue sic-background-color-thead" colSpan="3" >Vencimiento: dd-mm-aaaa ?</th>
		</tr>
		<tr>
			<th className="sic-small sic-align-center sic-text-color-dark-blue sic-background-color-thead sic-border">M&aacute;rgenes</th>
			<th className="sic-small sic-align-center sic-text-color-dark-blue sic-background-color-thead sic-border">Aprobado</th>
			<th className="sic-small sic-align-center sic-text-color-dark-blue sic-background-color-thead sic-border">Disponible</th>
		</tr>
	</thead>
	<tbody>
		<tr>
			<td className="sic-border sic-xmedium">Facility Rotativa</td>
			<td className="sic-border sic-align-right">{formatMonto(cliente.bec_margen_rotativo_total_aprobado)}</td>
			<td className="sic-border sic-align-right">{formatMonto(cliente.bec_margen_rotativo_total_disponible)}</td>
		</tr>

		<tr>
			<td className="sic-border">- Comex</td>
			<td className="sic-border sic-align-right">{formatMonto(cliente.bec_margen_rotativo_comex_aprobado)}</td>
			<td className="sic-border sic-align-right">{formatMonto(cliente.bec_margen_rotativo_comex_disponible)}</td>
		</tr>

		<tr>
			<td className="sic-border">- Cr&eacute;dito en moneda local</td>
			<td className="sic-border sic-align-right">{formatMonto(cliente.bec_margen_rotativo_credito_moneda_local_aprobado)}</td>
			<td className="sic-border sic-align-right">{formatMonto(cliente.bec_margen_rotativo_credito_moneda_local_disponible)}</td>
		</tr>

		<tr>
			<td className="sic-border">- Crédito en moneda extranjera</td>
			<td className="sic-border sic-align-right">{formatMonto(cliente.bec_margen_rotativo_oper_en_moneda_ext_no_comex_aprobado)}</td>
			<td className="sic-border sic-align-right">{formatMonto(cliente.bec_margen_rotativo_oper_en_moneda_ext_no_comex_disponible)}</td>
		</tr>

		<tr>
			<td className="sic-border">- Boleta de garant&iacute;a</td>
			<td className="sic-border sic-align-right">{formatMonto(cliente.bec_margen_rotativo_boleta_garantia_tecnica_aprobado)}</td>
			<td className="sic-border sic-align-right">{formatMonto(cliente.bec_margen_rotativo_boleta_garantia_tecnica_disponible)}</td>
		</tr>

		<tr>
			<td className="sic-border">- Factoring</td>
			<td className="sic-border sic-align-right">{formatMonto(cliente.bec_margen_rotativo_factoring_banco_aprobado)}</td>
			<td className="sic-border sic-align-right">{formatMonto(cliente.bec_margen_rotativo_factoring_banco_disponible)}</td>
		</tr>

	</tbody>
</table>
</div>

        </Col>
    </Row>

    <Row className="Control__contenedor">
        <Col className="sic-container-centered sic-margin-top sic-background-color-white sic-padding">
<div className="sic-card-2">

<table className="sic-table sic-medium sic-text-color-default sic-border sic-bordered">
	<thead>
		<tr>
			<th className="sic-large sic-align-center sic-text-color-dark-blue" colSpan="6">Productos Base</th>
		</tr>
	</thead>
	<tbody>
		<tr>
			<td className="sic-border">Cuenta Corriente Peso</td>
			<td className="sic-border sic-text-color-blue sic-align-right">{formatSiNo(cliente.bec_prod_cuenta_corriente_peso_tenencia)}</td>
			<td className="sic-border">Saldo Prom. &uacute;tl mes (M$)</td>
			<td className="sic-border sic-text-color-blue sic-align-right">{formatMonto(cliente.bec_prod_cuenta_corriente_peso_saldo_prom_ult_mes)}</td>
			<td className="sic-border"></td>
			<td className="sic-border"></td>
		</tr>
		<tr>
			<td className="sic-border">Cuenta Corriente D&oacute;lar</td>
			<td className="sic-border sic-text-color-blue sic-align-right">{formatSiNo(cliente.bec_prod_cuenta_corriente_dolar_tenencia)}</td>
			<td className="sic-border">Saldo Prom. &uacute;tl mes (USD)</td>
			<td className="sic-border sic-text-color-blue sic-align-right">{formatMonto(cliente.bec_prod_cuenta_corriente_dolar_saldo_prom_ult_mes)}</td>
			<td className="sic-border"></td>
			<td className="sic-border"></td>
		</tr>
		<tr>
			<td className="sic-border">Cuenta Corriente EURO</td>
			<td className="sic-border sic-text-color-blue sic-align-right">{formatSiNo(cliente.bec_prod_cuenta_corriente_euro_tenencia)}</td>
			<td className="sic-border">Saldo Prom. &uacute;tl mes (EURO)</td>
			<td className="sic-border sic-text-color-blue sic-align-right">{formatMonto(cliente.bec_prod_cuenta_corriente_euro_saldo_prom_ult_mes)}</td>
			<td className="sic-border"></td>
			<td className="sic-border"></td>
		</tr>
		<tr>
			<td className="sic-border">L&iacute;nea de Cr&eacute;dito</td>
			<td className="sic-border sic-text-color-blue">{formatSiNo(cliente.bec_prod_linea_credito_tenencia)}</td>
			<td className="sic-border">Aprobado (M$)</td>
			<td className="sic-border sic-text-color-blue sic-align-right">{formatMonto(cliente.bec_prod_linea_credito_aprobado)}</td>
			<td className="sic-border">Disponible (M$)</td>
			<td className="sic-border sic-text-color-blue sic-align-right">{formatMonto(cliente.bec_prod_linea_credito_disponible)}</td>
		</tr>
		<tr>
			<td className="sic-border">Tarjeta de Cr&eacute;dito</td>
			<td className="sic-border sic-text-color-blue">{formatSiNo(cliente.bec_prod_tarjeta_credito_tenencia)}</td>
			<td className="sic-border">Aprobado (M$)</td>
			<td className="sic-border sic-text-color-blue sic-align-right">{formatMonto(cliente.bec_prod_tarjeta_credito_aprobado)}</td>
			<td className="sic-border">Disponible (M$)</td>
			<td className="sic-border sic-text-color-blue sic-align-right">{formatMonto(cliente.bec_prod_tarjeta_credito_disponible)}</td>
		</tr>
	</tbody>
</table>
</div>

        </Col>
    </Row>

    <Row justifyContent='center' className="Control__contenedor">
        <Col className="sic-container-centered sic-margin-top sic-background-color-white sic-padding" lg={6} md={6} sm={4} xs={2}>
    
<div className="sic-card-2">
<table className="sic-table sic-medium sic-text-color-default sic-border sic-bordered">
	<thead>
		<tr>
			<th className="sic-large sic-align-center sic-text-color-dark-blue" colSpan="2">Productos de Activo</th>
		</tr>
        <tr>
			<th className="sic-small sic-align-center sic-text-color-dark-blue sic-background-color-thead sic-border"></th>
			<th className="sic-small sic-align-center sic-text-color-dark-blue sic-background-color-thead sic-border">Saldo puntual (M$)</th>
		</tr>
    </thead>
	<tbody>
		<tr>
			<td className="sic-border sic-xmedium">Colocaciones Efectivas</td>
			<td className="sic-border sic-text-color-blue sic-align-right">{formatMonto(cliente.bec_prod_colocaciones_efectivas_monto)}</td>
		</tr>
		<tr>
			<td className="sic-border">Cr&eacute;dito Comercial MN</td>
			<td className="sic-border sic-text-color-blue sic-align-right">{formatMonto(cliente.bec_prod_credito_comercial_mn_cp_monto)}</td>
		</tr>
		<tr>
			<td className="sic-border">PAE</td>
			<td className="sic-border sic-text-color-blue  sic-align-right">{formatMonto(cliente.bec_prod_pae_monto)}</td>
		</tr>
		<tr>
			<td className="sic-border">Credito Comercial ME</td>
			<td className="sic-border sic-text-color-blue  sic-align-right">{formatMonto(cliente.bec_prod_credito_comercial_me_monto)}</td>
		</tr>
		<tr>
			<td className="sic-border">Carta de Cr&eacute;dito Negociada</td>
			<td className="sic-border sic-text-color-blue sic-align-right"></td>
		</tr>
		<tr>
			<td className="sic-border">Financiamiento Contado</td>
			<td className="sic-border sic-text-color-blue  sic-align-right">{formatMonto(cliente.bec_prod_financiamiento_import_monto)}</td>
		</tr>
		<tr>
			<td className="sic-border">Leasing</td>
			<td className="sic-border sic-text-color-blue  sic-align-right">{formatMonto(cliente.bec_prod_leasing_mobiliario_monto)}</td>
		</tr>
		<tr>
			<td className="sic-border">Factoring</td>
			<td className="sic-border sic-text-color-blue  sic-align-right">{formatMonto(cliente.bec_prod_factoring_financiero_monto)}</td>
		</tr>
		<tr>
			<td className="sic-border">E-Confirming</td>
			<td className="sic-border sic-text-color-blue  sic-align-right">{formatMonto(cliente.bec_prod_confirming_monto)}</td>
		</tr>

		<tr>
			<td className="sic-border" colSpan="2"></td>
		</tr>

		<tr>
			<td className="sic-border sic-xmedium">Colocaciones Contingentes</td>
			<td className="sic-border sic-text-color-blue  sic-align-right">{formatMonto(cliente.bec_prod_colocaciones_contingentes_monto)}</td>
		</tr>
		<tr>
			<td className="sic-border">Boletas Garant&iacute;a</td>
			<td className="sic-border sic-text-color-blue  sic-align-right">{formatMonto(cliente.bec_prod_boletas_garantia_monto)}</td>
		</tr>
		<tr>
			<td className="sic-border">Carta Cr&eacute;dito (Importaci&oacute;n)</td>
			<td className="sic-border sic-text-color-blue  sic-align-right">{formatMonto(cliente.bec_prod_carta_credito_imp_monto)}</td>
		</tr>
		<tr>
			<td className="sic-border">Stand By</td>
			<td className="sic-border sic-text-color-blue  sic-align-right">{formatMonto(cliente.bec_prod_stand_by_monto)}</td>
		</tr>

	</tbody>
</table>
    
</div>    
    
<div className="sic-card-2 sic-margin-top">

<table className="sic-table sic-medium sic-text-color-default sic-border sic-bordered">
	<thead>
		<tr>
			<th className="sic-large sic-align-center sic-text-color-dark-blue" colSpan="3">Inversi&oacute;n</th>
		</tr>
        <tr>
			<th className="sic-border sic-background-color-thead"></th>
			<th className="sic-border sic-text-color-dark-blue sic-background-color-thead sic-align-center">S&iacute;/No</th>
			<th className="sic-border sic-text-color-dark-blue sic-background-color-thead sic-align-center">Saldo prom.<br/>&uacute;ltimo mes (M$)</th>
		</tr>
    </thead>

	<tbody>
		<tr>
			<td className="sic-border">Dep&oacute;sito a Plazo (DAP)</td>
			<td className="sic-border sic-text-color-blue sic-align-center">{formatSiNo(cliente.clte_inve_dap_tenencia)}</td>
			<td className="sic-border sic-text-color-blue  sic-align-right">{formatMonto(cliente.clte_inve_dap_monto)}</td>
		</tr>
		<tr>
			<td className="sic-border">Fondos Mutuos</td>
			<td className="sic-border sic-text-color-blue sic-align-center">{formatSiNo(cliente.clte_inve_fondos_mutuos_tenencia)}</td>
			<td className="sic-border sic-text-color-blue  sic-align-right">{formatMonto(cliente.clte_inve_fondos_mutuos_monto)}</td>
		</tr>
	</tbody>
</table>

</div>
    
        </Col>

        <Col className="sic-container-centered sic-margin-top sic-background-color-white sic-padding" lg={6} md={6} sm={4} xs={2}>
<div className="sic-card-2">
<table className="sic-table sic-medium sic-text-color-default sic-border sic-bordered">
	<thead>
		<tr>
			<th className="sic-large sic-align-center sic-text-color-dark-blue" colSpan="2">Servicios</th>
		</tr>
        <tr>
			<th className="sic-small sic-align-center sic-text-color-dark-blue sic-background-color-thead sic-border"></th>
			<th className="sic-small sic-align-center sic-text-color-dark-blue sic-background-color-thead sic-border">Habilitaci&oacute;n</th>
		</tr>
    </thead>
	<tbody>
		<tr>
			<td className="sic-border">Scotia Web</td>
			<td className="sic-border sic-text-color-blue  sic-align-right">{formatMonto(cliente.bec_serv_bbva_net_cash_habilitacion)}</td>
		</tr>
		<tr>
			<td className="sic-border">Uso Scotia Web</td>
			<td className="sic-border sic-text-color-blue  sic-align-right">{formatMonto(cliente.bec_serv_uso_bbva_net_cash_habilitacion)}</td>
		</tr>
		<tr>
			<td className="sic-border">Scotia Transfer</td>
			<td className="sic-border sic-text-color-blue  sic-align-right">{formatMonto(cliente.bec_serv_web_comex_habilitacion)}</td>
		</tr>
		<tr>
			<td className="sic-border">Consultivo Factoring</td>
			<td className="sic-border sic-text-color-blue  sic-align-right"></td>
		</tr>
		<tr>
			<td className="sic-border">Factoring Web</td>
			<td className="sic-border sic-text-color-blue  sic-align-right"></td>
		</tr>
		<tr>
			<td className="sic-border">Confirming Electrónico</td>
			<td className="sic-border sic-text-color-blue  sic-align-right"></td>
		</tr>
	</tbody>
</table>

</div>

<div className="sic-card-2 sic-margin-top">
<table className="sic-table sic-medium sic-text-color-default sic-border sic-bordered">
	
	<thead>
		<tr>
			<th className="sic-small sic-align-center sic-text-color-dark-blue sic-background-color-thead sic-border"></th>
			<th className="sic-small sic-align-center sic-text-color-dark-blue sic-background-color-thead sic-border">Canal</th>
			<th className="sic-small sic-align-center sic-text-color-dark-blue sic-background-color-thead sic-border">&uacute;ltimo mes<br/>(M$)</th>
			<th className="sic-small sic-align-center sic-text-color-dark-blue sic-background-color-thead sic-border">Prom. &uacute;ltimos<br/>tres meses (M$)</th>
		</tr>
	</thead>

	<tbody>
		<tr>
			<td className="sic-border">Impuestos</td>
			<td className="sic-border sic-text-color-blue  sic-align-right">{cliente.bec_serv_impuestos_canal}</td>
			<td className="sic-border sic-text-color-blue  sic-align-right">{formatMonto(cliente.bec_serv_impuestos_ultimo_mes)}</td>
			<td className="sic-border sic-text-color-blue  sic-align-right">{formatMonto(cliente.bec_serv_impuestos_ultimos_tres_meses)}</td>
		</tr>
		<tr>
			<td className="sic-border">Transferencias Masivas</td>
			<td className="sic-border sic-text-color-blue  sic-align-right">{cliente.bec_serv_transferencias_masivas_canal}</td>
			<td className="sic-border sic-text-color-blue  sic-align-right">{formatMonto(cliente.bec_serv_transferencias_masivas_ultimo_mes)}</td>
			<td className="sic-border sic-text-color-blue  sic-align-right">{formatMonto(cliente.bec_serv_transferencias_masivas_ultimos_tres_meses)}</td>
		</tr>
		<tr>
			<td className="sic-border">Transferencia Alto Valor</td>
			<td className="sic-border sic-text-color-blue  sic-align-right">{cliente.bec_serv_transferencias_alto_valor_canal}</td>
			<td className="sic-border sic-text-color-blue  sic-align-right">{formatMonto(cliente.bec_serv_transferencias_alto_valor_ultimo_mes)}</td>
			<td className="sic-border sic-text-color-blue  sic-align-right">{formatMonto(cliente.bec_serv_transferencias_alto_valor_ultimos_tres_meses)}</td>
		</tr>
		<tr>
			<td className="sic-border">Pago Proveedores</td>
			<td className="sic-border sic-text-color-blue  sic-align-right">{cliente.bec_serv_pago_proveedores_canal}</td>
			<td className="sic-border sic-text-color-blue  sic-align-right">{formatMonto(cliente.bec_serv_pago_proveedores_ultimo_mes)}</td>
			<td className="sic-border sic-text-color-blue  sic-align-right">{formatMonto(cliente.bec_serv_pago_proveedores_ultimos_tres_meses)}</td>
		</tr>
		<tr>
			<td className="sic-border">Pago Remuneraciones</td>
			<td className="sic-border sic-text-color-blue  sic-align-right">{cliente.bec_serv_pago_remuneraciones_canal}</td>
			<td className="sic-border sic-text-color-blue  sic-align-right">{formatMonto(cliente.bec_serv_pago_remuneraciones_ultimo_mes)}</td>
			<td className="sic-border sic-text-color-blue  sic-align-right">{formatMonto(cliente.bec_serv_pago_remuneraciones_ultimos_tres_meses)}</td>
		</tr>
		<tr>
			<td className="sic-border">Transbank</td>
			<td className="sic-border sic-text-color-blue  sic-align-right">{cliente.bec_serv_transbank_canal}</td>
			<td className="sic-border sic-text-color-blue  sic-align-right">{formatMonto(cliente.bec_serv_transbank_ultimo_mes)}</td>
			<td className="sic-border sic-text-color-blue  sic-align-right">{formatMonto(cliente.bec_serv_transbank_ultimos_tres_meses)}</td>
		</tr>
		<tr>
			<td className="sic-border">Recaudaci&oacute;n</td>
			<td className="sic-border sic-text-color-blue  sic-align-right">{cliente.bec_serv_recaudacion_canal}</td>
			<td className="sic-border sic-text-color-blue  sic-align-right">{formatMonto(cliente.bec_serv_recaudacion_ultimo_mes)}</td>
			<td className="sic-border sic-text-color-blue  sic-align-right">{formatMonto(cliente.bec_serv_recaudacion_ultimos_tres_meses)}</td>
		</tr>
		<tr>
			<td className="sic-border">Comex OPE (M USD)</td>
			<td className="sic-border sic-text-color-blue  sic-align-right">{cliente.bec_serv_comex_ope_canal}</td>
			<td className="sic-border sic-text-color-blue  sic-align-right">{formatMonto(cliente.bec_serv_comex_ope_ultimo_mes)}</td>
			<td className="sic-border sic-text-color-blue  sic-align-right">{formatMonto(cliente.bec_serv_comex_ope_ultimos_tres_meses)}</td>
		</tr>
		<tr>
			<td className="sic-border">Comex OPR (M USD)</td>
			<td className="sic-border sic-text-color-blue  sic-align-right">{cliente.bec_serv_comex_opr_canal}</td>
			<td className="sic-border sic-text-color-blue  sic-align-right">{formatMonto(cliente.bec_serv_comex_opr_ultimo_mes)}</td>
			<td className="sic-border sic-text-color-blue  sic-align-right">{formatMonto(cliente.bec_serv_comex_opr_ultimos_tres_meses)}</td>
		</tr>
		<tr>
			<td className="sic-border">Comex Carta Cr&eacute;dito (M USD)</td>
			<td className="sic-border sic-text-color-blue  sic-align-right">{cliente.bec_serv_comex_carta_credito_canal}</td>
			<td className="sic-border sic-text-color-blue  sic-align-right">{formatMonto(cliente.bec_serv_comex_carta_credito_ultimo_mes)}</td>
			<td className="sic-border sic-text-color-blue  sic-align-right">{formatMonto(cliente.bec_serv_comex_carta_credito_ultimos_tres_meses)}</td>
		</tr>
		<tr>
			<td className="sic-border">Compra Venta Divisa (M USD)</td>
			<td className="sic-border sic-text-color-blue  sic-align-right">{cliente.bec_serv_compra_venta_divisa_canal}</td>
			<td className="sic-border sic-text-color-blue  sic-align-right">{formatMonto(cliente.bec_serv_compra_venta_divisa_ultimo_mes)}</td>
			<td className="sic-border sic-text-color-blue  sic-align-right">{formatMonto(cliente.bec_serv_compra_venta_divisa_ultimos_tres_meses)}</td>
		</tr>
	</tbody>
</table>
    
</div>


        </Col>
    </Row>
</Grid>
</>

)
}

export default FichaClienteFicha;