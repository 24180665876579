import React from 'react';
import { useState, useEffect } from 'react'
import ModalDialogue from 'canvas-core-react/lib/ModalDialogue.js';
import {formatMonto, formatRut} from './SicUtilidades';
import PanelVencimientosCompl from './PanelVencimientosCompl'
import { useHttpGet as useHttp, MsgeLoading } from './useHttp';
import { resp_gestion } from '../data/get_dominios';
import {Link} from 'react-router-dom'
import {serviciosSIC} from '../data/const_servicios'
import '../Sic.scss';

const PanelVencimientos = ({bcas_id, gcia_id, plat_id, gest_id}) => {

  const k_venc_nkey = -1
  const k_tpk1_id_prod = "GEMP_V3_PRODUCTOS_GRUPO"

  const [clteRut, setClteRut] = useState('')
	const [clteRSocial, setClteRSocial] = useState('')
	const [producto, setProducto] = useState('')
	const [responsable, setResponsable] = useState('')

  const params_prod = `caso=${serviciosSIC.serv_select_tablas_x_params}&tpk1_id=${k_tpk1_id_prod}`
  const [panel_productos, isLoading_prod] = useHttp(params_prod)

  const params = `caso=${serviciosSIC.serv_panel_vencimientos}&bcas_id=${bcas_id}&gcia_id=${gcia_id}&plat_id=${plat_id}&gest_id=${gest_id}&ven_nkey=${k_venc_nkey}&clte_rut=${clteRut}&clte_rsocial=${clteRSocial}&producto=${producto}&responsable=${responsable}`
  const [post, isLoading] = useHttp(params)
  const panel_vencimientos = ((isLoading) ? [] : post)
  
	const [isModalVisible, setModalVisible] = useState(false);
	const [dataM, setDataM] = useState([]);

  const close = () => setModalVisible(false);
  	const open = (e) => {
		setDataM(panel_vencimientos[e.target.value - 1])
	  setModalVisible(true);
	}

  const mensajeCarga = () => {if (isLoading || isLoading_prod) return(<MsgeLoading/>)}

  const handleSubmit = (e) => {
		e.preventDefault()
		setClteRut(e.target.n_clte_rut.value.trim())
		setClteRSocial(e.target.n_clte_rsocial.value.trim())
		setProducto(e.target.n_producto.value.trim())
		setResponsable(e.target.n_responsable.value.trim())
    }

  useEffect (() => {
  } , [post] ) 

return(
  <> 
  {mensajeCarga()} 
  <form onSubmit={handleSubmit}>
    <div className="" >
    <table className="sic-table sic-xmedium">
          <tbody>
            <tr className="sic-border">

              <td className="PanelFiltros__label-3" width="10%"><div className="sic-align-left sic-margin-top">RUT</div></td>
              <td width="15%"><input className="sic-input-3" type="text" name="n_clte_rut" id="id_clte_rut"/></td>
              <td className="PanelFiltros__label-3" width="10%"><div className="sic-align-left sic-margin-top">Razón Social</div></td>
              <td width="15%"><input className="sic-input-3" type="text" name="n_clte_rsocial" id="id_clte_rsocial"/></td>
              <td className="sic-align-center" width="20%" colSpan="2"><button href="/vencimientos" className="sic-btn sic-border sic-border-color-gray-600 sic-hover-border-color-light-red sic-hover-text-color-dark-red" type="submit"> Consultar </button></td>

            </tr>

            <tr className="sic-border">

              <td className="PanelFiltros__label-3"><div className="sic-align-left sic-margin-top">Producto</div></td>
              <td className="sic-align-left">
                <select name="n_producto" id="id_producto" className="PanelFiltros__select">
                <option id="_PROD_TODO" key="_PROD_TODO" value="_TODO" className="PanelFiltros__select-option">(todos)</option>
                {
                    panel_productos.map(dproducto => (
                      <option id={dproducto.tpk2_id} key={dproducto.tpk2_id} value={dproducto.tpk2_id} className="PanelFiltros__select-option">
                      {dproducto.tabp_alias}
                    </option>
                ))
                }  
                </select>
              </td>

              <td className="PanelFiltros__label-3"><div className="sic-align-left sic-margin-top">Responsable</div></td>
              <td className="sic-align-left">
                <select name="n_responsable" id="id_responsable" className="PanelFiltros__select">
                {
                    resp_gestion.map(dresponsable => (
                        <option id={dresponsable.rges_id} key={dresponsable.rges_id} value={dresponsable.rges_id} className="PanelFiltros__select-option">
                          {dresponsable.rges_nombre}
                        </option>
                    ))
                }  
                </select>
              </td>

            </tr>


          </tbody>
        </table>

    </div>
  </form>

  <div className="sic-container sic-margin-top">

  <table className="sic-table sic-hoverable sic-striped">
      <colgroup></colgroup>
      <colgroup></colgroup>
      <colgroup></colgroup>
      <colgroup></colgroup>
      <colgroup></colgroup>
      <colgroup></colgroup>
      <colgroup></colgroup>
      <colgroup></colgroup>
      <colgroup></colgroup>
      <colgroup></colgroup>
      <colgroup></colgroup>
      <colgroup></colgroup>
      <colgroup></colgroup>
      <colgroup></colgroup>
      <colgroup></colgroup>
      <colgroup></colgroup>
      <colgroup></colgroup>
      <colgroup></colgroup>
      <thead>
        <tr className="sic-xmedium sic-color-dark-blue sic-border-bottom sic-background-color-thead">
          <th className="sic-border">&nbsp;</th>
          <th className="sic-border">Fecha<br/>Vencimiento</th>
          <th className="sic-border">Plazo<br/>días</th>
          <th className="sic-border">RUT</th>
          <th className="sic-border">Raz&oacute;n Social</th>
          <th className="sic-border">IG</th>
          <th className="sic-border">Producto (*)</th>
          <th className="sic-border">Moneda</th>
          <th className="sic-border">Amortización<br/>(miles)</th>
          <th className="sic-border">Cantidad<br/>Operaciones</th>
          <th className="sic-border">Fecha Venc.<br/>ART85</th>
          <th className="sic-border">Fecha Venc.<br/>UAF</th>
          <th className="sic-border">Fecha Venc.<br/>BASA</th>
          <th className="sic-border">Fecha Venc.<br/>Poderes</th>
          <th className="sic-border">Fecha Venc.<br/>Sociedad</th>
          <th className="sic-border">Nro. Operación</th>
          <th className="sic-border">Responsable</th>
          <th className="sic-border">Producto (tubo)</th>
        </tr>
      </thead>
	    <tbody>
      {
        panel_vencimientos.map((data, idx) => (
          <tr className="sic-medium" key={"venc_"+idx}>
            <td className="sic-align-center sic-border"><input className="sic-btn sic-padding-modal" type="button" onClick={open} value={idx + 1}/></td>
            <td className="sic-align-center sic-border">{data.venc_fecha_vencimiento_dma}</td>
            <td className="sic-align-center sic-border">{data.venc_fecha_vencimiento_plazo}</td>
            <td className="sic-align-center sic-border"><Link to={`/ficha/${data.venc_rut_cliente}`}  key={"venc_rut_"+idx+data.venc_rut_cliente} className="sic-anchor sic-nowrap">{formatRut(data.venc_rut_cliente)}</Link></td>
            <td className="sic-border" nowrap="{true}">{data.clte_razon_social}</td>
            <td className="sic-align-center sic-border">{data.bec_clte_rating}</td>
            <td className="sic-border" nowrap="{true}">{data.prod_notubo_tabp_alias}</td>
            <td className="sic-align-center sic-border">{data.venc_cod_moneda_sic}</td>
            <td className="sic-align-right sic-border">{formatMonto(data.venc_amortizacion)}</td>
            <td className="sic-align-center sic-border">{data.venc_cantidad_renovaciones}</td>
            <td className="sic-align-center sic-border">{data.venc_fecha_venc_art85_dma}</td>
            <td className="sic-align-center sic-border">{data.venc_fecha_venc_uaf_dma}</td>
            <td className="sic-align-center sic-border">{data.venc_fecha_venc_basa_dma}</td>
            <td className="sic-align-center sic-border">{data.venc_fecha_venc_poderes_dma}</td>
            <td className="sic-align-center sic-border">{data.venc_fecha_venc_sociedad_dma}</td>
            <td className="sic-align-center sic-border">{data.venc_nro_operacion}</td>
            <td className="sic-align-center sic-border">{data.responsable}</td>
            <td className="sic-border" nowrap="{true}">{data.prod_tabp_alias}</td>
          </tr>
        ))
      }
	    </tbody>
    </table>

  </div>

  <div>
  
  <ModalDialogue
    primaryButtonLabel="ok"
    secondaryButtonLabel="Cancelar"
    isModalVisible={isModalVisible}
    primaryAction={close}
    secondaryAction={close}
    setModalVisible={setModalVisible}
  >
<>
  <form>
  <div>
    <table className="sic-table-modal sic-small">
      <tbody>
      <tr>
        <td className="sic-color-dark-blue">RUT</td>
        <td>{formatRut(dataM.venc_rut_cliente)}</td>
        <td className="sic-color-dark-blue">Razon Social</td>
        <td>{dataM.clte_razon_social}</td>
      </tr>
      <tr>
        <td className="sic-color-dark-blue">IG</td>
        <td>{dataM.bec_clte_rating}</td>
        <td className="sic-color-dark-blue">Grupo Economico</td>
        <td>{dataM.venc_grupo_economico}</td>
      </tr>
      <tr>
        <td className="sic-color-dark-blue">Producto</td>
        <td>{dataM.prod_tabp_alias}</td>
        <td className="sic-color-dark-blue">Nro. Operacion</td>
        <td>{dataM.venc_nro_operacion}</td>
      </tr>
      <tr>
        <td className="sic-color-dark-blue">Fecha Vencimiento Op.</td>
        <td>{dataM.venc_fecha_vencimiento_dma}</td>
        <td className="sic-color-dark-blue">Resp. Gestion</td>
        <td>{dataM.venc_resp_gestion}</td>
      </tr>
      <tr>
        <td className="sic-color-dark-blue">Facility Tipo</td>
        <td>{dataM.venc_facility_tipo}</td>
        <td className="sic-color-dark-blue">Facility Nro</td>
        <td>{dataM.venc_facility_nro}</td>
      </tr>
      <tr>
        <td className="sic-color-dark-blue">Facility monto autorizado</td>
        <td>{formatMonto(dataM.venc_facility_monto_autorizado)}</td>
        <td className="sic-color-dark-blue">Facility monto disponible</td>
        <td>{formatMonto(dataM.venc_facility_monto_disponible)}</td>
      </tr>
      <tr>
        <td className="sic-color-dark-blue">Facility estado</td>
        <td>{dataM.venc_facility_estado}</td>
        <td className="sic-color-dark-blue">Fecha Venc. Facility</td>
        <td>{dataM.venc_facility_fecha_venc_dma}</td>
      </tr>
      <tr>
        <td className="sic-color-dark-blue">Subfacility tipo</td>
        <td>{dataM.venc_subfacility_tipo}</td>
        <td className="sic-color-dark-blue">Subfacility monto autorizado</td>
        <td>{formatMonto(dataM.venc_subfacility_monto_autorizado)}</td>
      </tr>
      <tr>
        <td className="sic-color-dark-blue">Subfacility monto disponible</td>
        <td>{formatMonto(dataM.venc_subfacility_monto_disponible)}</td>
        <td className="sic-color-dark-blue">Spread min. autorizado</td>
        <td>{formatMonto(dataM.venc_spread_minimo_autorizado,2)}</td>
      </tr>
      </tbody>
    </table>
    </div>

    <div className="sic-container sic-linea-separacion-1"/>

    <div className="sic-container">
    <table className="sic-table-modal">
      <tbody>
        <PanelVencimientosCompl texto="Moneda" valor={dataM.venc_cod_moneda_sic}></PanelVencimientosCompl>
        <PanelVencimientosCompl texto="Amortización" valor={dataM.venc_amortizacion} formato="0"></PanelVencimientosCompl>
        <PanelVencimientosCompl texto="Amortización Pesos" valor={dataM.venc_amortizacion_pesos} formato="0"></PanelVencimientosCompl>
        <PanelVencimientosCompl texto="Amortizacion USD" valor={dataM.venc_amortizacion_usd} formato="0"></PanelVencimientosCompl>
        <PanelVencimientosCompl texto="Intereses" valor={dataM.venc_intereses} formato="0"></PanelVencimientosCompl>
        <PanelVencimientosCompl texto="Estructura Préstamo" valor={dataM.venc_estructura_prestamo}></PanelVencimientosCompl>
        <PanelVencimientosCompl texto="Costo fondo oper original" valor={dataM.venc_costo_fondo_oper_original} formato="0"></PanelVencimientosCompl>
        <PanelVencimientosCompl texto="Spread original" valor={dataM.venc_spread_original} formato="2"></PanelVencimientosCompl>
        <PanelVencimientosCompl texto="Tasa original" valor={dataM.venc_tasa_orginal} formato="2"></PanelVencimientosCompl>
        <PanelVencimientosCompl texto="Cantidad Renovaciones" valor={dataM.venc_cantidad_renovaciones}></PanelVencimientosCompl>
        <PanelVencimientosCompl texto="Cuota" valor={dataM.venc_cuota} formato="0"></PanelVencimientosCompl>
        <PanelVencimientosCompl texto="Spread min. autorizado" valor={dataM.venc_spread_minimo_autorizado} formato="2"></PanelVencimientosCompl>
        <PanelVencimientosCompl texto="Comision orig. operacion" valor={dataM.venc_comision_original_operacion} formato="0"></PanelVencimientosCompl>
        <PanelVencimientosCompl texto="Fecha venc. ART85" valor={dataM.venc_fecha_venc_art85_dma}></PanelVencimientosCompl>
        <PanelVencimientosCompl texto="Fecha venc. UAF" valor={dataM.venc_fecha_venc_uaf_dma}></PanelVencimientosCompl>
        <PanelVencimientosCompl texto="Fecha venc. BASA" valor={dataM.venc_fecha_venc_basa_dma}></PanelVencimientosCompl>
        <PanelVencimientosCompl texto="Fecha venc. Poderes" valor={dataM.venc_fecha_venc_poderes_dma}></PanelVencimientosCompl>
        <PanelVencimientosCompl texto="Fecha venc. Sociedad" valor={dataM.venc_fecha_venc_sociedad_dma}></PanelVencimientosCompl>
      </tbody>
    </table>
    </div>

</form>
</>
  </ModalDialogue>
</div>

</>)
};

export default PanelVencimientos;