import React, { useState } from 'react';
import Grid from 'canvas-core-react/lib/Grid';
import Row from 'canvas-core-react/lib/Row';
import Col from 'canvas-core-react/lib/Column';
import Accordion from 'canvas-core-react/lib//Accordion';
import AccordionItem from 'canvas-core-react/lib//AccordionItem';
import {formatMonto, formatRut} from './SicUtilidades';
import { useHttpGet as useHttp, MsgeLoading } from './useHttp';
import {Link} from 'react-router-dom'
import {serviciosSIC} from '../data/const_servicios'
import '../Sic.scss';

const f_tenencia_ctacte = (dataf) => ( ( (dataf.bec_prod_cuenta_corriente_peso_tenencia === 'S') || (dataf.bec_prod_cuenta_corriente_dolar_tenencia === 'S') || (dataf.bec_prod_cuenta_corriente_euro_tenencia === 'S') ) ? 'S' : '' )
//const f_tenencia_credcomercial = (dataf) => ( ( (dataf.bec_prod_credito_comercial_mn_cp_tenencia === 'S') || (dataf.bec_prod_credito_comercial_mn_lp_tenencia === 'S') ) ? 'S' : '' ) 
const f_tenencia_leasing = (dataf) => ( ( (dataf.bec_prod_leasing_inmobiliario_tenencia === 'S') || (dataf.bec_prod_leasing_mobiliario_tenencia === 'S') ) ? 'S' : '' )
const f_tenencia_factoring = (dataf) => ( ( (dataf.bec_prod_factoring_financiero_tenencia === 'S') || (dataf.bec_prod_factoring_tradicional_tenencia === 'S') ) ? 'S' : '' )
const f_tenencia_inversion = (dataf) => ( ( (dataf.clte_inve_dap_tenencia === 'S') || (dataf.clte_inve_fondos_mutuos_tenencia === 'S') ) ? 'S' : '' )
const f_tenencia_comex = (dataf) => ( ( (dataf.bec_serv_comex_ope_tenencia === 'S') || (dataf.bec_serv_comex_opr_tenencia === 'S') || (dataf.bec_serv_comex_carta_credito_tenencia === 'S') || (dataf.bec_serv_compra_venta_divisa_tenencia === 'S') ) ? 'S' : '' )


const PanelClientes = ({bcas_id, gcia_id, plat_id, gest_id}) => {

	const [clteRut, setClteRut] = useState('')
	const [clteRSocial, setClteRSocial] = useState('')

	const params = `caso=${serviciosSIC.serv_panel_clientes}&bcas_id=${bcas_id}&gcia_id=${gcia_id}&plat_id=${plat_id}&gest_id=${gest_id}&clte_rut=${clteRut}&clte_rsocial=${clteRSocial}`
	const [post, isLoading] = useHttp(params)
	const panel_clientes = ((isLoading) ? [] : post)

	const mensajeCarga = () => {if (isLoading) return(<MsgeLoading/>)}

	const handleSubmit = (e) => {
		e.preventDefault()
		setClteRut(e.target.n_clte_rut.value.trim())
		setClteRSocial(e.target.n_clte_rsocial.value.trim())
    }

//	console.log('Clientes: ' + params)
	return(
<>
<form onSubmit={handleSubmit}>
  <div className="PanelFiltros__card" >
    <Grid className="PanelFiltros__block" fluid={true} >

      <Row className="PanelFiltros__rows" >
        <Col className="PanelFiltros__cols" xs={4} sm={8} md={2} lg={2}>
		  <div><label className="PanelFiltros__label" >RUT</label></div>
          <input className="sic-input-3" type="text" name="n_clte_rut" id="id_clte_rut"/>
        </Col>
        <Col className="PanelFiltros__cols" xs={4} sm={8} md={2} lg={2}>
		  <div><label className="PanelFiltros__label" >Razón Social</label></div>
          <input className="sic-input-3" type="text" name="n_clte_rsocial" id="id_clte_rsocial"/>
        </Col>
         <Col xs={2} sm={4} md={8} lg={8}>
            <button href="/clientes" className="sic-btn sic-border sic-border-color-gray-600 sic-hover-border-color-light-red sic-hover-text-color-dark-red sic-margin" type="submit"> Consultar </button>
        </Col>
        <Col xs={1} sm={1} md={1} lg={1}>
    		 {mensajeCarga()}
        </Col>
      </Row>

      <Row className="PanelFiltros__rows" >
        <Col xs={4} sm={8} md={12} lg={12}>
          <Accordion>
            <AccordionItem title="(otros filtros)"  className="sic-text-color-blue sic-small">
              <div className="sic-align-center">

				<table className="sic-table sic-border">
					<tbody>
						<tr className="sic-align-center">
							<td className="sic-padding" width="25%">
								<table className="sic-table-2 sic-border">
									<thead>
										<tr>
											<td className="sic-xmedium sic-color-dark-blue sic-border sic-background-color-thead" colSpan="2"> 
												Productos Base
											</td>
										</tr>
									</thead>
									<tbody>
										<tr className="sic-row-tabla-otros-filtros">
											<td>
												Cuenta Corriente Peso
											</td>
											<td>
												<input className="sic-check" name="cb_bec_prod_cuenta_corriente_peso_tenencia_s" value="S" type="checkbox" id="pbs_0"/>
											</td>
										</tr>

										<tr className="sic-row-tabla-otros-filtros">
											<td>
												Cuenta Corriente D&oacute;lar
											</td>
											<td>
												<input className="sic-check" name="cb_bec_prod_cuenta_corriente_dolar_tenencia_s" value="S" type="checkbox" id="pbs_1"/>
											</td>
										</tr>

										<tr>
											<td>
												Cuenta Corriente Euro
											</td>
											<td>
												<input className="sic-check" name="cb_bec_prod_cuenta_corriente_euro_tenencia_s" value="S" type="checkbox" id="pbs_2"/>
											</td>
										</tr>

										<tr className="sic-row-tabla-otros-filtros">
											<td>
												L&iacute;nea de Cr&eacute;dito
											</td>
											<td>
												<input className="sic-check" name="cb_bec_prod_linea_credito_tenencia_s" value="S" type="checkbox" id="pbs_3"/>
											</td>
										</tr>

										<tr className="sic-row-tabla-otros-filtros">
											<td>
												Tarjeta de Cr&eacute;dito
											</td>
											<td>
												<input className="sic-check" name="cb_bec_prod_tarjeta_credito_tenencia_s" value="S" type="checkbox" id="pbs_4"/>
											</td>
										</tr>

										<tr className="sic-row-tabla-otros-filtros-2">
											<td colspan="2">

												<table className="sic-table-2">
													<tbody>
														<tr className="sic-row-tabla-otros-filtros">
															<td colspan="2">
																Cuenta Corriente (saldo medio)
															</td>
														</tr>

														<tr>
															<td>
																<span className="sic-small">desde</span><br/>
																<input className="sic-input" type="text" name="t_ccte_spro_ini" id="t_ccte_spro_ini" size="6" /><br/>
															</td>
															<td>
																<span className="sic-small">hasta</span><br/>
																<input className="sic-input" type="text" name="t_ccte_spro_fin" id="t_ccte_spro_fin" size="6" />
															</td>
														</tr>
													</tbody>
												</table>
							
											</td>
										</tr>

										<tr className="sic-row-tabla-otros-filtros">
											<td colspan="2">

											<table className="sic-table">
												<tbody>
													<tr>
														<td colspan="2">
															Aduana Importaci&oacute;n (M USD)
														</td>
													</tr>

													<tr>
														<td>
															<span className="sic-small">desde</span><br/>
															<input className="sic-input" type="text" name="t_adua_impo_ini" id="t_adua_impo_ini" size="6" /><br/>
														</td>
														<td>
															<span className="sic-small">hasta</span><br/>
															<input className="sic-input" type="text" name="t_adua_impo_fin" id="t_adua_impo_fin" size="6" />
														</td>
													</tr>
												</tbody>
											</table>
							
											</td>
										</tr>

										<tr className="sic-row-tabla-otros-filtros">
											<td colspan="2">

											<table className="sic-table">
												<tbody>
													<tr>
														<td colspan="2">
															Aduana Exportaci&oacute;n (M USD)
														</td>
													</tr>

													<tr>
														<td>
															<span className="sic-small">desde</span><br/>
															<input className="sic-input" type="text" name="t_adua_expo_ini" id="t_adua_expo_ini" size="6" /><br/>
														</td>
														<td>
															<span className="sic-small">hasta</span><br/>
															<input className="sic-input" type="text" name="t_adua_expo_fin" id="t_adua_expo_fin" size="6" />
														</td>
													</tr>
												</tbody>
											</table>
							
											</td>
										</tr>

									</tbody>
								</table>
							</td>
							<td className="sic-padding" width="25%">
								<table className="sic-table-2 sic-border">
									<thead>
										<tr>
											<td className="sic-xmedium sic-color-dark-blue sic-border sic-background-color-thead" colSpan="2"> 
												Productos
											</td>
										</tr>
									</thead>
									<tbody>
										<tr className="sic-row-tabla-otros-filtros">
											<td>
												Credito Comercial MN
											</td>
											<td>
												<input className="sic-check" name="cb_bec_prod_credito_comercial_mn_cp_tenencia_s" value="S" type="checkbox" id="pvs_0"/>
											</td>
										</tr>

										<tr className="sic-row-tabla-otros-filtros">
											<td>
												Credito Comercial ME
											</td>
											<td>
												<input className="sic-check" name="cb_bec_prod_credito_comercial_me_tenencia_s" value="S" type="checkbox" id="pvs_4"/>
											</td>
										</tr>

										<tr className="sic-row-tabla-otros-filtros">
											<td>
												Facility Rotativa
											</td>
											<td>
												<input className="sic-check" name="cb_bec_marg_rota_tenencia_s" value="S" type="checkbox" id="mr_1"/>
											</td>
										</tr>

										<tr className="sic-row-tabla-otros-filtros">
											<td>
												PAE
											</td>
											<td>
												<input className="sic-check" name="cb_bec_prod_pae_tenencia_s" value="S" type="checkbox" id="pvs_3"/>
											</td>
										</tr>
										<tr className="sic-row-tabla-otros-filtros">
											<td>
												Fin. Import<br/>(C.Cred/F.Con)
											</td>
											<td>
												<input className="sic-check" name="cb_bec_prod_financiamiento_import_tenencia_s" value="S" type="checkbox" id="pvs_5"/>
											</td>
										</tr>
										<tr className="sic-row-tabla-otros-filtros">
											<td>
												Leasing
											</td>
											<td>
												<input className="sic-check" name="cb_bec_prod_leasing_mobiliario_tenencia_s" value="S" type="checkbox" id="pvs_6"/>
											</td>
										</tr>
										<tr className="sic-row-tabla-otros-filtros">
											<td>
												Factoring
											</td>
											<td>
												<input className="sic-check" name="cb_bec_prod_factoring_financiero_tenencia_s" value="S" type="checkbox" id="pvs_8"/>
											</td>
										</tr>

										<tr className="sic-row-tabla-otros-filtros">
											<td>
												Boletas Garantia
											</td>
											<td>
												<input className="sic-check" name="cb_bec_prod_boletas_garantia_tenencia_s" value="S" type="checkbox" id="pvs_11"/>
											</td>
										</tr>
										<tr className="sic-row-tabla-otros-filtros">
											<td>
												Carta Credito
											</td>
											<td>
												<input className="sic-check" name="cb_bec_prod_carta_credito_tenencia_s" value="S" type="checkbox" id="pvs_12"/>
											</td>
										</tr>
										<tr className="sic-row-tabla-otros-filtros">
											<td>
												Stand By
											</td>
											<td>
												<input className="sic-check" name="cb_bec_prod_stand_by_tenencia_s" value="S" type="checkbox" id="pvs_13"/>
											</td>
										</tr>
									</tbody>
								</table>
							</td>
							<td className="sic-padding" width="25%">
								<table className="sic-table-2 sic-border">
									<thead>
										<tr>
											<td className="sic-xmedium sic-color-dark-blue sic-border sic-background-color-thead" colSpan="2"> 
												Servicios
											</td>
										</tr>
									</thead>
									<tbody>
										<tr className="sic-row-tabla-otros-filtros">
											<td>
												Scotia Web
											</td>
											<td>
												<input className="sic-check" name="cb_bec_serv_bbva_net_cash_tenencia_s" value="S" type="checkbox" id="pts_0"/>
											</td>
										</tr>

										<tr className="sic-row-tabla-otros-filtros">
											<td>
												Web Comex
											</td>
											<td>
												<input className="sic-check" name="cb_bec_serv_web_comex_tenencia_s" value="S" type="checkbox" id="pts_3"/>
											</td>
										</tr>

										<tr className="sic-row-tabla-otros-filtros">
											<td>
												FX
											</td>
											<td>
												<input className="sic-check" name="cb_bec_serv_fx_tenencia_s" value="S" type="checkbox" id="pts_5"/>
											</td>
										</tr>

										<tr className="sic-row-tabla-otros-filtros">
											<td>
												Impuestos
											</td>
											<td>
												<input className="sic-check" name="cb_bec_serv_impuestos_tenencia_s" value="S" type="checkbox" id="pts_6"/>
											</td>
										</tr>
										<tr className="sic-row-tabla-otros-filtros">
											<td>
												Transferencias Masivas
											</td>
											<td>
												<input className="sic-check" name="cb_bec_serv_transferencias_alto_valor_tenencia_s" value="S" type="checkbox" id="pts_7"/>
											</td>
										</tr>
										<tr className="sic-row-tabla-otros-filtros">
											<td>
												Transferencia Alto Valor
											</td>
											<td>
												<input className="sic-check" name="cb_bec_serv_transferencias_masivas_tenencia_s" value="S" type="checkbox" id="pts_8"/>
											</td>
										</tr>
										<tr className="sic-row-tabla-otros-filtros">
											<td>
												Pagos Proveedores
											</td>
											<td>
												<input className="sic-check" name="cb_bec_serv_pago_proveedores_tenencia_s" value="S" type="checkbox" id="pts_9"/>
											</td>
										</tr>
										<tr className="sic-row-tabla-otros-filtros">
											<td>
												Pagos Remuneraciones
											</td>
											<td>
												<input className="sic-check" name="cb_bec_serv_pago_remuneraciones_tenencia_s" value="S" type="checkbox" id="pts_10"/>
											</td>
										</tr>
										<tr className="sic-row-tabla-otros-filtros">
											<td>
												Transbank
											</td>
											<td>
												<input className="sic-check" name="cb_bec_serv_transbank_tenencia_s" value="S" type="checkbox" id="pts_11"/>
												</td>
										</tr>
										<tr className="sic-row-tabla-otros-filtros">
											<td>
												Recaudacion
											</td>
											<td>
												<input className="sic-check" name="cb_bec_serv_recaudacion_tenencia_s" value="S" type="checkbox" id="pts_12"/>
											</td>
										</tr>
										<tr className="sic-row-tabla-otros-filtros">
											<td>
												Comex OPE
											</td>
											<td>
												<input className="sic-check" name="cb_bec_serv_comex_ope_tenencia_s" value="S" type="checkbox" id="pts_13"/>
											</td>
										</tr>
										<tr className="sic-row-tabla-otros-filtros">
											<td>
												Comex OPR
											</td>
											<td>
												<input className="sic-check" name="cb_bec_serv_comex_opr_tenencia_s" value="S" type="checkbox" id="pts_14"/>
											</td>
										</tr>
										<tr className="sic-row-tabla-otros-filtros">
											<td>
												Compra Venta Divisa
											</td>
											<td>
												<input className="sic-check" name="cb_bec_serv_compra_venta_divisa_tenencia_s" value="S" type="checkbox" id="pts_16"/>
											</td>
										</tr>
									</tbody>
								</table>
							</td>
							<td className="sic-padding" width="25%">
								<table className="sic-table-2 sic-border">
									<thead>
										<tr>
											<td className="sic-xmedium sic-color-dark-blue sic-border sic-background-color-thead" colSpan="2"> 
												Facility
											</td>
										</tr>
									</thead>
									<tbody>
										<tr className="sic-row-tabla-otros-filtros">
											<td>
												Facility Rotativa
											</td>
											<td>
												<input className="sic-check" name="cb_marg_rota_s" value="S" type="checkbox" id="ics_0"/>
											</td>
										</tr>
										<tr className="sic-row-tabla-otros-filtros">
											<td>
												Cr&eacute;dito en moneda local
											</td>
											<td>
												<input className="sic-check" name="cb_marg_rota_cred_ml_s" value="S" type="checkbox" id="ics_1"/>
											</td>
										</tr>
										<tr className="sic-row-tabla-otros-filtros">
											<td>
												Crédito moneda extr.
											</td>
											<td>
												<input className="sic-check" name="cb_marg_rota_oper_me_s" value="S" type="checkbox" id="ics_3"/>
											</td>
										</tr>
										<tr className="sic-row-tabla-otros-filtros">
											<td>
												Comex
											</td>
											<td>
												<input className="sic-check" name="cb_marg_rota_comex_s" value="S" type="checkbox" id="ics_2"/>
											</td>
										</tr>
										<tr className="sic-row-tabla-otros-filtros">
											<td>
												Boleta de garant&iacute;a
											</td>
											<td>
												<input className="sic-check" name="cb_marg_rota_bole_gtia_tecn_s" value="S" type="checkbox" id="ics_4"/>
											</td>
										</tr>
										<tr className="sic-row-tabla-otros-filtros">
											<td>
												Confirming banco
											</td>
											<td>
												<input className="sic-check" name="cb_marg_rota_conf_banco_s" value="S" type="checkbox" id="ics_8"/>
											</td>
										</tr>
										<tr className="sic-row-tabla-otros-filtros">
											<td>
												Factoring
											</td>
											<td>
												<input className="sic-check" name="cb_marg_rota_fact_fina_s" value="S" type="checkbox" id="ics_10"/>
											</td>
										</tr>
									</tbody>
								</table>
								<br/>
								<table className="sic-table-2 sic-border">
									<thead>
										<tr>
											<td className="sic-xmedium sic-color-dark-blue sic-border sic-background-color-thead" colSpan="2"> 
												Clasificaci&oacute;n
											</td>
										</tr>
									</thead>
									<tbody>
										<tr className="sic-row-tabla-otros-filtros">
											<td> 
												ROA
											</td>
											<td> 
												<input name="cb_bec_clte_roa_empresa_s" value="sin_roa" type="checkbox" id="roa_0"/>
												<label htmlFor="roa_0"><span>Sin ROA</span></label><br/>
												<input name="cb_bec_clte_roa_empresa_0" value="roa_0" type="checkbox" id="roa_1"/>
												<label htmlFor="roa_1"><span>Menor 1%</span></label><br/>
												<input name="cb_bec_clte_roa_empresa_1" value="roa_1" type="checkbox" id="roa_2"/>
												<label htmlFor="roa_2"><span>1% - 2%</span></label><br/>
												<input name="cb_bec_clte_roa_empresa_2" value="roa_2" type="checkbox" id="roa_3"/>
												<label htmlFor="roa_3"><span>2% - 3%</span></label><br/>
												<input name="cb_bec_clte_roa_empresa_3" value="roa_3" type="checkbox" id="roa_4"/>
												<label htmlFor="roa_4"><span>Mayor 3%</span></label>
											</td>
										</tr>
									</tbody>
								</table>
							</td>
  						</tr>
					</tbody>
  				</table>

              </div>
            </AccordionItem>
          </Accordion>
        </Col>
      </Row>

    </Grid>
  </div>
  </form>
  <div className="sic-container sic-margin-top">

    <table className="sic-table sic-hoverable sic-striped">

      <colgroup colSpan="1"></colgroup>
      <colgroup colSpan="3"></colgroup>
      <colgroup colSpan="11"></colgroup>
      <colgroup colSpan="2"></colgroup>
      <colgroup colSpan="6"></colgroup>

      <thead>
        <tr className="sic-large sic-color-dark-blue ">
          <th className="sic-border"></th>
          <th colSpan="2" className="sic-align-center sic-border">Cliente</th>
          <th colSpan="8"className="sic-align-center sic-border">Productos</th>
          <th colSpan="6"className="sic-align-center sic-border">Inf. SBIF (valores en M$)</th>
        </tr>
        </thead>

      <thead>
        <tr className="sic-medium sic-color-dark-blue sic-background-color-thead">
          <th className="sic-border"></th>
          <th className="sic-border sic-xmedium">RUT</th>
          <th className="sic-border sic-xmedium">Raz&oacute;n Social</th>
          <th className="sic-border">Cuenta<br/>Corriente</th>
          <th className="sic-border">L&iacute;nea de<br/>Cr&eacute;dito</th>
          <th className="sic-border">Facility</th>
          <th className="sic-border">COMEX</th>
          <th className="sic-border">Leasing</th>
          <th className="sic-border">Factoring</th>
          <th className="sic-border">Tarjeta de<br/>Cr&eacute;dito</th>
          <th className="sic-border">Inversiones</th>

          <th className="sic-border">Deterioro</th>
          <th className="sic-border">Clasificaci&oacute;n<br/>SBIF</th>
          <th className="sic-border">SBIF M$<br/>Vigente</th>
          <th className="sic-border">SBIF M$<br/>Leasing</th>
          <th className="sic-border">SBIF M$<br/>Contingente</th>
          <th className="sic-border">SBIF M$<br/>Comercial</th>
        </tr>
      </thead>

      <tbody>
      {
        panel_clientes.map((cliente, idx) => (
          <tr className="sic-medium" key={"clte_"+idx}>
            <td className="sic-align-center sic-border">{idx + 1}</td>
            <td className="sic-align-center sic-border"><Link to={`/ficha/${cliente.clte_rut}`} key={"clte_rut_"+idx+cliente.clte_rut} className="sic-anchor sic-nowrap">{formatRut(cliente.clte_rut)}</Link></td>
            <td className="sic-border"  nowrap="{true}">{cliente.clte_razon_social}</td>
            <td className="sic-align-center sic-border">{f_tenencia_ctacte(cliente)}</td>
            <td className="sic-align-center sic-border">{cliente.bec_prod_linea_credito_tenencia}</td>
            <td className="sic-align-center sic-border">{cliente.bec_prod_margen_rotativo_tenencia}</td>
            <td className="sic-align-center sic-border">{f_tenencia_comex(cliente)}</td>
            <td className="sic-align-center sic-border">{f_tenencia_leasing(cliente)}</td>
            <td className="sic-align-center sic-border">{f_tenencia_factoring(cliente)}</td>
            <td className="sic-align-center sic-border">{cliente.bec_prod_tarjeta_credito_tenencia}</td>
            <td className="sic-align-center sic-border">{f_tenencia_inversion(cliente)}</td>

            <td className="sic-border" nowrap="{true}">{cliente.clte_deterioro}</td>
            <td className="sic-align-center sic-border">{cliente.clte_clasificacion_sbif}</td>
            <td className="sic-align-right sic-border">{formatMonto(cliente.sfin_directa_deuda_total_sbif)}</td>
            <td className="sic-align-right sic-border">{formatMonto(cliente.sfin_directa_deuda_leasing_sbif)}</td>
            <td className="sic-align-right sic-border">{formatMonto(cliente.sfin_directa_deuda_contingente_sbif)}</td>
            <td className="sic-align-center sic-border">{formatMonto(cliente.sfin_directa_deuda_comercial_sbif)}</td>
          </tr>
        ))
      }

      </tbody>
    </table>

  </div>

  </>
  )
}

export default PanelClientes;