import React, { useState } from 'react';
import {formatRut} from './SicUtilidades';
import { useHttpGet as useHttp, MsgeLoading } from './useHttp';
import { resp_gestion } from '../data/get_dominios';
import {Link} from 'react-router-dom'
import {serviciosSIC} from '../data/const_servicios'
import '../Sic.scss';

const PanelVisitas = ({bcas_id, gcia_id, plat_id, gest_id}) => {

  const k_tpk1_id_prod = "GEMP_V3_PRODUCTOS_GRUPO"

  const [clteRut, setClteRut] = useState('')
  const [clteRSocial, setClteRSocial] = useState('')
	const [producto, setProducto] = useState('')
	const [responsable, setResponsable] = useState('')

  const params_prod = `caso=${serviciosSIC.serv_select_tablas_x_params}&tpk1_id=${k_tpk1_id_prod}`
  const [panel_productos, isLoading_prod] = useHttp(params_prod)

  const params = `caso=${serviciosSIC.serv_panel_visitas}&bcas_id=${bcas_id}&gcia_id=${gcia_id}&plat_id=${plat_id}&gest_id=${gest_id}&clte_rut=${clteRut}&clte_rsocial=${clteRSocial}&producto=${producto}&responsable=${responsable}`
  const [post, isLoading] = useHttp(params)

  const panel_visitas = ((isLoading) ? [] : post)

  const mensajeCarga = () => {if (isLoading|| isLoading_prod) return(<MsgeLoading/>)}

  const handleSubmit = (e) => {
		e.preventDefault()
		setClteRut(e.target.n_clte_rut.value.trim())
		setClteRSocial(e.target.n_clte_rsocial.value.trim())
		setProducto(e.target.n_producto.value.trim())
		setResponsable(e.target.n_responsable.value.trim())
  }

  return(
    <>

      {mensajeCarga()}

      <div className="">

      <form onSubmit={handleSubmit}>
  
        <table className="sic-table sic-xmedium">
          <tbody>
            <tr className="sic-border">

              <td className="PanelFiltros__label-3" width="10%"><div className="sic-align-left sic-margin-top">RUT</div></td>
              <td width="15%"><input className="sic-input-3" type="text" name="n_clte_rut" id="id_clte_rut"/></td>
              <td className="PanelFiltros__label-3" width="10%"><div className="sic-align-left sic-margin-top">Razón Social</div></td>
              <td width="15%"><input className="sic-input-3" type="text" name="n_clte_rsocial" id="id_clte_rsocial"/></td>
              <td className="sic-align-center" width="20%" colSpan="2"><button href="/visitas" className="sic-btn sic-border sic-border-color-gray-600 sic-hover-border-color-light-red sic-hover-text-color-dark-red" type="submit"> Consultar </button></td>

            </tr>

            <tr className="sic-border">

              <td className="PanelFiltros__label-3"><div className="sic-align-left sic-margin-top">Producto</div></td>
              <td className="sic-align-left">
                <select name="n_producto" id="id_producto" className="PanelFiltros__select">
                <option id="_PROD_TODO" key="_PROD_TODO" value="_TODO" className="PanelFiltros__select-option">(todos)</option>
                {
                    panel_productos.map(dproducto => (
                      <option id={dproducto.tpk2_id} key={dproducto.tpk2_id} value={dproducto.tpk2_id} className="PanelFiltros__select-option">
                        {dproducto.tabp_alias}
                      </option>
                ))
                }  
                </select>
              </td>

              <td className="PanelFiltros__label-3"><div className="sic-align-left sic-margin-top">Responsable</div></td>
              <td className="sic-align-left">
                <select name="n_responsable" id="id_responsable" className="PanelFiltros__select">
                {
                    resp_gestion.map(dresponsable => (
                        <option id={dresponsable.rges_id} key={dresponsable.rges_id} value={dresponsable.rges_id} className="PanelFiltros__select-option">
                          {dresponsable.rges_nombre}
                        </option>
                    ))
                }  
                </select>
              </td>
            </tr>


          </tbody>
        </table>
        </form>

      </div>

  <div className="sic-container sic-margin-top">

    <table className="sic-table sic-hoverable sic-striped">
  
      <colgroup></colgroup>
      <colgroup></colgroup>
      <colgroup></colgroup>
      <colgroup></colgroup>
      <colgroup></colgroup>
      <colgroup></colgroup>
      <colgroup></colgroup>
      <colgroup></colgroup>
      <thead>
        <tr className="sic-xmedium sic-color-dark-blue sic-background-color-thead">
          <th className="sic-border">&nbsp;</th>
          <th className="sic-border">Fecha<br/>Visita</th>
          <th className="sic-border">Días</th>
          <th className="sic-border">RUT</th>
          <th className="sic-border">Raz&oacute;n Social</th>
          <th className="sic-border">Tipo Cliente</th>
          <th className="sic-border">Producto</th>
          <th className="sic-border">Responsable</th>
          <th className="sic-border">Título</th>
          <th className="sic-border">Descripción</th>
        </tr>
      </thead>

      <tbody>
      {
        panel_visitas.map((data, idx) => (
          <tr className="sic-medium" key={"accs_"+idx}>
            <td className="sic-align-center sic-border">{idx + 1}</td>
            <td className={`sic-align-center sic-border (${(data.agen_fecha_prox_ctto_plazo < 0) ? " sic-text-color-red " : ""} `}>{data.visi_fecha_dma}</td>
            <td className="sic-align-center sic-border">{data.visi_fecha_plazo}</td>
            <td className="sic-align-center sic-border sic-nowrap"><Link to={`/ficha/${data.clte_rut}`} key={"accs_rut_"+idx+data.clte_rut} className="sic-anchor">{formatRut(data.clte_rut)}</Link></td>
            <td className="sic-border">{data.clte_razon_social}</td>
            <td className="sic-border sic-align-center">{data.visi_tipo_cliente}</td>
            <td className="sic-border">{data.prod_tabp_alias}</td>
            <td className="sic-border">{data.responsable}</td>
            <td className="sic-border">{data.visi_titulo}</td>
            <td className="sic-border">{data.visi_descripcion}</td>
          </tr>
        ))
      }
	    </tbody>
    </table>
  </div>

  </>
  )
 };

export default PanelVisitas;