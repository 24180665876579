import React from 'react';
import Tabs from 'canvas-core-react/lib/Tabs';
import Tab from 'canvas-core-react/lib/Tab';
import FichaClienteFicha from './FichaClienteFicha';
import FichaClienteContacto from './FichaClienteContacto';
import FichaClienteRentabilidad from './FichaClienteRentabilidad';
import FichaClienteAcciones from './FichaClienteAcciones';
import {formatRut, formatAntiguedad, formatMonto} from './SicUtilidades';
import { useHttpGet as useHttp, MsgeLoading } from './useHttp';
import '../Sic.scss';

const FichaCliente = ({match}) => {

  const rut = match.params.rut

  const [post, isLoading] = useHttp(`caso=serv_select_clientes_atributos_x_rut&clte_rut=${rut}`)
  const cliente = ((isLoading) ? [] : (post[0] ) )
  
  const [postSitFinanciera, isLoadingSitFinanciera] = useHttp(`caso=serv_select_clientes_sitfinanciera_x_rut&clte_rut=${rut}`)
  const clienteSitFinanciera = ((isLoadingSitFinanciera) ? [] : (postSitFinanciera[0] ? postSitFinanciera[0] : []) )

  const [postContacto, isLoadingContacto] = useHttp(`caso=serv_select_clientes_contacto_x_rut&clte_rut=${rut}`)
  const clienteContacto = ((isLoadingContacto) ? [] : (postContacto ? postContacto : []) )

  //serv_select_agenda_historia_x_rut
  //serv_select_agenda_acciones_x_rut
  //serv_select_clientes_rentabilidad_x_rut
  //serv_select_agenda_etapas_visitas_x_params

  const mensajeCarga = () => {if (isLoading) return(<MsgeLoading/>)}

  if (!rut) return ('')

  return(
  <>
  <div className="sic-container sic-background-color-container-ficha">
    <div className="sic-container-centered sic-card  sic-margin-top sic-margin-bottom-2">

      {mensajeCarga()}

      <div className="sic-container-centered sic-background-color-white sic-padding">

        <table className="sic-table sic-xmedium">
          <tbody>
            <tr className="sic-border-bottom sic-border-color-gray-500">
              <td className="sic-color-blue sic-background-color-gray-300">Raz&oacute;n Social</td>
              <td>{cliente.clte_razon_social}</td>
              <td className="sic-color-blue sic-background-color-gray-300">RUT</td>
              <td>{formatRut(cliente.clte_rut)}</td>
            </tr>
            <tr className="sic-border-bottom sic-border-color-gray-500">
              <td className="sic-color-blue sic-background-color-gray-300">Rubro</td>
              <td>{cliente.clte_rubro}</td>
              <td className="sic-color-blue sic-background-color-gray-300">Antig&uuml;edad</td>
              <td>{formatAntiguedad(cliente.clte_dias_ingreso_antiguedad,1)}</td>
            </tr>
            <tr className="sic-border-bottom sic-border-color-gray-500">
              <td className="sic-color-blue sic-background-color-gray-300">Ejec. Cuenta</td>
              <td>{cliente.ejecutivo}</td>
              <td className="sic-color-blue sic-background-color-gray-300">Contactabilidad (días):</td>
              <td>{formatMonto(cliente.clte_contactabilidad,0)}</td>
            </tr>
          </tbody>
        </table>

      </div>

      <div>

        <Tabs className="sic-TabsFicha">

          <Tab label="FICHA CLIENTE">
            <div className="sic-container sic-margin-top sic-separador-color-gray-300 "/>
            <div className="sic-container sic-margin-top sic-background-color-white sic-border-top" key="ficha_cliente">
              <FichaClienteFicha cliente={cliente} clienteSitFinanciera={clienteSitFinanciera}/>
            </div>
          </Tab>

          <Tab label="CONTACTOS" key="ficha_contactos">
            <div className="sic-container sic-margin-top sic-separador-color-gray-300 "></div>
            <div className="sic-container sic-margin-top">
              <FichaClienteContacto clienteContacto={clienteContacto}/>
            </div>
          </Tab>

          <Tab label="RENTABILIDAD" key="ficha_rentabilidad">
            <div className="sic-container sic-margin-top sic-separador-color-gray-300 "></div>
            <div className="sic-container sic-margin-top">
              <FichaClienteRentabilidad/>
            </div>
          </Tab>

          <Tab label="VISITAS" key="ficha_visitas">
            <div className="sic-container sic-margin-top sic-separador-color-gray-300 "></div>
            <div className="sic-container sic-margin-top">
            </div>
          </Tab>

          <Tab label="ACCIONES COMERCIALES" key="ficha_acciones_comerciales">
            <div className="sic-container sic-margin-top sic-separador-color-gray-300 "></div>
            <div className="sic-container sic-margin-top">
              <FichaClienteAcciones/>
            </div>
          </Tab>

        </Tabs>
      </div>
    </div>
</div>
</>
)
};

export default FichaCliente;