export const noc_deuda = [
    {
      "ns_nkey": 557,
      "tnoc_rutcli": 797469300,
      "ns_periodo": 201902,
      "ns_dcons": 0,
      "ns_dcom": 1,
      "ns_dhipot": 0,
      "ns_lindisp": 15000,
      "ns_dindirecta": 0,
      "ns_numinst": 1,
      "ns_cfinanciera": 1,
      "ns_disponible1": "",
      "ns_disponible2": " ",
      "ns_ddirvgte": 1,
      "ns_leasing": 0,
      "ns_dcommonextr": 0
    },
    {
      "ns_nkey": 563,
      "tnoc_rutcli": 797469300,
      "ns_periodo": 201901,
      "ns_dcons": 0,
      "ns_dcom": 0,
      "ns_dhipot": 0,
      "ns_lindisp": 15000,
      "ns_dindirecta": 0,
      "ns_numinst": 0,
      "ns_cfinanciera": 0,
      "ns_disponible1": "",
      "ns_disponible2": " ",
      "ns_ddirvgte": 0,
      "ns_leasing": 0,
      "ns_dcommonextr": 0
    },
    {
      "ns_nkey": 567,
      "tnoc_rutcli": 797469300,
      "ns_periodo": 201812,
      "ns_dcons": 0,
      "ns_dcom": 86,
      "ns_dhipot": 0,
      "ns_lindisp": 14915,
      "ns_dindirecta": 0,
      "ns_numinst": 1,
      "ns_cfinanciera": 86,
      "ns_disponible1": "",
      "ns_disponible2": " ",
      "ns_ddirvgte": 86,
      "ns_leasing": 0,
      "ns_dcommonextr": 0
    },
    {
      "ns_nkey": 570,
      "tnoc_rutcli": 797469300,
      "ns_periodo": 201811,
      "ns_dcons": 0,
      "ns_dcom": 5150,
      "ns_dhipot": 0,
      "ns_lindisp": 10000,
      "ns_dindirecta": 3,
      "ns_numinst": 1,
      "ns_cfinanciera": 5153,
      "ns_disponible1": "",
      "ns_disponible2": " ",
      "ns_ddirvgte": 5150,
      "ns_leasing": 0,
      "ns_dcommonextr": 0
    },
    {
      "ns_nkey": 571,
      "tnoc_rutcli": 797469300,
      "ns_periodo": 201810,
      "ns_dcons": 0,
      "ns_dcom": 5050,
      "ns_dhipot": 0,
      "ns_lindisp": 6093,
      "ns_dindirecta": 3916,
      "ns_numinst": 2,
      "ns_cfinanciera": 8966,
      "ns_disponible1": "",
      "ns_disponible2": " ",
      "ns_ddirvgte": 5050,
      "ns_leasing": 0,
      "ns_dcommonextr": 0
    },
    {
      "ns_nkey": 573,
      "tnoc_rutcli": 797469300,
      "ns_periodo": 201809,
      "ns_dcons": 0,
      "ns_dcom": 9186,
      "ns_dhipot": 0,
      "ns_lindisp": 5870,
      "ns_dindirecta": 0,
      "ns_numinst": 2,
      "ns_cfinanciera": 9186,
      "ns_disponible1": "",
      "ns_disponible2": " ",
      "ns_ddirvgte": 9186,
      "ns_leasing": 0,
      "ns_dcommonextr": 0
    }
   ]