import React from "react";
import SicGraficoTorta from "./SicGraficoTorta"
import SicGraficoLinea from "./SicGraficoLinea"
import SicGraficoBarraH from "./SicGraficoBarraH"
import Grid from 'canvas-core-react/lib/Grid';
import Row from 'canvas-core-react/lib/Row';
import Col from 'canvas-core-react/lib/Column';
import { useHttpGet as useHttp, MsgeLoading } from './useHttp';
import {serviciosSIC} from '../data/const_servicios'
import '../Sic.scss';

const PanelDiagnostico = ({bcas_id, gcia_id, plat_id, gest_id}) => {

  const grafico_torta_1_atributo_roa = "bec_clte_roa_empresa"
  const grafico_torta_1_atributo_tabla = "ROA"
  const grafico_torta_2_atributo_vinculacion = "bec_clte_vinculacion_cliente"
  const grafico_torta_3_atributo_segmento = "bec_clte_segmento_venta"
  
  const grafico_linea_1_concepto = "inversion_crediticia_cartera"
  const grafico_linea_2_concepto = "rentabilidad_neta_cartera"
  const grafico_linea_3_concepto = "total_recursos_gestionados"
  
  const grafico_penetracion_productos = "PROD"
  const grafico_penetracion_servicios = "SERV"
  
  const paramsServRoa = `caso=${serviciosSIC.serv_panel_diagnostico_grafico_conteo}&bcas_id=${bcas_id}&gcia_id=${gcia_id}&plat_id=${plat_id}&gest_id=${gest_id}&atributo_id=${grafico_torta_1_atributo_roa}&atributo_tabla=${grafico_torta_1_atributo_tabla}`
  const [postRoa, isLoadingRoa] = useHttp(paramsServRoa)
  const dataRoa = ((isLoadingRoa) ? [] : postRoa)
  
  const paramsServVinculacion = `caso=${serviciosSIC.serv_panel_diagnostico_grafico_conteo}&bcas_id=${bcas_id}&gcia_id=${gcia_id}&plat_id=${plat_id}&gest_id=${gest_id}&atributo_id=${grafico_torta_2_atributo_vinculacion}`
  const [postVinculacion, isLoadingVinculacion] = useHttp(paramsServVinculacion)
  const dataConteo2 = ((isLoadingVinculacion) ? [] : postVinculacion)
  
  const paramsServSegmento = `caso=${serviciosSIC.serv_panel_diagnostico_grafico_conteo}&bcas_id=${bcas_id}&gcia_id=${gcia_id}&plat_id=${plat_id}&gest_id=${gest_id}&atributo_id=${grafico_torta_3_atributo_segmento}`
  const [postSegmento, isLoadingConteo3] = useHttp(paramsServSegmento)
  const dataSegmento = ((isLoadingConteo3) ? [] : postSegmento)

  const paramsEvolucion1 = `caso=${serviciosSIC.serv_panel_diagnostico_grafico_evolucion}&bcas_id=${bcas_id}&gcia_id=${gcia_id}&plat_id=${plat_id}&gest_id=${gest_id}&concepto1=${grafico_linea_1_concepto}`
  const [postEvolucion1, isLoadingEvolucion1] = useHttp(paramsEvolucion1)
  const dataEvolucion1 = ((isLoadingEvolucion1) ? [] : postEvolucion1)

  const paramsEvolucion2 = `caso=${serviciosSIC.serv_panel_diagnostico_grafico_evolucion}&bcas_id=${bcas_id}&gcia_id=${gcia_id}&plat_id=${plat_id}&gest_id=${gest_id}&concepto1=${grafico_linea_2_concepto}`
  const [postEvolucion2, isLoadingEvolucion2] = useHttp(paramsEvolucion2)
  const dataEvolucion2 = ((isLoadingEvolucion2) ? [] : postEvolucion2)

  const paramsEvolucion3 = `caso=${serviciosSIC.serv_panel_diagnostico_grafico_evolucion}&bcas_id=${bcas_id}&gcia_id=${gcia_id}&plat_id=${plat_id}&gest_id=${gest_id}&concepto1=${grafico_linea_3_concepto}`
  const [postEvolucion3, isLoadingEvolucion3] = useHttp(paramsEvolucion3)
  const dataEvolucion3 = ((isLoadingEvolucion3) ? [] : postEvolucion3)

  const paramsProductos = `caso=${serviciosSIC.serv_panel_diagnostico_grafico_penetracion}&bcas_id=${bcas_id}&gcia_id=${gcia_id}&plat_id=${plat_id}&gest_id=${gest_id}&penetracion=${grafico_penetracion_productos}`
  const [postProductos, isLoadingProductos] = useHttp(paramsProductos)
  const dataProductos = ((isLoadingProductos) ? [] : postProductos)

  const paramsServicios = `caso=${serviciosSIC.serv_panel_diagnostico_grafico_penetracion}&bcas_id=${bcas_id}&gcia_id=${gcia_id}&plat_id=${plat_id}&gest_id=${gest_id}&penetracion=${grafico_penetracion_servicios}`
  const [postServicios, isLoadingServicios] = useHttp(paramsServicios)
  const dataServicios = ((isLoadingServicios) ? [] : postServicios)

  const paramsRoa = {
    title : "EMPRESAS POR ROA",
    subtitle : "Cantidad de Clientes"
  }
  
  const paramsVinculacion = {
    title : "VINCULACION DE CLIENTE",
    subtitle : "Cantidad de Clientes"
  }
  
  const paramsSegmento = {
    title : "SEGMENTO DE VENTA",
    subtitle : "Cantidad de Clientes"
  }
  
  const paramsEvolInversion = {
    title : "Evolución total inversión crediticia de la cartera",
    subtitle : "",
    xaxis_title : "PERIODO",
    yaxis_title : "MILES $"
  }
  
  const paramsEvolBAI = {
    title : "Evolución BAI de la cartera",
    subtitle : "",
    xaxis_title : "PERIODO",
    yaxis_title : "MILES $"
  }
  
  const paramsEvolRecursos = {
    title : "Evolución total recursos gestionados de la cartera",
    subtitle : "",
    xaxis_title : "PERIODO",
    yaxis_title : "MILES $"
  }
  
  const paramsPenetracionProductos = {
    title : "PENETRACIÓN PRODUCTOS",
    subtitle : "",
    xaxis_title : "PRODUCTOS",
    yaxis_title : "PENETRACION (%)"
  }
  
  const paramsPenetracionServicios = {
    title : "PENETRACIÓN SERVICIOS",
    subtitle : "",
    xaxis_title : "SERVICIOS",
    yaxis_title : "PENETRACION (%)"
  }
  
  const mensajeCarga = () => {if (isLoadingRoa || isLoadingVinculacion || isLoadingConteo3 || isLoadingEvolucion1 || isLoadingEvolucion2 || isLoadingEvolucion3) return(<MsgeLoading/>)}

  return(
  <>

  {mensajeCarga()} 

  <Grid >

    <Row className="Control__contenedor">
        <Col lg={4} md={4} sm={3} xs={1}>

          <SicGraficoTorta params={paramsRoa} datap={dataRoa}/>
        </Col>
        <Col lg={4} md={4} sm={3} xs={1}>
          <SicGraficoTorta params={paramsVinculacion} datap={dataConteo2}/>
        </Col>
        <Col lg={4} md={4} sm={2} xs={1}>
          <SicGraficoTorta params={paramsSegmento} datap={dataSegmento}/>
        </Col>
    </Row>

    <Row className="Control__contenedor">
        <Col lg={4} md={4} sm={2} xs={1}>
          <SicGraficoLinea params={paramsEvolInversion} datap={dataEvolucion1}/>
        </Col>
        <Col lg={4} md={4} sm={2} xs={1}>
          <SicGraficoLinea params={paramsEvolBAI} datap={dataEvolucion2}/>
        </Col>
        <Col lg={4} md={4} sm={2} xs={1}>
          <SicGraficoLinea params={paramsEvolRecursos} datap={dataEvolucion3}/>
        </Col>
    </Row>

    <Row className="Control__contenedor">
        <Col lg={6} md={6} sm={4} xs={2}>
          <SicGraficoBarraH params={paramsPenetracionProductos} datap={dataProductos}/>
        </Col>
        <Col lg={6} md={6} sm={2} xs={2}>
          <SicGraficoBarraH params={paramsPenetracionServicios} datap={dataServicios}/>
        </Col>
    </Row>

  </Grid>
  </>
  )
}

export default PanelDiagnostico;
