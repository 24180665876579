import React from 'react';
import { useState, useEffect } from 'react'
import spinner from '../components/spinner.svg';

export const MsgeLoading = () => { 
    return(<div className="sic-loader"><img src={spinner} className="sic-margin sic-xlarge sic-spin" alt="Cargando..."/></div>)
}

export const useHttpGet = (qstring) => {

    const [dataServ, setDataServ] = useState([])
    const [isLoading, setIsLoading] = useState(true)

    const pers_id =  localStorage.getItem('pers_id')

    const home = "https://sic-desa.defacto.cl/sic_servicios_v1.php"

    useEffect(() => {
        const getData = async () => {

            const uri = `${home}?${qstring}&pers_id=${pers_id}`
            setIsLoading(true)
console.log(uri)
            const res = await fetch(uri, { 
            method: 'GET', 
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            }, 
            });
            const data = await res.json()
            setDataServ(data)
            setIsLoading(false)
        }
        getData()
    }, [qstring, pers_id]);

    return [dataServ, isLoading]
}

export const useHttpPost = (qstring) => {

    const [dataServ, setDataServ] = useState([])
    const [isLoading, setIsLoading] = useState(true)
  
    const home = "https://sic-desa.defacto.cl/sic_servicios_v1.php"

    useEffect(() => {
        const getData = async () => {
            setIsLoading(true)

            const res = await fetch(home, { 
            method: 'POST', 
            headers: {
                'Authorization': 'Bearer xxx',
                'Content-Type': 'application/x-www-form-urlencoded'
            }, 
            body: qstring
            });
            const data = await res.json()
            setDataServ(data)
            setIsLoading(false)
        }
        getData()
    }, [qstring]);

    return [dataServ, isLoading]
  }
  
  export const useHttp0 = (url) => {

    const home = "https://sic-desa.defacto.cl/sic_servicios_v1.php?"
  //    const home = "http://10.235.27.90/sic/sic_servicios_v1.php?"
      const [dataServ, setDataServ] = useState([])
      const [isLoading, setIsLoading] = useState(true)
  
      useEffect(() => {
          const getData = async () => {
              setIsLoading(true)
              const res = await fetch(home+url)
              const data = await res.json()
              setDataServ(data)
              setIsLoading(false)
          }
          getData()
      }, [url]);
  
      return [dataServ, isLoading]
  }
  