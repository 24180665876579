import React from 'react';
import { useState, useEffect } from 'react'
import ModalDialogue from 'canvas-core-react/lib/ModalDialogue.js';
import {formatMonto, formatRut} from './SicUtilidades';
import { useHttpGet as useHttp, MsgeLoading } from './useHttp';
import { resp_gestion } from '../data/get_dominios';
import {Link} from 'react-router-dom'
import {serviciosSIC} from '../data/const_servicios'
import '../Sic.scss';

const PanelAlertas = ({bcas_id, gcia_id, plat_id, gest_id}) => {

	const k_aler_nkey = -1
	const k_aler_situacion = "_TODO"
	const k_tpk1_id_tipo_alerta = "GEMP_V3_TIPO_ALERTAS"
	const k_tpk1_id_prod = "GEMP_V3_PRODUCTOS_GRUPO"

	const [clteRut, setClteRut] = useState('')
	const [clteRSocial, setClteRSocial] = useState('')
	const [producto, setProducto] = useState('')
	const [responsable, setResponsable] = useState('')
	const [tipoAlerta, setTipoAlerta] = useState('')

	const params_prod = `caso=${serviciosSIC.serv_select_tablas_x_params}&tpk1_id=${k_tpk1_id_prod}`
	const [panel_productos, isLoading_prod] = useHttp(params_prod)

	const params_tipo = `caso=${serviciosSIC.serv_select_tablas_x_params}&tpk1_id=${k_tpk1_id_tipo_alerta}`
	const [tipo_alertas, isLoading_tipo] = useHttp(params_tipo)
  
	const params = `caso=${serviciosSIC.serv_panel_alertas}&bcas_id=${bcas_id}&gcia_id=${gcia_id}&plat_id=${plat_id}&gest_id=${gest_id}&aler_nkey=${k_aler_nkey}&clte_rut=${clteRut}&clte_rsocial=${clteRSocial}&producto=${producto}&responsable=${responsable}&tipo_alerta=${tipoAlerta}&situacion=${k_aler_situacion}`
	const [post, isLoading] = useHttp(params)
	const panel_alertas = ((isLoading) ? [] : post)

	const [isModalVisible, setModalVisible] = useState(false);
	const [dataM, setDataM] = useState([]);

	const close = () => setModalVisible(false);
  	const open = (e) => {
		setDataM(panel_alertas[e.target.value - 1])
	  	setModalVisible(true);
	}

	const mensajeCarga = () => {if (isLoading || isLoading_prod || isLoading_tipo) return(<MsgeLoading/>)}

	const handleSubmit = (e) => {
		e.preventDefault()
		setClteRut(e.target.n_clte_rut.value.trim())
		setClteRSocial(e.target.n_clte_rsocial.value.trim())
		setProducto(e.target.n_producto.value.trim())
		setResponsable(e.target.n_responsable.value.trim())
		setTipoAlerta(e.target.n_tipo_alerta.value.trim())
    }

	useEffect (() => {
	} , [post] ) 
  
	return(
	<>
    {mensajeCarga()} 

	<div className="" >
		<form onSubmit={handleSubmit}>

        <table className="sic-table sic-xmedium">
          <tbody>
            <tr className="sic-border">

              <td className="PanelFiltros__label-3" width="10%"><div className="sic-align-left sic-margin-top">RUT</div></td>
              <td width="15%"><input className="sic-input-3" type="text" name="n_clte_rut" id="id_clte_rut"/></td>
              <td className="PanelFiltros__label-3" width="10%"><div className="sic-align-left sic-margin-top">Razón Social</div></td>
              <td width="15%"><input className="sic-input-3" type="text" name="n_clte_rsocial" id="id_clte_rsocial"/></td>
              <td className="sic-align-center" width="20%" colSpan="2"><button href="/alertas" className="sic-btn sic-border sic-border-color-gray-600 sic-hover-border-color-light-red sic-hover-text-color-dark-red" type="submit"> Consultar </button></td>

            </tr>

            <tr className="sic-border">

              <td className="PanelFiltros__label-3"><div className="sic-align-left sic-margin-top">Producto</div></td>
              <td className="sic-align-left">
                <select name="n_producto" id="id_producto" className="PanelFiltros__select">
                <option id="_PROD_TODO" key="_PROD_TODO" value="_TODO" className="PanelFiltros__select-option">(todos)</option>
                {
                    panel_productos.map(dproducto => (
                        <option id={dproducto.tpk2_id} key={dproducto.tpk2_id} value={dproducto.tpk2_id} className="PanelFiltros__select-option">
                          {dproducto.tabp_alias}
                        </option>
                    ))
                }  
                </select>
              </td>

              <td className="PanelFiltros__label-3"><div className="sic-align-left sic-margin-top">Responsable</div></td>
              <td className="sic-align-left">
                <select name="n_responsable" id="id_responsable" className="PanelFiltros__select">
                {
                    resp_gestion.map(dresponsable => (
                        <option id={dresponsable.rges_id} key={dresponsable.rges_id} value={dresponsable.rges_id} className="PanelFiltros__select-option">
                          {dresponsable.rges_nombre}
                        </option>
                    ))
                }  
                </select>
              </td>

              <td className="PanelFiltros__label-3"><div className="sic-align-left sic-margin-top">Tipo Alerta</div></td>
              <td className="sic-align-left">
				  <select name="n_tipo_alerta" id="id_tipo_alerta" className="PanelFiltros__select">
					  <option id="_TIPO_TODO" key="_TIPO_TODO" value="_TODO" className="PanelFiltros__select-option">(todos)</option>
					{
						tipo_alertas.map(alerta => (
							<option id={alerta.tpk2_id} key={alerta.tpk2_id} value={alerta.tpk2_id} className="PanelFiltros__select-option">
								{alerta.tabp_alias}
							</option>
						))
					}  
					</select>
				</td>

            </tr>


          </tbody>
        </table>

		</form>

		</div>

  <div className="sic-container sic-margin-top">

    <table className="sic-table sic-hoverable sic-striped">

	  <colgroup></colgroup>
      <colgroup></colgroup>
      <colgroup></colgroup>
      <colgroup></colgroup>
      <colgroup></colgroup>
      <colgroup></colgroup>
      <colgroup></colgroup>
      <colgroup></colgroup>
      <colgroup></colgroup>
      <colgroup></colgroup>
      <colgroup></colgroup>
      <colgroup></colgroup>
      <colgroup></colgroup>
      <thead>
        <tr className="sic-xmedium sic-color-dark-blue sic-background-color-thead">
          <th className="sic-border">&nbsp;</th>
          <th className="sic-border">Fecha<br/>Gesti&oacute;n</th>
          <th className="sic-border">Plazo<br/>días</th>
          <th className="sic-border">RUT</th>
          <th className="sic-border">Raz&oacute;n Social</th>
          <th className="sic-border">Subtipo Alerta</th>
          <th className="sic-border">Vcmto Doc</th>
          <th className="sic-border">Nro. Operaci&oacute;n</th>
          <th className="sic-border">Moneda</th>
          <th className="sic-border">Monto</th>
          <th className="sic-border">D&iacute;as</th>
          <th className="sic-border">Observaciones</th>
          <th className="sic-border">Producto (*)</th>
          <th className="sic-border">Responsable</th>
          <th className="sic-border">Producto (tubo)</th>
        </tr>
      </thead>
	    <tbody>
      {
        panel_alertas.map((data, idx) => (
          <tr className="sic-medium" key={"data_"+idx}>
            <td className="sic-align-center sic-border"><input className="sic-btn sic-padding-modal" type="button" onClick={open} value={idx + 1}/></td>
            <td className="sic-align-center sic-border">{data.aler_gestion_fecha_dma}</td>
            <td className="sic-align-center sic-border">{data.aler_gestion_fecha_plazo}</td>
            <td className="sic-border"><Link to={`/ficha/${data.aler_rut_cliente}`} key={"aler_rut_"+idx+data.aler_rut_cliente} className="sic-anchor sic-nowrap">{formatRut(data.aler_rut_cliente)}</Link></td>
            <td className="sic-border" nowrap="{true}">{data.clte_razon_soc}</td>
            <td className="sic-border" nowrap="{true}">{data.niv2_tabp_nombre}</td>
            <td className="sic-border">{data.aler_fecha_vcmto_doc_dma}</td>
            <td className="sic-align-center sic-border">{data.aler_nro_operacion}</td>
            <td className="sic-align-center sic-border">{data.aler_cod_moneda_sic}</td>
            <td className="sic-align-right sic-border">{formatMonto(data.aler_monto)}</td>
            <td className="sic-align-center sic-border">{data.aler_dias}</td>
            <td className="sic-align-left sic-border" nowrap="{true}">{data.aler_observaciones}</td>
			<td className="sic-align-left sic-border" nowrap="{true}">{data.prod_notubo_tabp_alias}</td>
			<td className="sic-align-left sic-border" nowrap="{true}">{data.prod_tabp_alias}</td>
            <td className="sic-align-left sic-border">{data.responsable}</td>
          </tr>
        ))
      }
	    </tbody>
    </table>
  </div>

  <div>
  
  <ModalDialogue
    primaryButtonLabel="Grabar"
    secondaryButtonLabel="Cancelar"
    isModalVisible={isModalVisible}
    primaryAction={close}
    secondaryAction={close}
    setModalVisible={setModalVisible}
  >

	<>
  <form>
	<div>
		<table className="sic-table-modal">
			<tbody>
				<tr>
					<td className="sic-color-dark-blue">RUT</td>
					<td>{formatRut(dataM.aler_rut_cliente)}</td>
					<td className="sic-color-dark-blue">Fecha Gestion</td>
					<td>{dataM.aler_gestion_fecha_dma}</td>
				</tr>
				<tr>
					<td className="sic-color-dark-blue">Razon Social</td>
					<td colSpan="3">{dataM.clte_razon_soc}</td>
				</tr>
				<tr>
					<td className="sic-color-dark-blue">Grupo Economico</td>
					<td>{dataM.aler_grupo_economico}</td>
					<td className="sic-color-dark-blue">Sector Economico</td>
					<td>{dataM.aler_sector_economico}</td>
				</tr>
			</tbody>
		</table>
	</div>
	<div>
    <table className="sic-table-modal">
			<tbody>
				<tr>
					<td className="sic-color-dark-blue" width="15%">Tipo Alerta</td>
					<td width="35%">{dataM.niv1_tabp_nombre}</td>
					<td className="sic-color-dark-blue" width="15%">Subtipo Alerta</td>
					<td width="35%">{dataM.niv2_tabp_nombre}</td>
				</tr>
				<tr>
					<td className="sic-color-dark-blue">Producto</td>
					<td>{dataM.prod_tabp_alias}</td>
					<td className="sic-color-dark-blue">Resp. Gestion</td>
					<td>{dataM.aler_resp_gestion}</td>
				</tr>
				<tr>
					<td className="sic-color-dark-blue">Fecha vcmto doc</td>
					<td>{dataM.aler_fecha_vcmto_doc_dma}</td>
					<td className="sic-color-dark-blue">Nro Operacion</td>
					<td>{dataM.aler_nro_operacion}</td>
				</tr>
				<tr>
					<td className="sic-color-dark-blue">Moneda</td>
					<td>{dataM.aler_cod_moneda_sic}</td>
					<td className="sic-color-dark-blue">Monto</td>
					<td>{formatMonto(dataM.aler_monto)}</td>
				</tr>
				<tr>
					<td className="sic-color-dark-blue">Monto 2</td>
					<td>{formatMonto(dataM.aler_monto_2)}</td>
					<td className="sic-color-dark-blue">Monto 3</td>
					<td>{formatMonto(dataM.aler_monto_3)}</td>
				</tr>
				<tr>
					<td className="sic-color-dark-blue">Total Colocaciones</td>
					<td>{dataM.aler_total_colocaciones}</td>
					<td className="sic-color-dark-blue">Tramo</td>
					<td>{dataM.aler_tramo}</td>
				</tr>
				<tr>
					<td className="sic-color-dark-blue">Dias</td>
					<td>{dataM.aler_dias}</td>
					<td className="sic-color-dark-blue">Numero Operaciones</td>
					<td>{dataM.aler_numero_op}</td>
				</tr>
				<tr>
					<td className="sic-color-dark-blue">Vigencia</td>
					<td>{dataM.aler_vigente}</td>
					<td className="sic-color-dark-blue">Sobregiro</td>
					<td>{dataM.aler_sobregiro}</td>
				</tr>
				<tr>
					<td className="sic-color-dark-blue">Cant. Protestos</td>
					<td>{dataM.aler_cant_protestos}</td>
					<td className="sic-color-dark-blue">Monto Protestos</td>
					<td>{formatMonto(dataM.aler_monto_protesto)}</td>
				</tr>
				<tr>
					<td className="sic-color-dark-blue">Observaciones</td>
					<td colSpan="3">{dataM.aler_observaciones}</td>
				</tr>
				<tr>
					<td className="sic-color-dark-blue">Comentarios Ejecutivo</td>
					<td colSpan="3">?</td>
				</tr>
			</tbody>
		</table>
	</div>
	<div>
    <table className="sic-table sic-align-center sic-padding sic-xmedium">
			<tbody>
				<tr>
					<td>
						<input className="sic-radio" type="radio" name="r_estado_alerta" value="en_gestion" id="en_gestion"/>
						<label className="sic-color-dark-blue" forhtml="en_gestion">En Gestión</label>
					</td>
					<td>
						<input className="sic-radio" type="radio" name="r_estado_alerta" value="cerrado_con_exito" id="cerrado_con_exito"/>
						<label className="sic-color-dark-blue" forhtml="cerrado_con_exito">Cerrada con éxito</label>
					</td>
					<td>
						<input className="sic-radio" type="radio" name="r_estado_alerta" value="cerrado_sin_exito" id="cerrado_sin_exito"/>
						<label className="sic-color-dark-blue" forh	tml="cerrado_sin_exito">Cerrada sin éxito</label>
					</td>
				</tr>
			</tbody>
		</table>
	</div>

</form>
</>

</ModalDialogue>
</div>

  </>)
};

export default PanelAlertas;