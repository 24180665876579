import React, { useState, useEffect } from 'react';
import Row from 'canvas-core-react/lib/Row';
import Col from 'canvas-core-react/lib/Column';
import Logo from 'canvas-core-react/lib/LogoFlyingS';
import TextHeadline from 'canvas-core-react/lib/TextHeadline';
import { useHttpGet as useHttp, MsgeLoading } from './useHttp';
import {serviciosSIC} from '../data/const_servicios';
import '../Sic.scss';

const SicHeader = ({history}) => {

  const f_pers_caso = "x_todo"
  const f_pers_vigente = "S"
  const f_pers_contar = "N"
  const f_pers_rows = -1

  const [pers_id, setPers] = useState('')

  const params = `caso=${serviciosSIC.serv_select_cacc_personas_x_params}&pers_caso=${f_pers_caso}&pers_vigente=${f_pers_vigente}&contar=${f_pers_contar}&rows=${f_pers_rows}&pers_id_rut_ne=&pers_clave=&pers_mail=&pers_filtro=`
  const [post, isLoading] = useHttp(params)
  const dataUsuarios = ((isLoading) ? [] : post)

  const handleUsuario = (e) => {
    setPers(e.target.value)
    localStorage.setItem('pers_id', e.target.value);
    history.push(`/panel`)
  }

  const mensajeCarga = () => {if (isLoading) return(<MsgeLoading/>)}

  useEffect (() => {
//    console.log('Usuario: ' + pers_id )
  } , [pers_id] ) 
    
return(
<>
  {mensajeCarga()} 
  <div className='Header__container'>
    <Row justifyContent='center'>
      <Col lg={3} md={2} sm={1} xs={1}>
        <div className='Header__logo'>
          <Logo id={'logoId'} size={36} />
        </div>
      </Col>
      <Col lg={6} md={4} sm={4} xs={1}>
        <TextHeadline component="h1" className='Header__sic'>SIC</TextHeadline>
      </Col>
      <Col lg={3} md={2} sm={3} xs={2} className='Header__nombre'>
        <div className="sic-align-center">
        <select name="age" id="age-selector" className="sic-select" onChange={handleUsuario} value={localStorage.getItem('pers_id')}>
          <option id="_SELE" key="_usua_sele" value="_SELE">(seleccione)</option>
          {
            dataUsuarios.map( (usuario, index) => (
              <option id={usuario.pers_id} key={usuario.pers_id} value={usuario.pers_id}>
                {usuario.pers_nbre_apat_amat + ' // '+ usuario.perf_perf_nomb}
              </option>
            ))
          }  
        </select>
        </div>

      </Col>
    </Row>
  </div>
  </>
)
}

export default SicHeader;