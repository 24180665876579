import React, { useState } from 'react';
import {formatRut} from './SicUtilidades';
import { useHttpGet as useHttp, MsgeLoading } from './useHttp';
//import { useHttpTest, MsgeLoading } from './useHttp';
import { resp_gestion } from '../data/get_dominios';
import { panel_situacion } from '../data/get_dominios';
import {Link} from 'react-router-dom'
import {serviciosSIC} from '../data/const_servicios'
import '../Sic.scss';

const PanelAcciones = ({bcas_id, gcia_id, plat_id, gest_id}) => {

  const k_etapa = "_ABIERTAS"
  const k_tipoCliente = "_TODO"
  const k_tpk1_id_prod = "GEMP_V3_PRODUCTOS_GRUPO"
  const k_tpk1_id_moti = "GEMP_V3_MOTIVOS"

  const [clteRut, setClteRut] = useState('')
	const [clteRSocial, setClteRSocial] = useState('')
	const [producto, setProducto] = useState('')
	const [motivo, setMotivo] = useState('')
	const [situacion, setSituacion] = useState('')
  const [responsable, setResponsable] = useState('')

  const params_prod = `caso=${serviciosSIC.serv_select_tablas_x_params}&tpk1_id=${k_tpk1_id_prod}`
  const [panel_productos, isLoading_prod] = useHttp(params_prod)

  const params_moti = `caso=${serviciosSIC.serv_select_tablas_x_params}&tpk1_id=${k_tpk1_id_moti}`
  const [panel_motivos, isLoading_moti] = useHttp(params_moti)

  const params = `caso=${serviciosSIC.serv_panel_acciones}&bcas_id=${bcas_id}&gcia_id=${gcia_id}&plat_id=${plat_id}&gest_id=${gest_id}&clte_rut=${clteRut}&clte_rsocial=${clteRSocial}&producto=${producto}&responsable=${responsable}&motivo=${motivo}&situacion=${situacion}&tipocliente=${k_tipoCliente}&etapa=${k_etapa}`
  const [post, isLoading] = useHttp(params)
//  const [post, isLoading] = useHttpTest(`https://agendas-ist.apps.stg.azr-cc-pcf.cloud.bns/v1/agendas?ps_pers_id=cmoris&ps_bcas_id=banca_grandes_empresas&ps_gcia_id=gcia_inmobiliaria1&ps_plat_id=0124&ps_ejec_id=7659&ps_clte_rut=&ps_clte_razon_social=INMOBILIARIA&ps_tipo_clte=&ps_tipo_noc=&ps_etap=&ps_moti=&ps_prod=&ps_count=N&pi_dias=-1&ps_rege=&ps_cons_a=&pi_offset=0&pi_rows=100`)
  const dataAcciones = ((isLoading) ? [] : post)

//  const dataAcciones = dataAcciones_test.data
	const mensajeCarga = () => {if (isLoading || isLoading_prod || isLoading_moti) return(<MsgeLoading/>)}
  const handleSubmit = (e) => {
		e.preventDefault()
		setClteRut(e.target.n_clte_rut.value.trim())
		setClteRSocial(e.target.n_clte_rsocial.value.trim())
		setProducto(e.target.n_producto.value.trim())
		setResponsable(e.target.n_responsable.value.trim())
		setMotivo(e.target.n_motivo.value.trim())
		setSituacion(e.target.n_situacion.value.trim())
  }

  return(
    <>
    {mensajeCarga()} 

    <div className="">

      <form onSubmit={handleSubmit}>

        <table className="sic-table sic-xmedium">
          <tbody>

            <tr className="sic-border">
              <td className="PanelFiltros__label-3" width="10%"><div className="sic-align-left sic-margin-top">RUT</div></td>
              <td width="15%"><input className="sic-input-3" type="text" name="n_clte_rut" id="id_clte_rut"/></td>
              <td className="PanelFiltros__label-3" width="10%"><div className="sic-align-left sic-margin-top">Razón Social</div></td>
              <td width="15%"><input className="sic-input-3" type="text" name="n_clte_rsocial" id="id_clte_rsocial"/></td>
              <td className="sic-align-center" width="20%" colSpan="2"><button href="/acciones" className="sic-btn sic-border sic-border-color-gray-600 sic-hover-border-color-light-red sic-hover-text-color-dark-red" type="submit"> Consultar </button></td>
              <td className="sic-align-center" width="20%" colSpan="2"><button href="/prospectos" className="sic-btn sic-border sic-border-color-gray-600 sic-hover-border-color-light-red sic-hover-text-color-dark-red" type="submit"> Agendar Prospecto </button></td>
            </tr>

            <tr className="sic-border">

              <td className="PanelFiltros__label-3"><div className="sic-align-left sic-margin-top">Producto</div></td>
              <td className="sic-align-left">
                <select name="n_producto" id="id_producto" className="PanelFiltros__select">
                <option id="_PROD_TODO" key="_PROD_TODO" value="_TODO" className="PanelFiltros__select-option">(todos)</option>
                {
                    panel_productos.map(dproducto => (
                        <option id={dproducto.tpk2_id} key={dproducto.tpk2_id} value={dproducto.tpk2_id} className="PanelFiltros__select-option">
                          {dproducto.tabp_alias}
                        </option>
                    ))
                }  
                </select>
              </td>

              <td className="PanelFiltros__label-3"><div className="sic-align-left sic-margin-top">Responsable</div></td>
              <td className="sic-align-left">
                <select name="n_responsable" id="id_responsable" className="PanelFiltros__select">
                {
                    resp_gestion.map(dresponsable => (
                        <option id={dresponsable.rges_id} key={dresponsable.rges_id} value={dresponsable.rges_id} className="PanelFiltros__select-option">
                          {dresponsable.rges_nombre}
                        </option>
                    ))
                }  
                </select>
              </td>

              <td className="PanelFiltros__label-3" width="10%"><div className="sic-align-left sic-margin-top">Motivo</div></td>
              <td className="sic-align-left" width="15%">
                <select name="n_motivo" id="id_motivo" className="PanelFiltros__select">
                <option id="_MOTI_TODO" key="_MOTI_TODO" value="_TODO" className="PanelFiltros__select-option">(todos)</option>
                {
                    panel_motivos.map(dmotivo => (
                        <option id={dmotivo.tpk2_id} key={dmotivo.tpk2_id} value={dmotivo.tpk2_id} className="PanelFiltros__select-option">
                          {dmotivo.tabp_alias}
                        </option>
                    ))
                }  
                </select>
              </td>

              <td className="PanelFiltros__label-3" width="10%"><div className="sic-align-left sic-margin-top">Al día/Atrasada</div></td>
              <td className="sic-align-left" width="15%">
                <select name="n_situacion" id="id_situacion" className="PanelFiltros__select">
                {
                    panel_situacion.map(dsituacion => (
                        <option id={dsituacion.situ_id} key={dsituacion.situ_id} value={dsituacion.situ_id} className="PanelFiltros__select-option">
                          {dsituacion.situ_nombre}
                        </option>
                    ))
                }  
                </select>
              </td>

            </tr>

          </tbody>
        </table>
      </form>
    </div>

    <div className="sic-container sic-margin-top">

      <table className="sic-table sic-hoverable sic-striped">
    
        <colgroup></colgroup>
        <colgroup></colgroup>
        <colgroup></colgroup>
        <colgroup></colgroup>
        <colgroup></colgroup>
        <colgroup></colgroup>
        <colgroup></colgroup>
        <colgroup></colgroup>
        <colgroup></colgroup>

        <thead>
          <tr className="sic-xmedium sic-color-dark-blue sic-background-color-thead">
            <th className="sic-border">&nbsp;</th>
            <th className="sic-border">Fecha<br/>Contactar</th>
            <th className="sic-border">Plazo<br/>Días</th>
            <th className="sic-border">RUT</th>
            <th className="sic-border">Raz&oacute;n Social</th>
            <th className="sic-border">Tipo Cliente</th>
            <th className="sic-border">Etapa</th>
            <th className="sic-border">Motivo</th>
            <th className="sic-border">Producto</th>
            <th className="sic-border">Responsable</th>
            <th className="sic-border">Ejec. Producto</th>
          </tr>
        </thead>

        <tbody>
        {
          dataAcciones.map((data, idx) => (
            <tr className="sic-medium" key={"accs_"+idx}>
              <td className="sic-align-center sic-border">{idx + 1}</td>
              <td className={`sic-align-center sic-border ${(data.agen_fecha_prox_ctto_plazo < 0) ? " sic-text-color-red " : ""} `}>{data.agen_fecha_prox_ctto_dma}</td>
              <td className="sic-align-center sic-border">{data.agen_fecha_prox_ctto_plazo}</td>
              <td className="sic-align-center sic-border sic-nowrap"><Link to={`/ficha/${data.agen_rutcli}`} key={"accs_rut_"+idx+data.agen_rutcli} className="sic-anchor">{formatRut(data.agen_rutcli)}</Link></td>
              <td className="sic-border">{data.clte_razon_social}</td>
              <td className="sic-border sic-align-center">{data.agen_tipo_cliente}</td>
              <td className="sic-border">{data.etap_tabp_alias}</td>
              <td className="sic-border">{data.moti_tabp_alias}</td>
              <td className="sic-border">{data.prod_tabp_alias}</td>
              <td className="sic-border">{data.responsable}</td>
              <td className="sic-border">{data.ejpr_nbre_apat_amat}</td>
            </tr>
          ))
        }
        </tbody>
      </table>
    </div>
  </>
  )
 };

export default PanelAcciones;